import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import {
    Alert,
    Box,
    Card,
    CardContent,
    Checkbox,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { useEffect, useState } from 'react';
import { OnlyFansLists } from '../../../../types/onlyFansTypes';

type Props = {
    lists: OnlyFansLists.List[] | void | undefined;
    listsLoading: boolean;
    targetLists: { id: number | OnlyFansLists.Type; name: string }[];
    setTargetLists: (targetLists: { id: number | OnlyFansLists.Type; name: string }[]) => void;
    refetchLists: () => void;
    session?: any;
    theme: Theme;
};

const MessageUsersFormTargetList = (props: Props) => {
    const { lists, listsLoading, targetLists, setTargetLists, refetchLists, session, theme } = props;

    const [sortLists, setSortLists] = useState<boolean>(true);
    const [sortedLists, setSortedLists] = useState<OnlyFansLists.List[] | void | undefined>(lists);

    const handleListItemClicked = (id: number | OnlyFansLists.Type) => {
        const alreadyChecked = targetLists.find(item => item.id === id);

        if (alreadyChecked) {
            const newCheckedLists = targetLists.filter(item => item.id !== id);

            setTargetLists(newCheckedLists);
        } else if (lists) {
            const listName: string | undefined = lists.find(item => item.id === id)?.name;

            if (listName) {
                setTargetLists([...targetLists, { id, name: listName }]);
            }
        }
    };

    useEffect(() => {
        if (!lists) {
            return;
        }

        if (!sortLists) {
            setSortedLists(lists);
            return;
        }

        const sortedData = lists.slice().sort((a: any, b: any) => {
            let x = a.name.toLowerCase();
            let y = b.name.toLowerCase();

            if (x < y) {
                return -1;
            }

            if (x > y) {
                return 1;
            }

            return 0;
        });

        setSortedLists(sortedData);
    }, [lists, sortLists]);

    useEffect(() => {
        if (session && session.payload && session.payload.targetLists) {
            setTargetLists(session.payload.targetLists);
        }
    }, [session, setTargetLists]);

    return (
        <>
            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <Card variant="elevation">
                        <CardContent>
                            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                        Select Target Lists
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
                                <Grid item xs>
                                    <Chip
                                        color={!targetLists || targetLists.length === 0 ? 'error' : 'primary'}
                                        icon={<CheckCircleIcon />}
                                        label={`${targetLists ? targetLists.length : 0} selected`}
                                    />
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Tooltip title="Clear Selection">
                                        <IconButton
                                            color="error"
                                            onClick={() => {
                                                setTargetLists([]);
                                            }}
                                        >
                                            <ClearIcon fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Tooltip title="Sort lists alphabetically or custom like on OnlyFans">
                                        <IconButton
                                            onClick={() => {
                                                setSortLists(!sortLists);
                                            }}
                                        >
                                            <SortByAlphaIcon fontSize="large" color={sortLists ? 'primary' : 'secondary'} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Tooltip title="Reload Lists from OnlyFans">
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                refetchLists();
                                            }}
                                        >
                                            <RefreshIcon fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    {listsLoading ? (
                                        <Box textAlign={'center'}>
                                            <Typography variant="caption">
                                                Retrieving your Lists...
                                                <br />
                                                <br />
                                                This might take a while depending on how many Lists you have and OnlyFans servers speed 🤷‍♂️
                                                <br />
                                                <br />
                                                <CircularProgress size={24} />
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <>
                                            {sortedLists && typeof sortedLists !== 'undefined' ? (
                                                <Paper variant="outlined" sx={{ maxHeight: 200, overflow: 'auto' }}>
                                                    <List dense sx={{ width: '100%' }}>
                                                        {sortedLists.map((list: any) => (
                                                            <ListItem
                                                                disablePadding
                                                                key={list.id}
                                                                sx={{
                                                                    backgroundColor:
                                                                        targetLists && targetLists.find(item => item.id === list.id)
                                                                            ? theme.palette.grey[200]
                                                                            : '',
                                                                }}
                                                            >
                                                                <ListItemButton
                                                                    role={undefined}
                                                                    dense
                                                                    onClick={() => {
                                                                        handleListItemClicked(list.id);
                                                                    }}
                                                                >
                                                                    <ListItemIcon>
                                                                        <Checkbox
                                                                            tabIndex={-1}
                                                                            disableRipple
                                                                            checked={
                                                                                targetLists && targetLists.find(item => item.id === list.id)
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={list.name}
                                                                        secondary={`Users: ${(list.usersCount as number).toLocaleString()}`}
                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Paper>
                                            ) : (
                                                <Alert severity="error">Could not load lists!</Alert>
                                            )}
                                        </>
                                    )}
                                </Grid>

                                {!listsLoading && (
                                    <Grid item xs={12}>
                                        <Alert severity="info">
                                            Please note that only current fans and expired fans you follow in these lists will be able to
                                            receive the message
                                        </Alert>
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default MessageUsersFormTargetList;
