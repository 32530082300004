import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import ShareIcon from '@mui/icons-material/Share';
import { Divider, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { useDialog } from 'muibox';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { postUrlForAutoReply, SextforceAutoRepostWithLatestRepostLog } from '../../../../hooks/useSextforceAutoRepost';
import StyledLocalMenu from '../../../common/StyledLocalMenu';

type Props = {
    subscriber: any;
    autoRepost: SextforceAutoRepostWithLatestRepostLog;
    setChangeNextRepostDateDialogOpen: (open: boolean) => void;
    setEditDialogOpen: (open: boolean) => void;
    setShareDialogOpen: (open: boolean) => void;
    handleActivateAutoRepost: (autoRepostId: string) => void;
    handleDeactivateAutoRepost: (autoRepostId: string) => void;
    handleDeleteAutoRepost: (autoRepostId: string) => void;
    isDeleting: boolean;
    isReactivatingAutoRepost: boolean;
};

const SextforceAutoRepostCalendarLocalMenu = (props: Props) => {
    const {
        subscriber,
        autoRepost,
        setChangeNextRepostDateDialogOpen,
        setEditDialogOpen,
        setShareDialogOpen,
        handleActivateAutoRepost,
        handleDeactivateAutoRepost,
        handleDeleteAutoRepost,
        isDeleting,
        isReactivatingAutoRepost,
    } = props;

    const params = useParams();
    const dialog = useDialog();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const sortMenuOpen = Boolean(anchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={e => handleMenuClick(e)}>
                <MoreHorizIcon />
            </IconButton>
            <StyledLocalMenu anchorEl={anchorEl} open={sortMenuOpen} onClose={handleMenuClose}>
                {autoRepost.active && (
                    <>
                        <MenuItem
                            color="primary"
                            disabled={!autoRepost.active || isDeleting || isReactivatingAutoRepost}
                            onClick={e => {
                                e.stopPropagation();
                                setChangeNextRepostDateDialogOpen(true);
                            }}
                            disableRipple
                        >
                            <MoreTimeIcon sx={{ fontSize: '12pt' }} />
                            Change Next Repost Date & Time
                        </MenuItem>

                        <Divider />

                        <MenuItem
                            color="primary"
                            disabled={!autoRepost.active || isDeleting || isReactivatingAutoRepost}
                            onClick={e => {
                                e.stopPropagation();
                                setEditDialogOpen(true);
                            }}
                            disableRipple
                        >
                            <EditIcon color="primary" />
                            Edit Auto Repost Settings
                        </MenuItem>

                        <Divider />

                        <MenuItem
                            color="primary"
                            disabled={!autoRepost.active || isDeleting || isReactivatingAutoRepost}
                            disableRipple
                            component={Link}
                            to={`/subscribers/${params.userId}/services/hostess/autoCampaignTipReply?campaignUrl=${postUrlForAutoReply(
                                subscriber,
                                autoRepost,
                            )}`}
                        >
                            <QuickreplyIcon color="info" />
                            Setup Auto-Reply to Campaign Tip
                        </MenuItem>

                        <Divider />

                        <MenuItem
                            color="primary"
                            disabled={!autoRepost.active}
                            disableRipple
                            onClick={e => {
                                e.stopPropagation();
                                setShareDialogOpen(true);
                            }}
                        >
                            <ShareIcon color="secondary" />
                            Share Auto Repost Updates & Stats
                        </MenuItem>

                        <Divider />

                        <MenuItem
                            color="primary"
                            disableRipple
                            disabled={!autoRepost.active || isDeleting || isReactivatingAutoRepost}
                            onClick={e => {
                                e.stopPropagation();

                                dialog
                                    .confirm({
                                        title: 'Deactivate Auto Repost',
                                        message:
                                            'Are you sure you want to deactivate this auto repost? The last repost will not be processed. Essentially, this will pause this auto repost.',
                                        cancel: {
                                            text: 'Cancel',
                                            variant: 'outlined',
                                        },
                                        ok: {
                                            text: 'Deactivate',
                                            variant: 'contained',
                                            color: 'error',
                                        },
                                    })
                                    .then(() => {
                                        if (autoRepost._id) {
                                            handleDeactivateAutoRepost(autoRepost._id);
                                        }
                                    })
                                    .catch(() => {});
                            }}
                        >
                            <PowerSettingsNewIcon color="error" />
                            <Stack direction="column" spacing={0}>
                                <Typography variant="inherit">Pause Auto Repost</Typography>
                                <Typography variant="inherit" color="text.secondary">
                                    Will not process last repost
                                </Typography>
                            </Stack>
                        </MenuItem>

                        <Divider />

                        <MenuItem
                            color="primary"
                            disableRipple
                            disabled={!autoRepost.active || isDeleting || isReactivatingAutoRepost}
                            onClick={e => {
                                e.stopPropagation();

                                dialog
                                    .confirm({
                                        title: 'Delete Auto Repost',
                                        message: 'Are you sure you want to delete this auto repost?',
                                        cancel: {
                                            text: 'Cancel',
                                            variant: 'outlined',
                                        },
                                        ok: {
                                            text: 'Delete',
                                            variant: 'contained',
                                            color: 'error',
                                        },
                                    })
                                    .then(() => {
                                        if (autoRepost._id) {
                                            handleDeleteAutoRepost(autoRepost._id);
                                        }
                                    })
                                    .catch(() => {});
                            }}
                        >
                            <DeleteIcon color="error" />
                            <Stack direction="column" spacing={0}>
                                <Typography variant="inherit">Delete Auto Repost</Typography>
                                <Typography variant="inherit" color="text.secondary">
                                    Will process last repost
                                </Typography>
                            </Stack>
                        </MenuItem>
                    </>
                )}

                {!autoRepost.active && (
                    <>
                        <MenuItem
                            color="primary"
                            disableRipple
                            disabled={autoRepost.active || isDeleting || isReactivatingAutoRepost}
                            onClick={e => {
                                e.stopPropagation();

                                dialog
                                    .confirm({
                                        title: 'Reactivate Auto Repost',
                                        message:
                                            'Are you sure you want to reactivate this auto repost? The system will try to process the last repost.',
                                        cancel: {
                                            text: 'Cancel',
                                            variant: 'outlined',
                                        },
                                        ok: {
                                            text: 'Reactivate',
                                            variant: 'contained',
                                            color: 'error',
                                        },
                                    })
                                    .then(() => {
                                        if (autoRepost._id) {
                                            handleActivateAutoRepost(autoRepost._id);
                                        }
                                    })
                                    .catch(() => {});
                            }}
                        >
                            <PowerSettingsNewIcon color="success" />
                            <Stack direction="column" spacing={0}>
                                <Typography variant="inherit">Reactivate Auto Repost</Typography>
                            </Stack>
                        </MenuItem>

                        <Divider />

                        <MenuItem
                            color="primary"
                            disableRipple
                            disabled={autoRepost.active || isDeleting || isReactivatingAutoRepost}
                            onClick={e => {
                                e.stopPropagation();

                                dialog
                                    .confirm({
                                        title: 'Delete Auto Repost',
                                        message: 'Are you sure you want to delete this auto repost? This action is permanent.',
                                        cancel: {
                                            text: 'Cancel',
                                            variant: 'outlined',
                                        },
                                        ok: {
                                            text: 'Delete',
                                            variant: 'contained',
                                            color: 'error',
                                        },
                                    })
                                    .then(() => {
                                        if (autoRepost._id) {
                                            handleDeleteAutoRepost(autoRepost._id);
                                        }
                                    })
                                    .catch(() => {});
                            }}
                        >
                            <DeleteIcon color="error" />
                            <Stack direction="column" spacing={0}>
                                <Typography variant="inherit">Delete Auto Repost</Typography>
                                <Typography variant="inherit" color="text.secondary">
                                    Permenantly delete this auto repost
                                </Typography>
                            </Stack>
                        </MenuItem>
                    </>
                )}
            </StyledLocalMenu>
        </>
    );
};

export default SextforceAutoRepostCalendarLocalMenu;
