import { Card, Grid, Skeleton, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { Dinero } from 'dinero.js';

type Props = {
    title: string;
    value: Dinero;
    loading: boolean;
    icon?: React.ReactNode;
    theme: Theme;
};

const PriceCard = (props: Props) => {
    const { title, value, loading, icon, theme } = props;

    return (
        <Card
            variant="elevation"
            sx={{
                marginBottom: theme.spacing(0),
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: {
                    xs: theme.spacing(1.5),
                    sm: theme.spacing(2),
                },
            }}
        >
            <Grid container flexGrow={1} alignItems="center" spacing={2}>
                {icon && (
                    <Grid item xs={'auto'}>
                        {icon}
                    </Grid>
                )}
                <Grid item xs>
                    <Grid container flexGrow={1} alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" align="right">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" align="right" fontFamily="monospace">
                                {loading ? <Skeleton /> : value.toFormat()}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default PriceCard;
