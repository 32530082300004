import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import useAxios from './useAxios';

export type SextforceMessageCountersDirection = 'toUser' | 'fromUser';

export type SextforceMessageCountersWindow = 'minute' | 'daily' | 'weekly' | 'monthly';

const useSextforceMessageCounters = (direction: SextforceMessageCountersDirection, window: SextforceMessageCountersWindow) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const axios = useAxios();

    const fetchTotalSent = async (): Promise<{ count: number }> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId) {
            const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/messageCounters/${direction}/${window}`;

            return axios
                .get(query)
                .then(async response => {
                    if (response.status === 200) {
                        return response.data;
                    }

                    return { count: 0 };
                })
                .catch(error => {
                    console.error(error);

                    return { count: 0 };
                });
        }

        return { count: 0 };
    };

    return useQuery(['sextforceMessageCounters', params.userId, direction, window], () => fetchTotalSent(), {
        refetchOnWindowFocus: false,
        // Stall depending on the window
        staleTime: window === 'minute' ? 60000 : window === 'daily' ? 86400000 : window === 'weekly' ? 604800000 : 2592000000,
        refetchInterval: window === 'minute' ? 60000 : undefined,
        enabled: userContext.jwtToken && settingsContext.apiKey && params.userId ? true : false,
    });
};

export default useSextforceMessageCounters;
