import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';

export type LoginSessionResult =
    | 'success'
    | 'error'
    | 'wrong_password'
    | 'captcha_fail'
    | '2fa'
    | 'selfie'
    | 'selfie_expired'
    | 'timeout'
    | 'unknown_subscriber'
    | 'failed'
    | 'wrong_user'
    | 'wrong_email'
    | 'invalid_credentials'
    | 'access_denied';

export namespace OnlyFansLoginResponse {
    export interface EmailCode {
        lastSentDate: Date;
        requestAttemptsLimit: number;
        requestAttemptsLeft: number;
    }

    export interface ECode {
        lastSentDate?: Date;
        expirationDate?: Date;
        expirationSeconds?: number;
        requestAttemptsLimit: number;
        requestAttemptsLeft: number;
        checkAttemptsLimit?: number;
        checkAttemptsLeft?: number;
    }

    export interface OtpState {
        email: boolean;
        phone: boolean;
        phoneOtp: boolean;
        app: boolean;
        appOtp: boolean;
        faceOtp: boolean;
        emailMask: string;
        emailCode: EmailCode;
        phoneLast4: string;
        phoneCode: ECode;
        faceCode: ECode;
        forceFaceOtp: boolean;
    }

    export interface Payload {
        otpState: OtpState;
    }

    export interface Error {
        code: number;
        message: string;
        payload: Payload;
    }

    export interface Errors {
        code: string[];
    }

    export interface Response {
        success: boolean;
        error: Error;
        errors: Errors;
        otpState?: OtpState;
    }
}

export interface LoginSessionStatePayload {
    message: string;
    selectedOtpOption: string | null;
    otpCode: string | null;
    otpLink: string | null;
    loginResponse: OnlyFansLoginResponse.Response | null;
    myIp: string | null;
    result: LoginSessionResult | null;
}

const useSubscriberRelogin = (userId: string | undefined | null, keepRefreshing: boolean) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const axios = useAxios();

    const [isSubscriberReloginStartSubmitting, setIsSubscriberReloginStartSubmitting] = useState<boolean>(false);

    // Fetch relogin session details
    const fetchsubscriberRelogin = async (): Promise<{ running: boolean; session: LoginSessionStatePayload | null }> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username && userId) {
            const query: string = `${settingsContext.routes.subscribers.find}${userId}/relogin`;

            return axios
                .get(query)
                .then(async response => response.data)
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);

                    return { running: false, session: null };
                });
        }

        return { running: false, session: null };
    };

    const subscriberRelogin = useQuery(
        ['subscriberRelogin', userId, userContext.jwtToken],
        () => {
            return fetchsubscriberRelogin();
        },
        {
            refetchOnWindowFocus: false,
            refetchInterval: keepRefreshing ? 3000 : false,
            enabled: userContext.jwtToken && settingsContext.apiKey && userId ? true : false,
        },
    );

    const subscriberReloginStart = async (
        userId: string,
        email: string | null,
        password: string | null,
        userAgent: string | null,
        cookie: string | null,
        xBc: string | null,
    ): Promise<{ success: boolean; session: LoginSessionStatePayload | null }> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username && userId) {
            const query: string = `${settingsContext.routes.subscribers.base}${userId}/relogin`;

            setIsSubscriberReloginStartSubmitting(true);

            return axios
                .post(query, {
                    email: email && email.trim().length > 0 ? email.trim() : null,
                    password: password && password.trim().length > 0 ? password.trim() : null,
                    userAgent: userAgent && userAgent.trim().length > 0 ? userAgent.trim() : null,
                    cookie: cookie && cookie.trim().length > 0 ? cookie.trim() : null,
                    xBc: xBc && xBc.trim().length > 0 ? xBc.trim() : null,
                })
                .then(response => {
                    setIsSubscriberReloginStartSubmitting(false);

                    return response.data;
                })
                .catch(error => {
                    console.error(error);

                    setIsSubscriberReloginStartSubmitting(false);

                    handleHttpError(error, dialog);

                    return { success: false, session: null };
                });
        } else {
            return { success: false, session: null };
        }
    };

    const subscriberReloginCancel = async (userId: string): Promise<{ success: boolean }> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username && userId) {
            const query: string = `${settingsContext.routes.subscribers.base}${userId}/relogin`;

            setIsSubscriberReloginStartSubmitting(true);

            return axios
                .delete(query)
                .then(response => {
                    setIsSubscriberReloginStartSubmitting(false);

                    return response.data;
                })
                .catch(error => {
                    console.error(error);

                    setIsSubscriberReloginStartSubmitting(false);

                    // handleHttpError(error, dialog);

                    return { success: false };
                });
        } else {
            return { success: false };
        }
    };

    const subscriberReloginRequestOtpCode = async (
        userId: string,
        otpType: string,
    ): Promise<{ success: boolean; session: LoginSessionStatePayload | null }> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username && userId) {
            const query: string = `${settingsContext.routes.subscribers.base}${userId}/relogin/otp`;

            setIsSubscriberReloginStartSubmitting(true);

            return axios
                .post(query, {
                    otpType,
                })
                .then(response => {
                    setIsSubscriberReloginStartSubmitting(false);

                    return response.data;
                })
                .catch(async error => {
                    if (error.response && error.response.status === 429) {
                        await dialog.alert('Too many requests. Please try again later.').then(() => {});
                    } else {
                        console.error(error);

                        handleHttpError(error, dialog);
                    }

                    setIsSubscriberReloginStartSubmitting(false);

                    return { success: false, session: null };
                });
        } else {
            return { success: false, session: null };
        }
    };

    const subscriberReloginSubmitOtpCode = async (
        userId: string,
        otpType: string,
        otpCode: string,
    ): Promise<{ success: boolean; session: LoginSessionStatePayload | null }> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username && userId) {
            const query: string = `${settingsContext.routes.subscribers.base}${userId}/relogin/otp/submit`;

            setIsSubscriberReloginStartSubmitting(true);

            return axios
                .post(query, {
                    otpType,
                    otpCode,
                })
                .then(response => {
                    setIsSubscriberReloginStartSubmitting(false);

                    return response.data;
                })
                .catch(error => {
                    console.error(error);

                    setIsSubscriberReloginStartSubmitting(false);

                    handleHttpError(error, dialog);

                    return { success: false, session: null };
                });
        } else {
            return { success: false, session: null };
        }
    };

    return {
        ...subscriberRelogin,
        isSubscriberReloginStartSubmitting,
        subscriberReloginStart,
        subscriberReloginCancel,
        subscriberReloginRequestOtpCode,
        subscriberReloginSubmitOtpCode,
    };
};

export default useSubscriberRelogin;
