import { Alert, Grid, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import MessagesSentCard from '../../../components/common/MessagesSentCard';
import PageContainer from '../../../components/common/PageContainter';
import ServiceActionsBlocks from '../../../components/common/ServiceActions';
import ServiceStatusCard from '../../../components/common/ServiceStatuxCard';
import SharedCreditCard from '../../../components/common/SharedCreditCard';
import MessageUsersPriorityMessageCalendar from '../../../components/services/messageUsers/priorityMessage/MessageUsersPriorityMessageCalendar';
import MessageUsersOverviewStatus from '../../../components/services/messageUsers/sendMessage/MessageUsersOverviewStatus';
import useSubscriber from '../../../hooks/useSubscriber';
import { SettingsContext } from '../../../store/SettingsContext';

const MessageUsersOverview = () => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();

    const { data: subscriber } = useSubscriber();

    return (
        <PageContainer>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}`} /> Messaging for {subscriber.username}
                    </Typography>
                    <Alert severity="info" sx={{ marginBottom: theme.spacing(2) }}>
                        Please make sure you read the{' '}
                        <Link
                            to={`/subscribers/${subscriber._id}/services/messageUsers/welcome`}
                            style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                        >
                            <strong>Welcome</strong>
                        </Link>{' '}
                        information first!
                    </Alert>
                    <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <ServiceStatusCard
                                subscriber={subscriber}
                                serviceName="messageUsers"
                                alwaysActive={settingsContext.services.messageUsers.alwaysActive}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <MessagesSentCard />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <SharedCreditCard showTopUpButton={true} />
                        </Grid>
                    </Grid>

                    <MessageUsersOverviewStatus />

                    <MessageUsersPriorityMessageCalendar />

                    {/* <MessageUsersScheduledList /> */}

                    <ServiceActionsBlocks subscriber={subscriber} actions={settingsContext.services.messageUsers.actions} theme={theme} />
                </>
            )}
        </PageContainer>
    );
};

export default MessageUsersOverview;
