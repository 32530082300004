import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { Card, Grid, Skeleton, Theme, Typography, useTheme } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { getServiceStatusName } from '../../utils/common';

type ServiceStatusCardProps = {
    subscriber: any | null;
    serviceName: string;
    alwaysActive: boolean;
};

const ServiceStatusCard = (props: ServiceStatusCardProps) => {
    const { subscriber, serviceName, alwaysActive } = props;
    const theme: Theme = useTheme();

    const serviceStatusName: string = getServiceStatusName(subscriber, serviceName, alwaysActive);
    const status: any = {
        Available: {
            color: green[900],
            background: green[100],
            icon: <PlayArrowIcon htmlColor={green[900]} sx={{ fontSize: '36pt' }} />,
        },
        Active: {
            color: green[900],
            background: green[100],
            icon: <PlayArrowIcon htmlColor={green[900]} sx={{ fontSize: '36pt' }} />,
        },
        'Payment Due': {
            color: orange[900],
            background: orange[100],
            icon: <PauseIcon fontSize="large" />,
        },
        'Not Active': {
            color: red[900],
            background: red[100],
            icon: <StopIcon fontSize="large" />,
        },
        'Not Logged In': {
            color: red[900],
            background: red[100],
            icon: <StopIcon fontSize="large" />,
        },
    };

    return (
        <Card
            variant="elevation"
            sx={{
                backgroundColor: status[serviceStatusName].background,
                height: '100%',
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'space-between',
                padding: {
                    xs: theme.spacing(1.5),
                    sm: theme.spacing(2),
                },
            }}
        >
            {subscriber ? (
                <Grid container flexGrow={1} alignItems="center" spacing={2}>
                    <Grid item xs={2}>
                        {status[serviceStatusName].icon}
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container flexGrow={1} alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" align="right">
                                    Status
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={status[serviceStatusName].color} align="right">
                                    {serviceStatusName}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Skeleton />
            )}
        </Card>
    );
};

export default ServiceStatusCard;
