import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';

const SextforceAutoRepostDescription = () => {
    const [showMore, setShowMore] = useState<boolean>(false);

    return (
        <>
            <Typography variant="body1" gutterBottom={showMore}>
                Keep posts at the top of your and your fans' feeds by setting up automatic reposts of your promos or any regular posts. You
                can choose to post a link to the original post, or make a new post with the same content. All automatically and without any
                effort. Just say how often you want to repost and we'll take care of the rest. Tip campaigns can even repost automatically
                when the target is reached.
            </Typography>
            {!showMore && (
                <>
                    <Stack direction="column" spacing={0.5} alignItems="center">
                        <MoreHorizIcon color={'disabled'} />
                        <Button
                            variant="outlined"
                            color="info"
                            size="small"
                            onClick={() => {
                                setShowMore(true);
                            }}
                        >
                            Show More
                        </Button>
                    </Stack>
                </>
            )}
            {showMore && (
                <>
                    <Typography variant="body1" gutterBottom>
                        If you are reposting a colleague's promo or GG, the system will send them automatic Telegram notifications for each
                        repost with each post's stats and graph so they can see how much you are helping them, and they can even see LIVE
                        stats directly from your page right from Telegram, including whether or not the post has been deleted, archived or
                        private archived, so they always have confidence in your promos! Simply click on the Share button and send them the
                        Telegram invitation link.
                    </Typography>
                    <Typography variant="body1" gutterBottom fontWeight={'bold'}>
                        Equally, if you are buying a promo from someone who uses this system, you can use the Telegram live updates to make
                        sure your post is still active and performing, so there's no funny business going on.
                    </Typography>
                    <Typography variant="body1">
                        To get started, post a promo on OnlyFans like normal, then click on Add New Auto Repost below.
                    </Typography>
                </>
            )}
        </>
    );
};

export default SextforceAutoRepostDescription;
