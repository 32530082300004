import ForumIcon from '@mui/icons-material/Forum';
import { Button, Card, Grid, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { Theme } from '@mui/system';
import { useEffect, useState } from 'react';
import useMessageUsersTotalSent from '../../hooks/useMessageUsersTotalSent';
import useSextforceMessageCounters, {
    SextforceMessageCountersDirection,
    SextforceMessageCountersWindow,
} from '../../hooks/useSextforceMessageCounters';

// Output the right value based on it being a number, string, or ReactNode
const Value = (props: { value: number | string | React.ReactNode }) => {
    const { value } = props;

    return (
        <>
            {typeof value === 'number' || typeof value === 'string'
                ? `${typeof value === 'number' ? value.toLocaleString() : value}`
                : value
                ? value
                : 0}
        </>
    );
};

const MessagesSentCard = () => {
    const theme: Theme = useTheme();

    const { data: totalSent, isLoading: totalSentLoading } = useMessageUsersTotalSent();

    const [source, setSource] = useState<'total' | 'counter'>('total');
    const [direction, setDirection] = useState<SextforceMessageCountersDirection>('toUser');
    const [window, setWindow] = useState<SextforceMessageCountersWindow>('minute');
    const { data: messageCounter, isLoading: messageCounterLoading } = useSextforceMessageCounters(direction, window);

    const loading = totalSentLoading || messageCounterLoading;
    const title =
        source === 'total' ? 'Priority Messages Sent' : direction === 'toUser' ? 'Messages Sent to Users' : 'Messages Received from Users';

    const [value, setValue] = useState<number>(0);
    const [rate, setRate] = useState<string>('');

    useEffect(() => {
        if (source === 'total') {
            setValue(totalSent?.totalSent || 0);
            setRate('');
        } else {
            setValue(messageCounter?.count || 0);

            switch (window) {
                case 'minute':
                    setRate('/minute');
                    break;
                case 'daily':
                    setRate('/last 24H');
                    break;
                case 'weekly':
                    setRate('/last 7D');
                    break;
                case 'monthly':
                    setRate('/last 30D');
                    break;
            }
        }
    }, [source, totalSent, messageCounter, window]);

    return (
        <Card
            variant="elevation"
            sx={{
                marginBottom: 0,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: {
                    xs: theme.spacing(1.5),
                    sm: theme.spacing(2),
                },
            }}
        >
            <Grid container flexGrow={1} alignItems="flex-start" spacing={1}>
                <Grid item xs={'auto'}>
                    <ForumIcon fontSize="large" htmlColor={theme.palette.success.dark} />
                </Grid>
                <Grid item xs>
                    <Grid container flexGrow={1} alignItems="center" spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" align="right">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={0.5} alignItems="flex-end" justifyContent="flex-end">
                                <Typography
                                    variant="h5"
                                    align="right"
                                    fontFamily="monospace"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {loading ? <Skeleton /> : <Value value={value} />}
                                </Typography>
                                <Typography variant="body2" align="right" color="text.secondary">
                                    {rate}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="space-between">
                        <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="flex-start">
                            <Button
                                variant={source === 'total' ? 'outlined' : 'text'}
                                color="primary"
                                onClick={() => {
                                    setSource('total');
                                    setDirection('toUser');
                                }}
                                sx={{ minWidth: 0 }}
                            >
                                Priority
                            </Button>
                            <Button
                                variant={source === 'counter' && direction === 'toUser' ? 'outlined' : 'text'}
                                color="primary"
                                onClick={() => {
                                    setSource('counter');
                                    setDirection('toUser');
                                }}
                                sx={{ minWidth: 0 }}
                            >
                                To Fans
                            </Button>
                            <Button
                                variant={source === 'counter' && direction === 'fromUser' ? 'outlined' : 'text'}
                                color="primary"
                                onClick={() => {
                                    setSource('counter');
                                    setDirection('fromUser');
                                }}
                                sx={{ minWidth: 0 }}
                            >
                                From Fans
                            </Button>
                        </Stack>
                        <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="flex-end">
                            <Button
                                variant={window === 'minute' ? 'outlined' : 'text'}
                                color="primary"
                                disabled={source === 'total'}
                                onClick={() => setWindow('minute')}
                                sx={{ minWidth: 0 }}
                            >
                                Min
                            </Button>
                            <Button
                                variant={window === 'daily' ? 'outlined' : 'text'}
                                color="primary"
                                disabled={source === 'total'}
                                onClick={() => setWindow('daily')}
                                sx={{ minWidth: 0 }}
                            >
                                1D
                            </Button>
                            <Button
                                variant={window === 'weekly' ? 'outlined' : 'text'}
                                color="primary"
                                disabled={source === 'total'}
                                onClick={() => setWindow('weekly')}
                                sx={{ minWidth: 0 }}
                            >
                                1W
                            </Button>
                            <Button
                                variant={window === 'monthly' ? 'outlined' : 'text'}
                                color="primary"
                                disabled={source === 'total'}
                                onClick={() => setWindow('monthly')}
                                sx={{ minWidth: 0 }}
                            >
                                1M
                            </Button>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Card>
    );
};

export default MessagesSentCard;
