import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faCommentSlash, faSignsPost } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BlockIcon from '@mui/icons-material/Block';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GirlIcon from '@mui/icons-material/Girl';
import InfoIcon from '@mui/icons-material/Info';
import InsightsIcon from '@mui/icons-material/Insights';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import ListIcon from '@mui/icons-material/List';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import MailIcon from '@mui/icons-material/Mail';
import MessageIcon from '@mui/icons-material/Message';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import MoodIcon from '@mui/icons-material/Mood';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import ScienceIcon from '@mui/icons-material/Science';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import { createContext } from 'react';
import IconInstagram from '../components/icons/IconInstagram';
import IconOnlyFans from '../components/icons/IconOnlyFans';
import IconReddit from '../components/icons/IconReddit';
import IconTikTok from '../components/icons/IconTikTok';
import IconTwitter from '../components/icons/IconTwitter';
import firebaseConfig, { FirebaseConfig } from '../firebase';
import { OnlyFansSubscriber } from '../hooks/useSubscriber';
import { MuiColorPalletName } from '../utils/common';

const apiKey: string = 'fe56e8d4-bae5-4be8-b7c7-44fc28519aec';
const apiHost: string = 'https://api.onlystruggles.com/api';
const apiPublicHost: string = 'https://api.onlystruggles.com/publicApi';
// const apiPublicHost: string = 'http://127.0.0.1:5200/publicApi';
// const apiHost: string = 'http://127.0.0.1:5200/api';

type FinancialContext = {
    gbpToUsdRatio: number;
};

type SettingsContextRoutes = {
    [key: string]: {
        [key: string]: string;
    };
};

export type ServiceAction = {
    type: 'action';
    title: string;
    description: string;
    icon: JSX.Element;
    url: (id: string) => string;
    visible: boolean;
};

export type ServiceActionCategory = {
    type: 'category';
    title: string;
    description?: string;
    visible: boolean;
};

export type ServiceActions = ServiceAction | ServiceActionCategory;

type SettingsContextServices = {
    [key: string]: {
        visible: boolean;
        name: string;
        shortDescription: string;
        alwaysActive: boolean;
        icon: (color: MuiColorPalletName, fontSize?: 'inherit' | 'medium' | 'small' | 'large' | undefined) => JSX.Element;
        homeUrl?: string;
        actions?: ServiceActions[];
        [key: string]: any;
    };
};

export type Platform = {
    name: string;
    icon?: (fontSize?: 'small' | 'inherit' | 'medium' | 'large' | undefined) => JSX.Element;
    url: string;
};

type SettingsContextType = {
    apiKey: string;
    firebase: FirebaseConfig;
    financial: FinancialContext;
    webRoot: string;
    routes: SettingsContextRoutes;
    subscriber?: OnlyFansSubscriber;
    services: SettingsContextServices;
    platforms: { [name: string]: Platform };
};

const settings: SettingsContextType = {
    apiKey,
    firebase: firebaseConfig,
    financial: {
        gbpToUsdRatio: 1.25,
    },
    webRoot: 'http://dashboard.onlystruggles.com',
    routes: {
        system: {
            base: `${apiHost}/system/`,
            stats: `${apiHost}/system/stats/`,
        },
        account: {
            base: `${apiHost}/account/`,
            dashboard: `${apiHost}/account/dashboard/`,
        },
        onlyFans: {
            base: `${apiHost}/onlyFans/`,
        },
        subscribers: {
            count: `${apiHost}/subscribers/count`,
            find: `${apiHost}/subscribers/`,
            base: `${apiHost}/subscribers/`,
        },
        categories: {
            base: `${apiHost}/categories/`,
        },
        transactions: {
            base: `${apiHost}/transactions/`,
        },
        followBack: {
            base: `${apiHost}/followBack/`,
        },
        messageUsers: {
            base: `${apiHost}/messageUsers/`,
        },
        massDm: {
            base: `${apiHost}/massDm/`,
        },
        sextforce: {
            base: `${apiHost}/sextforce/`,
            publicAgent: `${apiPublicHost}/sextforce/agent/`,
            agentSubmitSale: `${apiPublicHost}/sextforce/agent/`,
            publicMetrics: `${apiPublicHost}/sextforce/metrics/`,
        },
        metrics: {
            base: `${apiHost}/metrics/`,
        },
        autoRepost: {
            base: `${apiHost}/autoRepost/`,
        },
        autoRepostColors: {
            base: `${apiHost}/autoRepostColors/`,
        },
        autoCampaignTipReply: {
            base: `${apiHost}/autoCampaignTipReply/`,
        },
    },
    services: {
        sextforce: {
            visible: true,
            name: 'Sextforce',
            shortDescription:
                'Powerful assistants management, accounting and sales, campaigns, promos and trials tracking service. Automatically track sales of sexters, calculate commission, track account progress, see who subscribed and consumed each campaign, promo and trial, and generate reports.',
            alwaysActive: false,
            icon: (color, fontSize?) => <MilitaryTechIcon color={color} fontSize={fontSize} />,
            homeUrl: 'services/sextforce',
            totalAllowedAgents: 99,
            actions: [
                // {
                //     type: 'action',
                //     title: 'Welcome',
                //     description: `Please read this first! This will explain how the system works and how to use it.`,
                //     icon: <InfoIcon />,
                //     url: (id: string) => `/subscribers/${id}/services/sextforce/welcome`,
                //     visible: true,
                // },
                // {
                //     type: 'category',
                //     title: 'Agents / Sexters',
                //     visible: true,
                // },
                {
                    type: 'action',
                    title: `Sales Report`,
                    description: `View sales reports, Agents' commissions, compare sales and Agents' performance, etc.`,
                    icon: <AssessmentIcon />,
                    url: (id: string) => `/subscribers/${id}/services/sextforce/reports`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: 'Transactions',
                    description: `Browse and search sales transactions, and export to CSV or Excel file. Perfect for sending reports to Agents or analytics.`,
                    icon: <AttachMoneyIcon />,
                    url: (id: string) => `/subscribers/${id}/services/sextforce/transactions`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: 'Assign Anonymous Sales',
                    description: `Browse, and assign transactions that end with X.00 or transactions that don't have a fraction with a dedicated Agent to the relevant Agents that made the sale. Assign transactions manually or by approving a submitted sale proof.`,
                    icon: <AssignmentTurnedInIcon />,
                    url: (id: string) => `/subscribers/${id}/services/sextforce/assign`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: `Manage Sale Proofs`,
                    description: `Browse, view and manage sale proof submissons from Agents. This is a great place to see the submissions that were not matched to any transaction either because the Agent entered the time/price incorrectly or because of an OnlyFans glitch.`,
                    icon: <ReceiptIcon />,
                    url: (id: string) => `/subscribers/${id}/services/sextforce/saleproofs`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: 'Manage Agents',
                    description: `Add and manage Sextforce Agents. Set the Agent's identifying fraction, commission, manage sale proofs submission link, etc.`,
                    icon: <PeopleIcon />,
                    url: (id: string) => `/subscribers/${id}/services/sextforce/agents`,
                    visible: true,
                },
                {
                    type: 'category',
                    title: 'Analytics',
                    visible: true,
                },
                {
                    type: 'action',
                    title: `Account Sales`,
                    description: `Track the performance of your account. See insight and statistics, and a 12-month sales analysis.`,
                    icon: <InsightsIcon />,
                    url: (id: string) => `/subscribers/${id}/services/sextforce/metrics/account`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: `Tracking Links (Campaigns)`,
                    description: `Track the performance of your Tracking Links (Campaigns), setup goals and alerts, and share stats with colleagues.`,
                    icon: <InsightsIcon />,
                    url: (id: string) => `/subscribers/${id}/services/sextforce/metrics/campaigns`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: `Free Trial Links`,
                    description: `Track the performance of your Free Trial Links and Promos, setup goals and alerts, and share stats with colleagues.`,
                    icon: <InsightsIcon />,
                    url: (id: string) => `/subscribers/${id}/services/sextforce/metrics/trials`,
                    visible: true,
                },
                {
                    type: 'category',
                    title: 'Auto-Repost',
                    visible: true,
                },
                {
                    type: 'action',
                    title: `Auto-Repost`,
                    description: 'Automatically repost your promos and posts to your feed, and push updates to your clients via Telegram.',
                    icon: <FontAwesomeIcon icon={faSignsPost} />,
                    url: (id: string) => `/subscribers/${id}/services/sextforce/autoRepost`,
                    visible: true,
                },
                {
                    type: 'category',
                    title: 'Live Stream',
                    visible: true,
                },
                {
                    type: 'action',
                    title: `Stream Tippers To List`,
                    description: 'Automatically add tippers from your live streams to lists, see stream stats and more.',
                    icon: <LiveTvIcon />,
                    url: (id: string) => `/subscribers/${id}/services/sextforce/liveStream/addTippersToList`,
                    visible: true,
                },
                {
                    type: 'category',
                    title: 'Big Brother',
                    visible: true,
                },
                {
                    type: 'action',
                    title: `Telegram Notifications`,
                    description: 'Receive notifications about incoming messages, tips, subscriptions, resubscriptions, and more.',
                    icon: <FontAwesomeIcon icon={faTelegram} />,
                    url: (id: string) => `/subscribers/${id}/services/sextforce/bigBrother/telegram`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: `Restricted Words Monitor`,
                    description: 'Monitor and manage the use of restricted words in your outgoing messages.',
                    icon: <FontAwesomeIcon icon={faCommentSlash} />,
                    url: (id: string) => `/subscribers/${id}/services/sextforce/bigBrother/restrictedWords`,
                    visible: true,
                },
            ],
            productTypes: ['message', 'post', 'tip', 'subscription', 'resubscription', 'stream', 'unknown'],
        },
        followBack: {
            visible: true,
            name: 'Follow-Back',
            shortDescription:
                'Follow expired, and optionally current, fans. Runs daily, exhausting the daily limit and sending reports via Telegram.',
            homeUrl: 'services/followBack',
            alwaysActive: true,
            icon: (color, fontSize?) => <FavoriteIcon color={color} fontSize={fontSize} />,
            actions: [
                {
                    type: 'action',
                    title: 'Welcome',
                    description: `Please read this first! This will explain how the system works and how to use it.`,
                    icon: <InfoIcon />,
                    url: (id: string) => `/subscribers/${id}/services/followBack/welcome`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: 'Settings',
                    description: `Manage Follow-Back limit, priority following settings, etc.`,
                    icon: <SettingsIcon />,
                    url: (id: string) => `/subscribers/${id}/services/followBack/settings`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: `Report`,
                    description: `View Follow-Back performance reports`,
                    icon: <AssessmentIcon />,
                    url: (id: string) => `/subscribers/${id}/services/followBack/report`,
                    visible: true,
                },
            ],
        },
        messageUsers: {
            visible: true,
            name: 'Messaging',
            shortDescription:
                'Send personalised messages to users that end up in their Priority inbox! Automate welcome messages and replies to fans, target online fans and more.',
            homeUrl: 'services/messageUsers',
            alwaysActive: true,
            icon: (color, fontSize?) => <MessageIcon color={color} fontSize={fontSize} />,
            actions: [
                {
                    type: 'action',
                    title: 'Welcome',
                    description: `Please read this first! This will explain how the system works and how to use it.`,
                    icon: <InfoIcon />,
                    url: (id: string) => `/subscribers/${id}/services/messageUsers/welcome`,
                    visible: true,
                },
                {
                    type: 'category',
                    title: 'Send Message',
                    visible: true,
                },
                {
                    type: 'action',
                    title: `Send Priority Message`,
                    description: `Send and schedule regular messages to your fans - either to those who are online right now, or current fans, or to expired fans or those in your lists.`,
                    icon: <SendIcon />,
                    url: (id: string) => `/subscribers/${id}/services/messageUsers/sendMessage`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: 'History & Unsend',
                    description: `See sent messages history and statistics including sales and ROI, as well as repeat and unsend sent messages.`,
                    icon: <SummarizeIcon />,
                    url: (id: string) => `/subscribers/${id}/services/messageUsers/history`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: 'Auto Mass DM',
                    description: `Automatically schedule and send OnlyFans Mass DMs to fans and lists, bypass the OnlyFans daily schedule limit, and track the performance of your messages.`,
                    icon: <MailIcon />,
                    url: (id: string) => `/subscribers/${id}/services/messageUsers/autoMassDm`,
                    visible: false,
                },
                {
                    type: 'category',
                    title: 'Auto Welcome Message',
                    visible: true,
                },
                {
                    type: 'action',
                    title: `New Subscribers`,
                    description: `Send an automatic welcome message when a new fan subscribes to you.`,
                    icon: <WavingHandIcon />,
                    url: (id: string) => `/subscribers/${id}/services/messageUsers/autoSendWelcomeMessage/newSubscribers`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: `Subscribers Rebilling`,
                    description: `Send an automatic message when a fan rebills an ongoing subscription.`,
                    icon: <WavingHandIcon />,
                    url: (id: string) => `/subscribers/${id}/services/messageUsers/autoSendWelcomeMessage/rebillSubscribers`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: `Returning Subscribers`,
                    description: `Send an automatic welcome message when a fan resubscribes to you.`,
                    icon: <WavingHandIcon />,
                    url: (id: string) => `/subscribers/${id}/services/messageUsers/autoSendWelcomeMessage/returningSubscribers`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: `New Trial Fans`,
                    description: `Send an automatic welcome message when a fan consumes a free trial link.`,
                    icon: <WavingHandIcon />,
                    url: (id: string) => `/subscribers/${id}/services/messageUsers/autoSendWelcomeMessage/newTrialSubscribers`,
                    visible: true,
                },
                {
                    type: 'category',
                    title: 'Auto-Reply',
                    visible: true,
                },
                {
                    type: 'action',
                    title: 'Auto-Reply to Freeloaders',
                    description: `Setup automatic replies to freeloaders who message you to relieve the pressure off sexters.`,
                    icon: <ReplyAllIcon />,
                    url: (id: string) => `/subscribers/${id}/services/hostess/autoReply`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: 'Auto-Reply to Expired',
                    description: `Setup automatic replies to expired fans who message you to relieve the pressure off sexters.`,
                    icon: <ReplyAllIcon />,
                    url: (id: string) => `/subscribers/${id}/services/hostess/autoReplyToExpired`,
                    visible: true,
                },
                {
                    type: 'action',
                    title: 'Auto-Reply to Campaign Tip',
                    description: `Setup automatic replies to campaign contributions to thank the fan for the tip or send them content.`,
                    icon: <AttachMoneyIcon />,
                    url: (id: string) => `/subscribers/${id}/services/hostess/autoCampaignTipReply`,
                    visible: true,
                },
            ],
            restrictedWords: [
                'abduct',
                'abducted',
                'abducting',
                'abduction',
                'admireme',
                'animal',
                'asphyxia',
                'asphyxiate',
                'asphyxiation',
                'asphyxicate',
                'asphyxication',
                'bait',
                'ballbusting',
                'bareback',
                'beastiality',
                'bestiality',
                'blacked',
                'blackmail',
                'bleeding',
                'blood',
                'bloodplay',
                'bukkake',
                'caned',
                'caning',
                'cannibal',
                'cbt',
                'cervics',
                'cerviks',
                'cervix',
                'child',
                'chloroform',
                'chloroformed',
                'chloroforming',
                'choke',
                'choking',
                'coma',
                'comatose',
                'consent',
                'cp',
                'diapers',
                'depressed',
                'dog',
                'doze',
                'drinking',
                'drunk',
                'drunken',
                'enema',
                'entrance',
                'escort',
                'escorting',
                'fanfuck',
                'farm',
                'fecal',
                'fetal',
                'fisted',
                'fisting',
                'flogging',
                'foetal',
                'forced',
                'forcedbi',
                'forceful',
                'forcing',
                'fuckafan',
                'fuckfan',
                'gangbang',
                'gangbangs',
                'gaping',
                'golden',
                'hardsports',
                'hooker',
                'hurting',
                'hypno',
                'hypnotize',
                'hypnotized',
                'hypnotizing',
                'inbreed',
                'inbreeded',
                'inbreeding',
                'incapacitate',
                'incapacitation',
                'incest',
                'intox',
                'inzest',
                'jail',
                'jailbait',
                'kidnap',
                'kidnapped',
                'kidnapping',
                // 'knock',
                // 'knocked',
                'lactate',
                'lactation',
                'lolicon',
                'lolita',
                'loli',
                'medicalplay',
                'menstrate',
                'menstrual',
                'menstruate',
                'menstruating',
                'menstruation',
                'meet',
                'molest',
                'molested',
                'molesting',
                'mutilate',
                'mutilation',
                'necrophilia',
                'nigger',
                'paddling',
                'paralyzed',
                'passed',
                'pedo',
                'pedophile',
                'pedophilia',
                'pee',
                'peeplay',
                'pegging',
                'piss',
                'pissing',
                'poo',
                'poop',
                'preteen',
                'prostituted',
                'prostituting',
                'prostitution',
                'pse',
                'rape',
                'raping',
                'rapist',
                'restrictedwordtestzulu',
                'scat',
                'schoolgirl',
                'showers',
                'skat',
                'snuff',
                'strangled',
                'strangling',
                'strangulation',
                'suffocate',
                'suffocation',
                'teen',
                'toilet',
                'toiletslave',
                'toiletslavery',
                'torture',
                'tortured',
                'trance',
                'unconscious',
                'unconsciousness',
                'underage',
                'unwilling',
                'vomit',
                'vomitted',
                'vomitting',
                'watersports',
                'whipping',
                'young',
                'zoophilia',
            ],
        },
        hostess: {
            visible: false,
            name: 'Hostess',
            shortDescription: 'Hostess stands at the entrance and reacts to fans as they come in, spent, subscribe or talk to you.',
            homeUrl: 'services/hostess',
            alwaysActive: true,
            icon: (color, fontSize?) => <GirlIcon color={color} fontSize={fontSize} />,
            actions: [
                {
                    type: 'action',
                    title: 'Welcome',
                    description: `Please read this first! This will explain how the system works and how to use it.`,
                    icon: <InfoIcon />,
                    url: (id: string) => `/subscribers/${id}/services/hostess/welcome`,
                    visible: true,
                },
            ],
        },
        labs: {
            visible: false,
            name: 'Labs',
            shortDescription: 'Late night experiments, new features, and ideas that are not yet ready for prime time.',
            homeUrl: 'labs',
            alwaysActive: true,
            icon: (color, fontSize?) => <ScienceIcon color={color} fontSize={fontSize} />,
            actions: [
                {
                    type: 'action',
                    title: 'Performer Rankings',
                    description: `The latest estimates of how OnlyFans performers rank in terms of earnings.`,
                    icon: <EmojiEventsIcon />,
                    url: (id: string) => `/subscribers/${id}/labs/performerRanking`,
                    visible: true,
                },
            ],
        },
        housekeeping: {
            visible: true,
            name: 'Housekeeping',
            shortDescription:
                'Think of it as “smart lists” on steroids… Sort your active fans and the fans you follow into Lists, keeping the lists up to date daily.',
            alwaysActive: false,
            icon: (color, fontSize?) => <ListIcon color={color} fontSize={fontSize} />,
        },
        renameUsers: {
            visible: true,
            name: 'Rename Users',
            shortDescription: `Optional add-on for the Housekeeping system. Rename the users automatically based on the List they belong to! For example, add a 🆓 emoji before the names of everyone in the Freeloaders list, or add a 💰 emoji before the names of users in your Spenders lists.`,
            alwaysActive: false,
            icon: (color, fontSize?) => <DriveFileRenameOutlineIcon color={color} fontSize={fontSize} />,
        },
        blockUsers: {
            visible: true,
            name: 'Block Users',
            shortDescription: `Optional add-on for the Housekeeping system. The system leverages the power of the Housekeeping system to generate a List of users to block based on your search condition, and then systematically and daily go through this target list and block the users on there one by one.`,
            alwaysActive: false,
            icon: (color, fontSize?) => <BlockIcon color={color} fontSize={fontSize} />,
        },
        inCommonFans: {
            visible: true,
            name: 'In-Common Fans',
            shortDescription: `Optional add-on for the Housekeeping system. The system leverages the power of the Housekeeping system to generate a List of fans who are in-common (overlap) to two of your accounts. Both account must be running Housekeeing for the service to work.`,
            alwaysActive: false,
            icon: (color, fontSize?) => <JoinInnerIcon color={color} fontSize={fontSize} />,
        },
        unfollowUsers: {
            visible: true,
            name: 'Unfollow Users',
            shortDescription: `Optional add-on for the Housekeeping system. The system leverages the power of the Housekeeping system to generate a List of users to unfollow based on your search condition, and then systematically and daily go through this target list and unfollow the users on there one by one.`,
            alwaysActive: false,
            icon: (color, fontSize?) => <ThumbDownIcon color={color} fontSize={fontSize} />,
        },
        unblockUsers: {
            visible: true,
            name: 'Unblock Users',
            shortDescription:
                'Set those jailbirds free! Unblock all the blocked users, or unblock & follow hundreds of users at a time daily until everyone is unblocked and followed.',
            alwaysActive: false,
            icon: (color, fontSize?) => <MoodIcon color={color} fontSize={fontSize} />,
        },
    },
    platforms: {
        onlyfans: {
            name: 'OnlyFans',
            url: 'https://onlyfans.com',
            icon: (fontSize?: 'small' | 'inherit' | 'medium' | 'large' | undefined) => <IconOnlyFans fontSize={fontSize} />,
        },
        instagram: {
            name: 'Instagram',
            url: 'https://instagram.com',
            icon: (fontSize?: 'small' | 'inherit' | 'medium' | 'large' | undefined) => <IconInstagram fontSize={fontSize} />,
        },
        tiktok: {
            name: 'TikTok',
            url: 'https://tiktok.com',
            icon: (fontSize?: 'small' | 'inherit' | 'medium' | 'large' | undefined) => <IconTikTok fontSize={fontSize} />,
        },
        twitter: {
            name: 'Twitter',
            url: 'https://twitter.com',
            icon: (fontSize?: 'small' | 'inherit' | 'medium' | 'large' | undefined) => <IconTwitter fontSize={fontSize} />,
        },
        reddit: {
            name: 'Reddit',
            url: 'https://reddit.com',
            icon: (fontSize?: 'small' | 'inherit' | 'medium' | 'large' | undefined) => <IconReddit fontSize={fontSize} />,
        },
    },
};

export const SettingsContext = createContext(settings);

export const SettingsContextProvider = (props: any) => {
    return <SettingsContext.Provider value={settings}>{props.children}</SettingsContext.Provider>;
};
