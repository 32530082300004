import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    Box,
    Button,
    Collapse,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { SextforceAutoRepost, SextforceAutoRepostWithLatestRepostLog } from '../../../../hooks/useSextforceAutoRepost';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError } from '../../../../utils/common';
import { TaskOccurrence } from './SextforceAutoRepostCalendar';
import SextforceAutoRepostCalendarLocalMenu from './SextforceAutoRepostCalendarLocalMenu';
import SextforceAutoRepostChangeNextAutoRepostDateDialog from './SextforceAutoRepostChangeNextAutoRepostDateDialog';
import SextforceAutoRepostEditRepostDialog from './SextforceAutoRepostEditRepostDialog';
import SextforceAutoRepostShareDialog from './SextforceAutoRepostShareDialog';
import { AutoRepostNameAndBadges } from './SextforceAutoRepostTableRepostsHistoryRow';
import SextforceAutoRepostTableRepostsHistoryRowLogs from './SextforceAutoRepostTableRepostsHistoryRowLogs';

const AutoRepostCard = (props: {
    subscriber: any;
    autoRepost: SextforceAutoRepostWithLatestRepostLog;
    refetchAutoReposts: () => void;
    isFutureScheduledMessage: boolean;
    futureScheduledDate?: moment.Moment;
    setSelectedDate: (day: moment.Moment) => void;
}) => {
    const { subscriber, autoRepost, refetchAutoReposts, isFutureScheduledMessage, futureScheduledDate, setSelectedDate } = props;

    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme = useTheme();
    const params = useParams();
    const axios = useAxios();
    const dialog = useDialog();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [changeNextRepostDateDialogOpen, setChangeNextRepostDateDialogOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isReactivatingAutoRepost, setIsReactivatingAutoRepost] = useState(false);

    const deleteAutoRepost = async (autoRepostId: string) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsDeleting(true);

        const data = await axios
            .delete(`${settingsContext.routes.autoRepost.base}${params.userId}/${autoRepostId}`)
            .then(async response => {
                return response.data as SextforceAutoRepost;
            })
            .catch(error => {
                setIsDeleting(false);
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const handleDeleteAutoRepost = (autoRepostId: string) => {
        deleteAutoRepost(autoRepostId).then(data => {
            if (!data) {
                return;
            }

            enqueueSnackbar({
                variant: 'success',
                message:
                    'Auto Repost has been scheduled for deactivation. In a minute or two it will be deactivated and will send the final notification and summary on Telegram.',
            });

            setIsDeleting(false);
            refetchAutoReposts();
        });
    };

    const changeAutoRepostActiveState = async (autoRepostId: string, active: boolean) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsReactivatingAutoRepost(true);

        const data = await axios
            .put(`${settingsContext.routes.autoRepost.base}${params.userId}/${autoRepostId}/active`, {
                active,
            })
            .then(async response => {
                return response.data as SextforceAutoRepost;
            })
            .catch(error => {
                setIsReactivatingAutoRepost(false);
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const handleActivateAutoRepost = async (autoRepostId: string) => {
        return changeAutoRepostActiveState(autoRepostId, true).then(() => {
            enqueueSnackbar({
                variant: 'success',
                message: 'Auto Repost has been reactivated',
            });

            refetchAutoReposts();
        });
    };

    const handleDeactivateAutoRepost = async (autoRepostId: string) => {
        return changeAutoRepostActiveState(autoRepostId, false).then(() => {
            enqueueSnackbar({
                variant: 'success',
                message: 'Auto Repost has been deactivated',
            });

            refetchAutoReposts();
        });
    };

    return (
        <Box
            pl={1}
            pr={1}
            pb={0}
            sx={{
                borderBottom: '1px solid',
                borderBottomColor: 'divider',
                backgroundColor: isFutureScheduledMessage ? 'grey.50' : 'transparent',
            }}
        >
            <Table
                width={'100%'}
                size="small"
                sx={{
                    '& td': {
                        '&:first-of-type': {
                            paddingLeft: '0px',
                            paddingRight: '0px',
                        },
                        '&:last-child': {
                            paddingRight: '0px',
                        },
                        paddingLeft: 1,
                        paddingRight: 1,
                        borderBottom: 'none',
                    },
                    '& th': {
                        '&:first-of-type': {
                            paddingLeft: '0px',
                            paddingRight: '0px',
                        },
                        '&:last-child': {
                            paddingRight: '0px',
                        },
                        // Make header cells bold
                        fontWeight: 'bold',
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                    bgcolor: open ? 'grey.50' : 'transparent',
                    tableLayout: 'fixed',
                }}
            >
                <TableBody>
                    <TableRow>
                        <TableCell
                            width={'80px'}
                            valign="top"
                            sx={{
                                borderRight: '6px solid',
                                borderRightColor: autoRepost.color ? autoRepost.color.color : 'divider',
                                paddingLeft: 0,
                                minWidth: 80,
                                verticalAlign: 'top',
                            }}
                        >
                            <Stack direction="column" spacing={0} alignItems="center">
                                {isFutureScheduledMessage && futureScheduledDate && (
                                    <Button
                                        variant="text"
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            setSelectedDate(moment(autoRepost.nextRepostDate));
                                        }}
                                        startIcon={<EventRepeatIcon />}
                                    >
                                        {moment(autoRepost.nextRepostDate).format('DD MMM')}
                                    </Button>
                                )}
                                <Box>{moment(futureScheduledDate).format('hh:mma')}</Box>
                                <Tooltip title={open ? 'Hide Reposts History' : 'Show Reposts History'}>
                                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </TableCell>
                        <TableCell sx={{ textWrap: 'wrap', overflow: 'scroll' }}>
                            <Stack direction="column" spacing={0.5}>
                                <AutoRepostNameAndBadges
                                    autoRepost={autoRepost}
                                    isFutureScheduledMessage={isFutureScheduledMessage}
                                    setSelectedDate={setSelectedDate}
                                />
                            </Stack>
                        </TableCell>
                        <TableCell
                            width={60}
                            align="center"
                            sx={{
                                borderBottom: 'none',
                            }}
                        >
                            <SextforceAutoRepostCalendarLocalMenu
                                autoRepost={autoRepost}
                                subscriber={subscriber}
                                setChangeNextRepostDateDialogOpen={setChangeNextRepostDateDialogOpen}
                                setEditDialogOpen={setEditDialogOpen}
                                setShareDialogOpen={setShareDialogOpen}
                                handleActivateAutoRepost={handleActivateAutoRepost}
                                handleDeactivateAutoRepost={handleDeactivateAutoRepost}
                                handleDeleteAutoRepost={handleDeleteAutoRepost}
                                isDeleting={isDeleting}
                                isReactivatingAutoRepost={isReactivatingAutoRepost}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                            }}
                            colSpan={3}
                        >
                            <Collapse
                                in={open}
                                timeout={{
                                    enter: theme.transitions.duration.enteringScreen,
                                    exit: theme.transitions.duration.leavingScreen,
                                }}
                                unmountOnExit
                                sx={{
                                    overflow: 'hidden',
                                }}
                            >
                                <SextforceAutoRepostTableRepostsHistoryRowLogs subscriber={subscriber} autoRepost={autoRepost} />
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <SextforceAutoRepostShareDialog
                subscriber={subscriber}
                autoRepost={autoRepost}
                open={shareDialogOpen}
                onClose={() => setShareDialogOpen(false)}
                theme={theme}
            />
            <SextforceAutoRepostEditRepostDialog
                subscriber={subscriber}
                autoRepost={autoRepost}
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                refetchAutoReposts={refetchAutoReposts}
            />
            <SextforceAutoRepostChangeNextAutoRepostDateDialog
                subscriber={subscriber}
                autoRepost={autoRepost}
                open={changeNextRepostDateDialogOpen}
                onClose={() => setChangeNextRepostDateDialogOpen(false)}
                refetchAutoReposts={refetchAutoReposts}
            />
        </Box>
    );
};

type Props = {
    subscriber: any;
    taskOccurrences: TaskOccurrence[];
    refetchAutoReposts: () => void;
    selectedDate: moment.Moment;
    setSelectedDate: (date: moment.Moment) => void;
    handlePrevDay: () => void;
    handleNextDay: () => void;
};

const SextforceAutoRepostCalendarDayView = (props: Props) => {
    const { subscriber, taskOccurrences, refetchAutoReposts, selectedDate, setSelectedDate, handlePrevDay, handleNextDay } = props;

    return (
        <Box sx={{ width: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pb: 2,
                    mb: 0,
                    borderBottom: '1px solid',
                    borderBottomColor: 'divider',
                }}
            >
                <IconButton onClick={handlePrevDay} disabled={moment(selectedDate).isSame(moment(), 'day')}>
                    <ChevronLeft />
                </IconButton>
                <Typography variant="h6" sx={{ flex: 1, textAlign: 'center' }}>
                    {selectedDate.format('LL')}
                </Typography>
                <IconButton onClick={handleNextDay}>
                    <ChevronRight />
                </IconButton>
            </Box>

            <Stack spacing={0}>
                {taskOccurrences.length === 0 && (
                    <Typography variant="body1" color="text.secondary" align="center" gutterBottom>
                        No auto reposts scheduled for this day.
                    </Typography>
                )}
                {moment(selectedDate).isBefore(moment(), 'day') && (
                    <Typography variant="body1" color="text.secondary" align="center" gutterBottom sx={{ mt: 2 }}>
                        This day has already passed!
                    </Typography>
                )}
                {taskOccurrences.map((taskOccurrence, index) => (
                    <AutoRepostCard
                        key={index}
                        subscriber={subscriber}
                        autoRepost={taskOccurrence.task}
                        refetchAutoReposts={refetchAutoReposts}
                        isFutureScheduledMessage={taskOccurrence.isRepeat}
                        futureScheduledDate={taskOccurrence.isRepeat ? taskOccurrence.dateTime : undefined}
                        setSelectedDate={setSelectedDate}
                    />
                ))}
            </Stack>
        </Box>
    );
};

export default SextforceAutoRepostCalendarDayView;
