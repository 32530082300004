import LinkIcon from '@mui/icons-material/Link';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StarIcon from '@mui/icons-material/Star';
import VideocamIcon from '@mui/icons-material/Videocam';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Alert,
    Box,
    Grid,
    IconButton,
    LinearProgress,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import dinero from 'dinero.js';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { SextforceAutoRepost } from '../../../../hooks/useSextforceAutoRepost';
import useSextforceAutoRepostLogs, { SextforceAutoRepostLog } from '../../../../hooks/useSextforceAutoRepostLogs';
import { objectIdToDate, secondsToTimeString } from '../../../../utils/common';
import NA from '../../../common/NA';

const AllStatsSummaryBox = (props: { autoRepostLogs: SextforceAutoRepostLog[] }) => {
    const { autoRepostLogs } = props;

    const SummaryItem = (props: { label: string; value: number | string | React.ReactNode }) => {
        const { label, value } = props;

        return (
            <>
                <Grid item xs={8}>
                    <Typography variant="subtitle2">{label}</Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                    <Typography variant="subtitle2">{value?.toLocaleString() || 0}</Typography>
                </Grid>
            </>
        );
    };

    return (
        <Alert icon={false} severity="success">
            <Grid container alignItems="center" spacing={0}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom fontWeight="bold">
                        Summary
                    </Typography>
                </Grid>
                <SummaryItem
                    label="Purchases"
                    value={`${autoRepostLogs.reduce((acc, curr) => acc + (curr.stats?.purchasedCount || 0), 0)} (${dinero({
                        amount: Math.trunc(autoRepostLogs.reduce((acc, curr) => acc + (curr.stats?.purchasedSumm || 0), 0) * 100),
                        currency: 'USD',
                    }).toFormat()})`}
                />
                <SummaryItem
                    label="Tips"
                    value={`${autoRepostLogs.reduce((acc, curr) => acc + (curr.stats?.tipCount || 0), 0)} (${dinero({
                        amount: Math.trunc(autoRepostLogs.reduce((acc, curr) => acc + (curr.stats?.tipSum || 0), 0) * 100),
                        currency: 'USD',
                    }).toFormat()})`}
                />
                <SummaryItem label="Views" value={autoRepostLogs.reduce((acc, curr) => acc + (curr.stats?.lookCount || 0), 0)} />
                <SummaryItem label="Likes" value={autoRepostLogs.reduce((acc, curr) => acc + (curr.stats?.likeCount || 0), 0)} />
                <SummaryItem label="Comments" value={autoRepostLogs.reduce((acc, curr) => acc + (curr.stats?.commentCount || 0), 0)} />
                <SummaryItem label="Video Views" value={autoRepostLogs.reduce((acc, curr) => acc + (curr.stats?.videoLookCount || 0), 0)} />
                <SummaryItem label="Video Plays" value={autoRepostLogs.reduce((acc, curr) => acc + (curr.stats?.videoPlayCount || 0), 0)} />
                <SummaryItem
                    label="Video Play Duration"
                    value={secondsToTimeString(autoRepostLogs.reduce((acc, curr) => acc + (curr.stats?.videoPlayDuration || 0), 0))}
                />
                <SummaryItem
                    label="View Duration"
                    value={secondsToTimeString(autoRepostLogs.reduce((acc, curr) => acc + (curr.stats?.lookDuration || 0), 0))}
                />
            </Grid>
        </Alert>
    );
};

const StatsGraph = (props: { autoRepostLog: SextforceAutoRepostLog }) => {
    const { autoRepostLog } = props;

    const data = autoRepostLog.stats.likeChart.map((like: any, index: number) => {
        return {
            date: new Date(like.date),
            likes: like.count,
            looks: autoRepostLog.stats.lookChart[index].count,
            uniqueLooks: autoRepostLog.stats.uniqueLookChart[index].count,
            comments: autoRepostLog.stats.commentChart[index].count,
        };
    });

    return (
        <Box sx={{ width: '100%', aspectRatio: '624/200' }}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={data}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="date"
                        scale={'time'}
                        tickFormatter={(value: Date) => moment(value).format('MMM D, YYYY')}
                        domain={['dataMin', 'dataMax']}
                        tick={{ fontSize: 11 }}
                    />
                    {/* <YAxis
                        domain={[
                            (dataMin: number) => Math.round(dataMin - dataMin * 0.1),
                            (dataMax: number) => Math.round(dataMax + dataMax * 0.1),
                        ]}
                        allowDecimals={false}
                        tickFormatter={(value: number) => value.toLocaleString()}
                        type="number"
                    /> */}
                    <Tooltip />
                    <Line type="linear" dataKey="likes" stroke="#f8749d" />
                    <Line type="linear" dataKey="looks" stroke="#58c3f0" />
                    <Line type="linear" dataKey="uniqueLooks" stroke="#6b6b6b" />
                    <Line type="linear" dataKey="comments" stroke="#ffb968" />
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

const VideoStatsBox = (props: { autoRepostLog: SextforceAutoRepostLog; theme: Theme }) => {
    const { autoRepostLog, theme } = props;

    return (
        <Box
            sx={{
                // rounded corners and background color
                borderRadius: theme.shape.borderRadius,
                // bgcolor: 'grey.300',
                p: 1,
                mb: 1,
            }}
        >
            <Table
                size="small"
                width="100%"
                sx={{
                    // remove all bottom borders
                    '& td': {
                        borderBottom: 'none',
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                }}
            >
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                <PlayArrowIcon color="primary" fontSize="small" />
                                <div>Video Views</div>
                            </Stack>
                        </TableCell>
                        <TableCell align="right">
                            {autoRepostLog.stats && autoRepostLog.stats.videoLookCount ? (
                                autoRepostLog.stats.videoLookCount.toLocaleString()
                            ) : (
                                <NA />
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                <PlayArrowIcon color="primary" fontSize="small" />
                                <div>Video Plays</div>
                            </Stack>
                        </TableCell>
                        <TableCell align="right">
                            {autoRepostLog.stats && autoRepostLog.stats.videoPlayCount ? (
                                autoRepostLog.stats.videoPlayCount.toLocaleString()
                            ) : (
                                <NA />
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                <VideocamIcon color="primary" fontSize="small" />
                                <div>Video Play Duration</div>
                            </Stack>
                        </TableCell>
                        <TableCell align="right">
                            {autoRepostLog.stats && autoRepostLog.stats.videoPlayDuration ? (
                                secondsToTimeString(autoRepostLog.stats.videoPlayDuration)
                            ) : (
                                <NA />
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                <VideocamIcon color="primary" fontSize="small" />
                                <div>Average Video Play Duration</div>
                            </Stack>
                        </TableCell>
                        <TableCell align="right">
                            {autoRepostLog.stats && autoRepostLog.stats.videoPlayDurationAverage ? (
                                secondsToTimeString(autoRepostLog.stats.videoPlayDurationAverage)
                            ) : (
                                <NA />
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                <VisibilityIcon color="primary" fontSize="small" />
                                <div>View Duration</div>
                            </Stack>
                        </TableCell>
                        <TableCell align="right">
                            {autoRepostLog.stats && autoRepostLog.stats.lookDuration ? (
                                secondsToTimeString(autoRepostLog.stats.lookDuration)
                            ) : (
                                <NA />
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                <VisibilityIcon color="primary" fontSize="small" />
                                <div>View Duration Average</div>
                            </Stack>
                        </TableCell>
                        <TableCell align="right">
                            {autoRepostLog.stats && autoRepostLog.stats.lookDurationAverage ? (
                                secondsToTimeString(autoRepostLog.stats.lookDurationAverage)
                            ) : (
                                <NA />
                            )}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    );
};

const AutoRepostLogDetailsRow = (props: { subscriber: any; autoRepostLog: SextforceAutoRepostLog; index: number; theme: Theme }) => {
    const { subscriber, autoRepostLog, index, theme } = props;

    return (
        <React.Fragment>
            <TableRow
                sx={{
                    // Remove bottom border from this row on every cell
                    '& td': {
                        borderBottom: 'none',
                    },
                }}
            >
                <TableCell>
                    <IconButton component={Link} to={`https://onlyfans.com/${autoRepostLog.postId}/${subscriber.username}`}>
                        <LinkIcon />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Stack direction="column" spacing={0.5} alignItems="center">
                        <div>{moment(objectIdToDate(autoRepostLog._id)).format('LLL')}</div>
                    </Stack>
                </TableCell>
                <TableCell align="center">
                    <Stack direction="column" spacing={0}>
                        {autoRepostLog.stats ? (
                            <Typography variant="inherit">
                                {autoRepostLog.stats.tipCount ? autoRepostLog.stats.tipCount.toLocaleString() : '0'}
                            </Typography>
                        ) : (
                            <NA />
                        )}
                        {autoRepostLog.stats ? (
                            <Typography variant="inherit" color="textSecondary">
                                {autoRepostLog.stats.tipSum
                                    ? dinero({
                                          amount: Math.trunc(autoRepostLog.stats.tipSum * 100),
                                          currency: 'USD',
                                      }).toFormat()
                                    : '$0.00'}
                            </Typography>
                        ) : (
                            <NA />
                        )}
                    </Stack>
                </TableCell>
                <TableCell align="center">
                    <Stack direction="column" spacing={0}>
                        {autoRepostLog.stats ? (
                            <Typography variant="inherit">
                                {autoRepostLog.stats.lookCount ? autoRepostLog.stats.lookCount.toLocaleString() : '0'}
                            </Typography>
                        ) : (
                            <NA />
                        )}
                        {autoRepostLog.stats ? (
                            <Typography variant="inherit" color="textSecondary">
                                {autoRepostLog.stats.uniqueLookCount ? autoRepostLog.stats.uniqueLookCount.toLocaleString() : '0'}
                            </Typography>
                        ) : (
                            <NA />
                        )}
                    </Stack>
                </TableCell>
                <TableCell align="center">
                    {autoRepostLog.stats ? (
                        <Typography variant="inherit">
                            {autoRepostLog.stats.likeCount ? autoRepostLog.stats.likeCount.toLocaleString() : '0'}
                        </Typography>
                    ) : (
                        <NA />
                    )}
                </TableCell>
                <TableCell align="center">
                    {autoRepostLog.stats ? (
                        <Typography variant="inherit">
                            {autoRepostLog.stats.commentCount ? autoRepostLog.stats.commentCount.toLocaleString() : '0'}
                        </Typography>
                    ) : (
                        <NA />
                    )}
                </TableCell>
            </TableRow>
            {index === 0 && (
                <TableRow>
                    <TableCell colSpan={6}>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <StarIcon color="info" sx={{ fontSize: '12pt' }} />
                            <div style={{ color: theme.palette.info.main }}>Original Post</div>
                        </Stack>
                    </TableCell>
                </TableRow>
            )}
            <TableRow>
                <TableCell align="left" colSpan={6}>
                    {autoRepostLog.stats && <StatsGraph autoRepostLog={autoRepostLog} />}

                    <VideoStatsBox autoRepostLog={autoRepostLog} theme={theme} />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

type Props = {
    subscriber: any;
    autoRepost: SextforceAutoRepost;
};

const SextforceAutoRepostTableRepostsHistoryRowLogs = (props: Props) => {
    const { subscriber, autoRepost } = props;
    const theme: Theme = useTheme();
    const { data: autoRepostLogs, isLoading: autoRepostLogsLoading } = useSextforceAutoRepostLogs(autoRepost);

    return (
        <Box
            sx={{
                bgcolor: 'grey.50',
                p: 1,
                overflowX: 'scroll',
            }}
        >
            <Typography variant="subtitle1" gutterBottom>
                Reposts History
            </Typography>
            <Typography variant="body2" gutterBottom>
                The repost logs and statistics get recorded just before the new post is posted.
            </Typography>
            <Box sx={{}}>
                {autoRepostLogsLoading && <LinearProgress />}
                {!autoRepostLogsLoading && autoRepostLogs && autoRepostLogs.length === 0 && (
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>No reposts yet</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                )}
                {!autoRepostLogsLoading && autoRepostLogs && autoRepostLogs.length > 0 && (
                    <>
                        <AllStatsSummaryBox autoRepostLogs={autoRepostLogs} />
                        <Table
                            size="small"
                            sx={{
                                // No padding on the left and right of the table but only on the first and last cell
                                '& td': {
                                    '&:first-of-type': {
                                        paddingLeft: '0px',
                                        paddingRight: '0px',
                                    },
                                    '&:last-child': {
                                        paddingRight: '0px',
                                    },
                                },
                                '& th': {
                                    '&:first-of-type': {
                                        paddingLeft: '0px',
                                        paddingRight: '0px',
                                    },
                                    '&:last-child': {
                                        paddingRight: '0px',
                                    },
                                },
                                // Remove the bordder from the cells in the last row
                                '& tr:last-child td': {
                                    borderBottom: 'none',
                                },
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell width={32}></TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell width={60} align="center">
                                        <Stack direction="column" spacing={0}>
                                            <Typography variant="inherit">Purchases</Typography>
                                            <Typography variant="inherit" color="textSecondary">
                                                Amount
                                            </Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell width={60} align="center">
                                        <Stack direction="column" spacing={0}>
                                            <Typography variant="inherit">Views</Typography>
                                            <Typography variant="inherit" color="textSecondary">
                                                Unique
                                            </Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell width={60} align="center">
                                        Likes
                                    </TableCell>
                                    <TableCell width={60} align="center">
                                        Comments
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {autoRepostLogs &&
                                    autoRepostLogs.map((autoRepostLog: SextforceAutoRepostLog, index: number) => (
                                        <AutoRepostLogDetailsRow
                                            key={autoRepostLog._id}
                                            subscriber={subscriber}
                                            autoRepostLog={autoRepostLog}
                                            index={index}
                                            theme={theme}
                                        />
                                    ))}
                            </TableBody>
                        </Table>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default SextforceAutoRepostTableRepostsHistoryRowLogs;
