import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import {
    SextforceOnlyFansTelegramNotification,
    SextforceOnlyFansTelegramNotificationOptions,
} from '../../../../../hooks/useSextforceOnlyFansTelegramNotifications';
import SextforceOnlyFansTelegramNotificationOptionsForm from './SextforceOnlyFansTelegramNotificationOptionsForm';

const SextforceOnlyFansTelegramNotificationOptionsEditForm = ({
    options,
    setOptions,
    isLoading,
    handleSave,
    refetchSextforceOnlyFansTelegramNotifications,
    setOpen,
}: {
    options: SextforceOnlyFansTelegramNotificationOptions;
    setOptions: (options: SextforceOnlyFansTelegramNotificationOptions) => void;
    isLoading: boolean;
    handleSave: (options: SextforceOnlyFansTelegramNotificationOptions) => Promise<SextforceOnlyFansTelegramNotification>;
    refetchSextforceOnlyFansTelegramNotifications: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<SextforceOnlyFansTelegramNotification[], unknown>>;
    setOpen: (show: boolean) => void;
}) => {
    const { enqueueSnackbar } = useSnackbar();

    return (
        <>
            <Grid container alignItems={'center'} spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h6">Notification Options</Typography>
                </Grid>

                <SextforceOnlyFansTelegramNotificationOptionsForm options={options} setOptions={setOptions} />

                <Grid item xs={8}>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        loading={isLoading}
                        disabled={options.notifyWhenUnreadCountHigherThan && options.notifyWhenUnreadCountHigherThanAmount < 1}
                        onClick={async () => {
                            const newSextforceOnlyFansTelegramNotification = await handleSave(options);

                            if (newSextforceOnlyFansTelegramNotification) {
                                enqueueSnackbar('Notification settings updated', {
                                    variant: 'success',
                                });

                                await refetchSextforceOnlyFansTelegramNotifications();

                                setOpen(false);
                            } else {
                                enqueueSnackbar('Failed to add new Telegram account', { variant: 'error' });
                            }
                        }}
                    >
                        Save Notification Settings
                    </LoadingButton>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="outlined"
                        color="error"
                        fullWidth
                        size="large"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default SextforceOnlyFansTelegramNotificationOptionsEditForm;
