import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import useAxios from './useAxios';

const useMessageUsersTotalSent = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const axios = useAxios();

    const fetchTotalSent = async (): Promise<{ totalSent: number }> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId) {
            const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/totalSent`;

            return axios
                .get(query)
                .then(async response => {
                    if (response.status === 200) {
                        return response.data;
                    }

                    return { totalSent: 0 };
                })
                .catch(error => {
                    console.error(error);

                    return { totalSent: 0 };
                });
        }

        return { totalSent: 0 };
    };

    return useQuery(['messageUsersTotalSent', params.userId], () => fetchTotalSent(), {
        refetchOnWindowFocus: false,
        // Stall for 3 minutes
        staleTime: 180000,
        enabled: userContext.jwtToken && settingsContext.apiKey && params.userId ? true : false,
    });
};

export default useMessageUsersTotalSent;
