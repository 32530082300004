import { LinearProgress, Theme, Typography } from '@mui/material';
import React from 'react';

const StyledCardTitleBar = ({
    title,
    badge,
    isLoading,
    theme,
}: {
    title: string | React.ReactNode;
    badge?: string | React.ReactNode;
    isLoading?: boolean;
    theme: Theme;
}) => {
    return (
        <>
            <div
                style={{
                    backgroundColor: theme.palette.primary.main,
                    minHeight: theme.spacing(6),
                    borderBottomWidth: 2,
                    borderBottomColor: theme.palette.secondary.main,
                    borderBottomStyle: 'solid',
                    textTransform: 'uppercase',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                }}
            >
                {typeof title === 'string' ? (
                    <Typography variant="subtitle1" color="white">
                        {title}
                    </Typography>
                ) : (
                    title
                )}
                {badge && (
                    <>
                        {typeof badge === 'string' ? (
                            <Typography variant="subtitle1" color="white">
                                {badge}
                            </Typography>
                        ) : (
                            badge
                        )}
                    </>
                )}
            </div>
            {isLoading && <LinearProgress />}
        </>
    );
};

export default StyledCardTitleBar;
