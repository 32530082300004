import moment from 'moment';
import { SextforceAutoRepostWithLatestRepostLog } from '../hooks/useSextforceAutoRepost';

interface RepostOccurrence {
    repost: SextforceAutoRepostWithLatestRepostLog;
    scheduledTime: moment.Moment;
}

export function calculateUpcomingReposts(
    reposts: SextforceAutoRepostWithLatestRepostLog[],
    fromTime: moment.Moment = moment(),
): RepostOccurrence[] {
    const upcomingReposts: RepostOccurrence[] = [];
    const endTime = moment(fromTime).add(24, 'hours');

    reposts.forEach(repost => {
        if (!repost.active) return;

        let nextRepostTime = moment(repost.nextRepostDate);

        // If first repost is beyond our 24h window, skip this repost
        if (nextRepostTime.isAfter(endTime)) return;

        // Add the first occurrence
        upcomingReposts.push({
            repost,
            scheduledTime: moment(nextRepostTime),
        });

        // Add additional occurrences if frequency is less than 24h
        while (true) {
            nextRepostTime = moment(nextRepostTime).add(repost.repostEveryAmount, repost.repostEveryUnit as moment.DurationInputArg2);
            if (nextRepostTime.isAfter(endTime)) break;

            upcomingReposts.push({
                repost,
                scheduledTime: moment(nextRepostTime),
            });
        }
    });

    return upcomingReposts.sort((a, b) => a.scheduledTime.valueOf() - b.scheduledTime.valueOf());
}

// Example usage:
/*
const now = moment();
const upcomingReposts = calculateUpcomingReposts(repostsList, now);

console.log(`Number of reposts in next 24 hours: ${upcomingReposts.length}`);
upcomingReposts.forEach(({ repost, scheduledTime }) => {
    console.log(`${repost.name} scheduled for ${scheduledTime.format('LLLL')}`);
});
*/

// Helper function to get just the count if that's all you need
export function getUpcomingRepostsCount(reposts: SextforceAutoRepostWithLatestRepostLog[], fromTime: moment.Moment = moment()): number {
    return calculateUpcomingReposts(reposts, fromTime).length;
}
