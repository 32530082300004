import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { OnlyFansSessionActionType } from '../types/onlyFansDbTypes';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';
import { SextforceAutoRepostColor } from './useSextforceAutoRepost';
import useSubscriber from './useSubscriber';

export interface ScheduledTask {
    _id?: string;
    subscriberId: string;
    actionType: OnlyFansSessionActionType;
    colorId?: string;
    color?: SextforceAutoRepostColor;
    scheduleDateTime: Date;
    complete: boolean;
    repeatEveryAmount?: number;
    repeatEveryUnit?: moment.unitOfTime.DurationConstructor;
    endDate?: Date;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any;
}

const useMessageUsersScheduledSessions = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();
    const queryClient = useQueryClient();
    const axios = useAxios();

    const { data: subscriber } = useSubscriber();

    const [isUpdatingScheduledMessage, setIsUpdatingScheduledMessage] = useState<boolean>(false);
    const [isDeletingScheduledMessage, setIsDeletingScheduledMessage] = useState<boolean>(false);

    // Fetch Scheduled Messages
    const fetchScheduledMessages = async (): Promise<ScheduledTask[]> => {
        const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/scheduled`;

        return axios
            .get(query)
            .then(async response => {
                if (response.status === 200) {
                    return response.data;
                } else {
                    return Promise.reject(response.data);
                }
            })
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);
            });
    };

    const updateScheduledMessage = async (
        messageId: string,
        scheduleDateTime: Date,
        repeatEveryAmount: number | undefined,
        repeatEveryUnit: string | undefined,
        endDate: Date | undefined,
        colorId?: string,
    ): Promise<ScheduledTask | null> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/scheduled/${messageId}`;

            setIsUpdatingScheduledMessage(true);

            return axios
                .put(query, {
                    scheduleDateTime,
                    repeatEveryAmount,
                    repeatEveryUnit,
                    endDate,
                    colorId,
                })
                .then(async response => {
                    if (response.status === 200) {
                        queryClient.invalidateQueries(['messageUsersScheduledMessages', subscriber._id]);

                        setIsUpdatingScheduledMessage(false);

                        return response.data;
                    } else {
                        setIsUpdatingScheduledMessage(false);

                        return Promise.reject(response.data);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);

                    setIsUpdatingScheduledMessage(false);

                    return null;
                });
        }

        return null;
    };

    const deleteScheduledMessage = async (messageId: string): Promise<{ success: true; id: string } | void> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/scheduled/${messageId}`;

            setIsDeletingScheduledMessage(true);

            return axios
                .delete(query)
                .then(async response => {
                    setIsDeletingScheduledMessage(false);

                    if (response.status === 200) {
                        queryClient.invalidateQueries(['messageUsersScheduledMessages', subscriber?._id]);

                        return response.data;
                    } else {
                        return Promise.reject(response.data);
                    }
                })
                .catch(error => {
                    setIsDeletingScheduledMessage(false);

                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    const messageUsersScheduledSessions = useQuery(['messageUsersScheduledMessages', subscriber?._id], () => fetchScheduledMessages(), {
        refetchOnWindowFocus: false,
        staleTime: 5000,
        enabled:
            userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber && subscriber._id
                ? true
                : false,
    });

    return {
        ...messageUsersScheduledSessions,
        updateScheduledMessage,
        isUpdatingScheduledMessage,
        deleteScheduledMessage,
        isDeletingScheduledMessage,
    };
};

export default useMessageUsersScheduledSessions;
