import ContactsIcon from '@mui/icons-material/Contacts';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { Box, IconButton, Skeleton, Stack, TableCell, TableRow, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { ContentState, EditorState } from 'draft-js';
import moment from 'moment';
import { useDialog } from 'muibox';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { OnlyFansMessaMessagesQueueDetails, SextforceAutoMassDmMessage } from '../../../../hooks/useMessageUsersMassDm';
import { SextforceAutoRepost } from '../../../../hooks/useSextforceAutoRepost';
import useSubscriberLists from '../../../../hooks/useSubscriberLists';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError, loadDraftsJSEditorStateFromHtml, parseNumberOrString } from '../../../../utils/common';
import Bullet from '../../../common/Bullet';
import NA from '../../../common/NA';
import MessageUsersFormEditiorPreview from '../sendMessage/MessageUsersFormEditiorPreview';

const momentPeriods: { [key: string]: string } = {
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
};

type Props = {
    subscriber: any;
    massDm: SextforceAutoMassDmMessage;
    refetchMassDms: () => void;
};

const MessageUsersAutoMassDmListTableRow = (props: Props) => {
    const { subscriber, massDm, refetchMassDms } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const params = useParams();
    const dialog = useDialog();
    const axios = useAxios();
    const [isDeleting, setIsDeleting] = useState(false);

    const messageDetails: OnlyFansMessaMessagesQueueDetails.Response | undefined | null = massDm.messageDetails;

    const { data: lists, isLoading: isLoadingLists } = useSubscriberLists(
        messageDetails && messageDetails.userLists && messageDetails.userLists.length > 0 ? true : false,
    );

    const editorState: EditorState =
        massDm.text && massDm.text.length > 0
            ? loadDraftsJSEditorStateFromHtml(massDm.text)
            : EditorState.createWithContent(ContentState.createFromText(''));

    const unsendMassDm = async (autoRepostId: string) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsDeleting(true);

        const data = await axios
            .delete(`${settingsContext.routes.autoRepost.base}${params.userId}/${autoRepostId}`)
            .then(async response => {
                return response.data as SextforceAutoRepost;
            })
            .catch(error => {
                setIsDeleting(false);
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const handleUnsendMassDm = (autoRepostId: string) => {
        unsendMassDm(autoRepostId).then(data => {
            if (!data) {
                return;
            }

            dialog
                .alert({
                    title: 'Unsend Mass DM',
                    message:
                        'The Mass DM has been successfully unsent. The message will be removed from the chats of the users who received it.',
                    ok: {
                        text: 'OK',
                        variant: 'contained',
                        color: 'primary',
                    },
                })
                .then(() => {
                    setIsDeleting(false);
                    refetchMassDms();
                });
        });
    };

    return (
        <>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    cursor: 'pointer',
                    '& td': {
                        borderBottom: 'unset',
                    },
                }}
            >
                <TableCell sx={{ textWrap: 'nowrap' }}>{moment(massDm.date).format('L LT')}</TableCell>
                <TableCell
                    sx={{
                        // no wrap text
                        textWrap: 'nowrap',
                    }}
                >
                    {massDm.name}
                </TableCell>
                <TableCell align="center" sx={{ textWrap: 'nowrap' }}>
                    {massDm.sentCount?.toLocaleString() || <NA />}
                </TableCell>
                <TableCell align="center" sx={{ textWrap: 'nowrap' }}>
                    {typeof massDm.viewedCount === 'number' ? (
                        <Stack direction="column" alignContent={'center'}>
                            <Typography variant="inherit">{massDm.viewedCount.toLocaleString()}</Typography>
                            <Typography variant="inherit" color="text.secondary">
                                {massDm.viewedCount > 0 ? `${((massDm.viewedCount / massDm.sentCount) * 100).toFixed(2)}%` : '0%'}
                            </Typography>
                        </Stack>
                    ) : (
                        <NA />
                    )}
                </TableCell>
                <TableCell align="center" sx={{ textWrap: 'nowrap' }}>
                    {massDm.purchasedCount && !massDm.isFree ? (
                        <Stack direction="column" alignContent={'center'}>
                            <Typography variant="inherit">{massDm.purchasedCount.toLocaleString()}</Typography>
                            <Typography variant="inherit" color="text.secondary">
                                $
                                {(massDm.price * massDm.purchasedCount).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Stack>
                    ) : massDm.isFree ? (
                        'FREE'
                    ) : (
                        <NA />
                    )}
                </TableCell>
                <TableCell align="center">
                    <Stack direction="row" spacing={0.5} justifyContent={'center'} alignItems={'center'}>
                        <IconButton color="error" onClick={() => handleUnsendMassDm(massDm._id!)} disabled={isDeleting}>
                            <NoAccountsIcon />
                        </IconButton>
                    </Stack>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 4,
                        paddingTop: 4,
                        // ...(!open && { borderBottom: 'none' }),
                    }}
                    colSpan={7}
                >
                    <Stack direction="column" spacing={1}>
                        <Typography variant="inherit" color="textSecondary">
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Tooltip title="Send To">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <ContactsIcon color="primary" sx={{ fontSize: '12pt' }} />
                                    </div>
                                </Tooltip>
                                {isLoadingLists && <Skeleton width={100} variant="text" />}
                                <Stack direction="row" spacing={0.5} alignItems={'center'}>
                                    {lists && !isLoadingLists && messageDetails && messageDetails.userLists ? (
                                        messageDetails.userLists.map((u, index) => (
                                            <React.Fragment key={index}>
                                                <Typography variant="inherit">
                                                    {lists.find(l => parseNumberOrString(u) === l.id)?.name || 'Unknown List'}
                                                </Typography>
                                                {index < messageDetails.userLists.length - 1 && <Bullet />}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <NA />
                                    )}
                                </Stack>
                            </Stack>
                        </Typography>
                        <Typography variant="inherit" color="textSecondary">
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Tooltip title="Exclude">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <NoAccountsIcon color="error" sx={{ fontSize: '12pt' }} />
                                    </div>
                                </Tooltip>
                                {isLoadingLists && <Skeleton width={100} variant="text" />}
                                <Stack direction="row" spacing={0.5} alignItems={'center'}>
                                    {lists && !isLoadingLists && messageDetails && messageDetails.excludedLists ? (
                                        messageDetails.excludedLists.map((u, index) => (
                                            <React.Fragment key={index}>
                                                <Typography variant="inherit">
                                                    {lists.find(l => parseNumberOrString(u) === l.id)?.name || 'Unknown List'}
                                                </Typography>
                                                {index < messageDetails.userLists.length - 1 && <Bullet />}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <NA />
                                    )}
                                </Stack>
                            </Stack>
                        </Typography>
                        {massDm.text && massDm.text.length > 0 && (
                            <Box
                                sx={{
                                    '&:hover': {
                                        // animate opacity on hover
                                        transition: 'opacity 0.3s',
                                        opacity: 1,
                                    },
                                    // animate opacity on leave
                                    transition: 'opacity 0.15s',
                                    opacity: 0.7,
                                    // smaller font for all children
                                    '& *': {
                                        fontSize: '0.9rem',
                                    },
                                }}
                            >
                                <MessageUsersFormEditiorPreview
                                    editorState={editorState}
                                    lockMessage={!massDm.isFree}
                                    lockMessagePrice={massDm.price}
                                    lockedText={messageDetails?.lockedText || false}
                                    taggedCreators={
                                        messageDetails?.releaseForms?.map(f => {
                                            return { id: f.id, name: f.name };
                                        }) || []
                                    }
                                    taggedReleaseForms={[]}
                                    theme={theme}
                                    fullWidth
                                />
                            </Box>
                        )}
                    </Stack>
                </TableCell>
            </TableRow>
        </>
    );
};

export default MessageUsersAutoMassDmListTableRow;
