import { Card, CardContent, Grid, Theme, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import useMetricsDailyStats from '../../hooks/useMetricsDailyStats';
import useSubscriberCategories from '../../hooks/useSubscriberCategories';
import ReloginAlertCard from '../common/ReloginAlertCard';
import UsernameWithAvatar from '../common/UsernameWithAvatar';
import ValueAndTrend from '../subscriber/ValueAndTrend';
import DashboardMyAccountsServicesList from './DashboardMyAccountsServicesList';
import DashboardMyAccountsSharedCredit from './DashboardMyAccountsSharedCredit';

type DashboardMyAccountsSubscriberCardProps = {
    subscriber: any;
    setStats: (stats: any) => void;
    refetchSubscribers: () => void;
    openTransferCreditDialog: (subscriber: any) => void;
};

const DashboardMyAccountsSubscriberCard = (props: DashboardMyAccountsSubscriberCardProps) => {
    const { subscriber, setStats, refetchSubscribers, openTransferCreditDialog } = props;
    const theme: Theme = useTheme();
    const timezone: string = useMemo(() => moment.tz.guess(), []);
    const startDate: Date = useMemo(() => moment().subtract(2, 'days').startOf('day').toDate(), []);
    const endDate: Date = useMemo(() => moment().endOf('day').toDate(), []);
    const { data: dailyStats, isFetching: dailyStatsLoading } = useMetricsDailyStats(startDate, endDate, timezone, subscriber._id);
    const { data: categories, isFetching: categoriesLoading } = useSubscriberCategories();

    const category = useMemo(() => {
        if (categoriesLoading || !subscriber.cognito || !subscriber.cognito.category) {
            return undefined;
        }

        return categories && categories.find(c => c._id === subscriber.cognito.category);
    }, [categories, categoriesLoading, subscriber.cognito]);

    useEffect(() => {
        if (dailyStats) {
            setStats((prevStats: any[]) => {
                return {
                    ...prevStats,
                    [subscriber._id]: dailyStats,
                };
            });
        }
    }, [dailyStats, setStats, subscriber._id]);

    return (
        <Card
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                ...(category &&
                    category.color && {
                        backgroundImage: `linear-gradient(90deg, ${category.color} 0%, ${category.color} 4px, transparent 4px);`,
                    }),
            }}
        >
            <Box
                sx={{
                    p: 2,
                    pt: 1,
                }}
            >
                <UsernameWithAvatar subscriber={subscriber} category={category} refetchSubscribers={refetchSubscribers} />
            </Box>

            <CardContent sx={{ pt: 1 }}>
                {!subscriber.isLoggedIn && (
                    <Box sx={{ mb: 1 }}>
                        <ReloginAlertCard subscriber={subscriber} refetchSubscriber={refetchSubscribers} />
                    </Box>
                )}

                <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={4}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="body2" color="text.secondary">
                                    Fans
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ValueAndTrend
                                    loading={dailyStatsLoading}
                                    value={
                                        dailyStats &&
                                        dailyStats[0] &&
                                        dailyStats[0].counters &&
                                        dailyStats[0].counters.subscribers &&
                                        dailyStats[0].counters.subscribers.active
                                            ? dailyStats[0].counters.subscribers.active
                                            : undefined
                                    }
                                    previousValue={
                                        dailyStats &&
                                        dailyStats[1] &&
                                        dailyStats[1].counters &&
                                        dailyStats.length > 1 &&
                                        dailyStats[1].counters.subscribers &&
                                        dailyStats[1].counters.subscribers.active
                                            ? dailyStats[1].counters.subscribers.active
                                            : undefined
                                    }
                                    type={'number'}
                                    separateLines={true}
                                    textAlign="center"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="body2" color="text.secondary">
                                    Following
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ValueAndTrend
                                    loading={dailyStatsLoading}
                                    value={
                                        dailyStats &&
                                        dailyStats[0] &&
                                        dailyStats[0].counters &&
                                        dailyStats[0].counters.subscriptions &&
                                        dailyStats[0].counters.subscriptions.active
                                            ? dailyStats[0].counters.subscriptions.active
                                            : undefined
                                    }
                                    previousValue={
                                        dailyStats &&
                                        dailyStats.length > 1 &&
                                        dailyStats[1].counters &&
                                        dailyStats[1].counters.subscriptions &&
                                        dailyStats[1].counters.subscriptions.active
                                            ? dailyStats[1].counters.subscriptions.active
                                            : undefined
                                    }
                                    type={'number'}
                                    separateLines={true}
                                    textAlign="center"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="body2" color="text.secondary">
                                    Ranking
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ValueAndTrend
                                    loading={dailyStatsLoading}
                                    value={
                                        dailyStats && dailyStats[0] && dailyStats[0].performerTop ? dailyStats[0].performerTop : undefined
                                    }
                                    previousValue={
                                        dailyStats && dailyStats.length > 1 && dailyStats[1].performerTop
                                            ? dailyStats[1].performerTop
                                            : undefined
                                    }
                                    type={'number'}
                                    separateLines={true}
                                    textAlign="center"
                                    logic="less"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="body2" color="text.secondary">
                                    Earnings
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ValueAndTrend
                                    loading={dailyStatsLoading}
                                    value={
                                        dailyStats &&
                                        dailyStats[0] &&
                                        dailyStats[0].earnings &&
                                        dailyStats[0].earnings.list &&
                                        dailyStats[0].earnings.list.total &&
                                        dailyStats[0].earnings.list.total.all &&
                                        dailyStats[0].earnings.list.total.all.total_gross
                                            ? dailyStats[0].earnings.list.total.all.total_gross
                                            : undefined
                                    }
                                    previousValue={
                                        dailyStats &&
                                        dailyStats.length > 1 &&
                                        dailyStats[1].earnings &&
                                        dailyStats[1].earnings.list.total &&
                                        dailyStats[1].earnings.list.total.all &&
                                        dailyStats[1].earnings.list.total.all.total_gross
                                            ? dailyStats[1].earnings.list.total.all.total_gross
                                            : undefined
                                    }
                                    type={'money'}
                                    separateLines={true}
                                    textAlign="center"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <DashboardMyAccountsSharedCredit subscriber={subscriber} openTransferCreditDialog={openTransferCreditDialog} />
                    </Grid>
                </Grid>

                <DashboardMyAccountsServicesList subscriber={subscriber} theme={theme} />
            </CardContent>
        </Card>
    );
};

export default DashboardMyAccountsSubscriberCard;
