import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { handleHttpError } from '../utils/common';
import { OnlyFansMetricType } from './useSextforceMetricsCampaignsOverview';

export type OnlyFansMetricsShareLinksType = 'metric' | 'metricsGroup';

export interface OnlyFansMetricsShareLinks {
    _id?: string;
    metricId: string;
    type?: OnlyFansMetricsShareLinksType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: any;
    expiresIn?: Date;
    link?: string;
}

export interface OnlyFansMetricsPublicData {
    username: string;
    avatar?: string;
    name: string | null;
    type: OnlyFansMetricType;
    createdAt: Date | null;
    updatedAt: Date | null;
    trialUrl: string | null;
    campaignCode: string | null;
    counters: {
        countSubscribers?: number;
        countTransitions?: number;
        claimsCount?: number;
        subscribeCounts?: number;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        metricsDatas: any;
    };
    subscribersGraph?: {
        interval: string;
        count: number;
    }[];
    suspiciousUsers?: {
        userId: number;
        userName: string;
        subscribedAt: Date | null;
    }[];
    totalEarnings?: number;
}

export interface OnlyFansMetricsPublicResults {
    type: OnlyFansMetricsShareLinksType;
    name?: string;
    createdAt?: Date;
    results: OnlyFansMetricsPublicData | OnlyFansMetricsPublicData[];
}

const useSextforcePulicMetric = (metricShareLinkId: string | undefined) => {
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();

    // Retrieve subscriber data
    const fetchMetric = async (): Promise<OnlyFansMetricsPublicResults> => {
        const query: string = `${settingsContext.routes.sextforce.publicMetrics}${metricShareLinkId}`;

        return axios
            .get(query, {
                method: 'get',
                headers: {
                    apiKey: settingsContext.apiKey,
                },
            })
            .then(response => response.data);
    };

    return useQuery(['publicMetric', metricShareLinkId], () => fetchMetric(), {
        refetchOnWindowFocus: false,
        staleTime: 0,
        enabled: metricShareLinkId ? true : false,
        retry: 0,
        onError: (error: any) => {
            if (error && error.response && error.response.data && error.response.data.status && error.response.data.status === 400) {
                dialog
                    .alert({
                        message: 'Link does not exist or expired',
                        title: 'Error',
                        ok: {
                            text: 'OK',
                            color: 'primary',
                            variant: 'contained',
                        },
                    })
                    .then(() => {});

                return null;
            }

            handleHttpError(error, dialog);
        },
    });
};

export default useSextforcePulicMetric;
