import { alpha, Theme, Typography, useTheme } from '@mui/material';

const Bullet = () => {
    const theme: Theme = useTheme();

    return (
        <Typography variant="inherit" color={alpha(theme.palette.text.disabled, 0.25)}>
            &bull;
        </Typography>
    );
};

export default Bullet;
