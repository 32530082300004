import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { ScheduledTask } from '../../../../hooks/useMessageUsersScheduledSessions';
import MessageUsersPriorityMessageCalendarItemCard from './MessageUsersPriorityMessageCalendarItemCard';

const DateTitle = ({ date }: { date: Date }) => {
    return (
        <TableRow>
            <TableCell colSpan={2}>
                <Typography variant="h6" pl={1} pt={1} pb={1}>
                    {moment(date).format('MMMM D, YYYY')}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

type Props = {
    scheduledMessages: ScheduledTask[];
    setSelectedDate: (date: moment.Moment) => void;
    isScheduledMessagesLoading: boolean;
};

const MessageUsersPriorityMessageCalendarDayView = (props: Props) => {
    const { scheduledMessages, setSelectedDate, isScheduledMessagesLoading } = props;

    return (
        <Box
            sx={{
                display: 'block',
                overflowX: 'auto',
            }}
        >
            {!isScheduledMessagesLoading && scheduledMessages && scheduledMessages.length === 0 && (
                <Typography variant="body1" color="text.secondary" align="center" gutterBottom>
                    No Priority messages scheduled for this day.
                </Typography>
            )}
            {!isScheduledMessagesLoading && scheduledMessages && scheduledMessages.length > 0 && (
                <>
                    <Table
                        size="small"
                        sx={{
                            // No padding on the left and right of the table but only on the first and last cell
                            '& td': {
                                '&:first-of-type': {
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                },
                                '&:last-child': {
                                    paddingRight: 1,
                                },
                                paddingLeft: 0.5,
                                paddingRight: 0.5,
                                paddingTop: 0,
                                paddingBottom: 0,
                            },
                            '& th': {
                                '&:first-of-type': {
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                },
                                '&:last-child': {
                                    paddingRight: 1,
                                },
                                // Make header cells bold
                                fontWeight: 'bold',
                                paddingLeft: 0.5,
                                paddingRight: 0.5,
                            },
                        }}
                    >
                        <TableBody>
                            {scheduledMessages.map((scheduledMessage, index) => (
                                <React.Fragment key={scheduledMessage._id}>
                                    {index === 0 && (
                                        <DateTitle key={`date-${scheduledMessage._id}`} date={scheduledMessage.scheduleDateTime} />
                                    )}
                                    {index > 0 &&
                                        moment(scheduledMessages[index - 1].scheduleDateTime).isSame(
                                            scheduledMessage.scheduleDateTime,
                                            'day',
                                        ) === false && (
                                            <DateTitle key={`date-${scheduledMessage._id}`} date={scheduledMessage.scheduleDateTime} />
                                        )}
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <MessageUsersPriorityMessageCalendarItemCard
                                                scheduledMessage={scheduledMessage}
                                                setSelectedDate={setSelectedDate}
                                                isFutureScheduledMessage={false}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </Box>
    );
};

export default MessageUsersPriorityMessageCalendarDayView;
