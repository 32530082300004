import { Grid } from '@mui/material';
import { Theme } from '@mui/system';
import { comparrisonConditions } from '../../../../utils/common';

type Props = {
    exclude: any;
    theme: Theme;
};

const MessageUsersCurrentSessionExclude = (props: Props) => {
    const { exclude, theme } = props;

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <strong>&middot; Skip Creators</strong>
            </Grid>
            <Grid item xs={6}>
                {exclude.excludeCreators ? 'Yes' : 'No'}
            </Grid>
            <Grid item xs={6}>
                <strong>&middot; Limit</strong>
            </Grid>
            <Grid item xs={6}>
                {exclude.excludeLimitMessageCount && typeof exclude.excludeLimitMessageCount === 'number' ? (
                    `${exclude.excludeLimitMessageCount.toLocaleString()} messages`
                ) : (
                    <div style={{ color: theme.palette.grey[500] }}>None</div>
                )}
            </Grid>
            <Grid item xs={6}>
                <strong>&middot; Users in Lists</strong>
            </Grid>
            <Grid item xs={6}>
                {exclude.excludeLists ? (
                    <Grid container spacing={1}>
                        {exclude.excludeLists.map((excludeList: { id: number; name: string }) => (
                            <Grid item xs={12} key={excludeList.id}>
                                <span style={{ display: 'block', overflow: 'auto' }}>{excludeList.name}</span>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <div style={{ color: theme.palette.grey[500] }}>None</div>
                )}
            </Grid>
            <Grid item xs={6}>
                <strong>&middot; Users Spent</strong>
            </Grid>
            <Grid item xs={6}>
                {exclude.excludeSpentCondition && exclude.excludeSpentAmount ? (
                    `${comparrisonConditions[exclude.excludeSpentCondition]} $${exclude.excludeSpentAmount}`
                ) : (
                    <div style={{ color: theme.palette.grey[500] }}>None</div>
                )}
            </Grid>
            <Grid item xs={6}>
                <strong>&middot; In</strong>
            </Grid>
            <Grid item xs={6}>
                {exclude.excludeSpentPeriodAmount && exclude.excludeSpentPeriod ? (
                    `${exclude.excludeSpentPeriodAmount} ${exclude.excludeSpentPeriod}`
                ) : (
                    <div style={{ color: theme.palette.grey[500] }}>None</div>
                )}
            </Grid>
            <Grid item xs={6}>
                <strong>&middot; Exclude Current Fans</strong>
            </Grid>
            <Grid item xs={6}>
                {exclude.excludeFans ? 'Yes' : 'No'}
            </Grid>
            <Grid item xs={6}>
                <strong>&middot; Messaged in the Last</strong>
            </Grid>
            <Grid item xs={6}>
                {exclude.excludeMessagedInPeriod && exclude.excludeMessagedInPeriodAmount ? (
                    `${exclude.excludeMessagedInPeriodAmount} ${exclude.excludeMessagedInPeriod}`
                ) : (
                    <div style={{ color: theme.palette.grey[500] }}>None</div>
                )}
            </Grid>
        </Grid>
    );
};

export default MessageUsersCurrentSessionExclude;
