import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { SextforceOnlyFansTelegramNotificationOptions } from '../../../../../hooks/useSextforceOnlyFansTelegramNotifications';

const SextforceOnlyFansTelegramNotificationOptionsForm = ({
    options,
    setOptions,
}: {
    options: SextforceOnlyFansTelegramNotificationOptions;
    setOptions: (options: SextforceOnlyFansTelegramNotificationOptions) => void;
}) => {
    return (
        <>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} ml={1}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="New message received"
                            control={
                                <Checkbox
                                    checked={options.notifyIncomingMessage}
                                    onChange={event => {
                                        setOptions({
                                            ...options,
                                            notifyIncomingMessage: event.target.checked,
                                        });
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} ml={2}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    label="Show how much they've spent"
                                    control={
                                        <Checkbox
                                            checked={options.includeSpentTotal}
                                            onChange={event => {
                                                setOptions({
                                                    ...options,
                                                    includeSpentTotal: event.target.checked,
                                                });
                                            }}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} ml={2}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    label="Include messages from creators"
                                    disabled={!options.notifyIncomingMessage || !options.includeSpentTotal}
                                    control={
                                        <Checkbox
                                            checked={options.includeIncomingMessageFromCreators}
                                            onChange={event => {
                                                setOptions({
                                                    ...options,
                                                    includeIncomingMessageFromCreators: event.target.checked,
                                                });
                                            }}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} ml={2}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    label="Include messages from Mass DMs"
                                    disabled={!options.notifyIncomingMessage || !options.includeSpentTotal}
                                    control={
                                        <Checkbox
                                            checked={options.includeIncomingMessageFromMassDMs}
                                            onChange={event => {
                                                setOptions({
                                                    ...options,
                                                    includeIncomingMessageFromMassDMs: event.target.checked,
                                                });
                                            }}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} ml={2}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    label="Messages from freeloaders"
                                    disabled={!options.notifyIncomingMessage || !options.includeSpentTotal}
                                    control={
                                        <Checkbox
                                            checked={options.notifyIncomingMessageFreeloaders}
                                            onChange={event => {
                                                setOptions({
                                                    ...options,
                                                    notifyIncomingMessageFreeloaders: event.target.checked,
                                                });
                                            }}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    label="Messages from spenders"
                                    disabled={!options.notifyIncomingMessage || !options.includeSpentTotal}
                                    control={
                                        <Checkbox
                                            checked={options.notifyIncomingMessageSpenders}
                                            onChange={event => {
                                                setOptions({
                                                    ...options,
                                                    notifyIncomingMessageSpenders: event.target.checked,
                                                });
                                            }}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Unread messages are higher than..."
                            control={
                                <Checkbox
                                    checked={options.notifyWhenUnreadCountHigherThan}
                                    onChange={event => {
                                        setOptions({
                                            ...options,
                                            notifyWhenUnreadCountHigherThan: event.target.checked,
                                        });
                                    }}
                                />
                            }
                        />
                    </Grid>
                    {options.notifyWhenUnreadCountHigherThan && (
                        <Grid item xs={12} sx={{ ml: 3 }}>
                            <TextField
                                label="Total unread messages"
                                value={options.notifyWhenUnreadCountHigherThanAmount}
                                onChange={event => {
                                    // Only allow numbers and empty string
                                    if (!/^\d*$/.test(event.target.value)) {
                                        return;
                                    }

                                    // Don't allow negative values
                                    if (parseInt(event.target.value, 10) < 1) {
                                        return;
                                    }

                                    setOptions({
                                        ...options,
                                        notifyWhenUnreadCountHigherThanAmount: event.target.value ? parseInt(event.target.value, 10) : 0,
                                    });
                                }}
                                fullWidth
                                error={options.notifyWhenUnreadCountHigherThanAmount < 1}
                                helperText={options.notifyWhenUnreadCountHigherThanAmount < 1 ? 'Value must be higher than 0' : ''}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="New tip"
                            control={
                                <Checkbox
                                    checked={options.notifyIncomingTip}
                                    onChange={event => {
                                        setOptions({
                                            ...options,
                                            notifyIncomingTip: event.target.checked,
                                        });
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="New message purchase"
                            control={
                                <Checkbox
                                    checked={options.notifyIncomingMessagePurchase}
                                    onChange={event => {
                                        setOptions({
                                            ...options,
                                            notifyIncomingMessagePurchase: event.target.checked,
                                        });
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="New post purchase"
                            control={
                                <Checkbox
                                    checked={options.notifyIncomingPostPurchase}
                                    onChange={event => {
                                        setOptions({
                                            ...options,
                                            notifyIncomingPostPurchase: event.target.checked,
                                        });
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="New stream tip"
                            control={
                                <Checkbox
                                    checked={options.notifyIncomingStreamTip}
                                    onChange={event => {
                                        setOptions({
                                            ...options,
                                            notifyIncomingStreamTip: event.target.checked,
                                        });
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="New subscriber/follower"
                            control={
                                <Checkbox
                                    checked={options.notifyIncomingSubscription}
                                    onChange={event => {
                                        setOptions({
                                            ...options,
                                            notifyIncomingSubscription: event.target.checked,
                                        });
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Subscriber rebill"
                            control={
                                <Checkbox
                                    checked={options.notifyIncomingRebill}
                                    onChange={event => {
                                        setOptions({
                                            ...options,
                                            notifyIncomingRebill: event.target.checked,
                                        });
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Returning subscriber/follower"
                            control={
                                <Checkbox
                                    checked={options.notifyIncomingResubscription}
                                    onChange={event => {
                                        setOptions({
                                            ...options,
                                            notifyIncomingResubscription: event.target.checked,
                                        });
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Mass DM started on OnlyFans"
                            control={
                                <Checkbox
                                    checked={options.notifyMassDMStarted}
                                    onChange={event => {
                                        setOptions({
                                            ...options,
                                            notifyMassDMStarted: event.target.checked,
                                        });
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Mass DM finished on OnlyFans"
                            control={
                                <Checkbox
                                    checked={options.notifyMassDMFinished}
                                    onChange={event => {
                                        setOptions({
                                            ...options,
                                            notifyMassDMFinished: event.target.checked,
                                        });
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="A user blocked me"
                            control={
                                <Checkbox
                                    checked={options.notifyBlockedMe}
                                    onChange={event => {
                                        setOptions({
                                            ...options,
                                            notifyBlockedMe: event.target.checked,
                                        });
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="I blocked a user"
                            control={
                                <Checkbox
                                    checked={options.notifyBlockedUser}
                                    onChange={event => {
                                        setOptions({
                                            ...options,
                                            notifyBlockedUser: event.target.checked,
                                        });
                                    }}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default SextforceOnlyFansTelegramNotificationOptionsForm;
