export const arrayOnlyFansSubscriberHostessAutoReplyFreeloadersAction = ['none', 'mute', 'restrict', 'block'];

export type OnlyFansSubscriberHostessAutoReplyFreeloadersAction = typeof arrayOnlyFansSubscriberHostessAutoReplyFreeloadersAction[number];

export interface OnlyFansSubscriberHostessAutoReplyFreeloaders {
    active: boolean;
    delayBeforeSendingMessage: boolean;
    spentThreshold: number;
    replies: string[];
    action: OnlyFansSubscriberHostessAutoReplyFreeloadersAction;
    excludeCreators: boolean;
    autoUnrestrict: boolean;
    followingThresholdAmount?: number;
    followingThresholdPeriod?: string;
    repeatScriptWhenFinished?: boolean;
    skipUsersnames?: string[];
    historyTtlDays?: number | null;
}

export interface OnlyFansSubscriberHostessAutoReplyExpired {
    active: boolean;
    delayBeforeSendingMessage: boolean;
    replies: string[];
    action: OnlyFansSubscriberHostessAutoReplyFreeloadersAction;
    excludeCreators: boolean;
    autoUnrestrict: boolean;
    sendReplyOnPurchase: boolean;
    repeatScriptWhenFinished: boolean;
    skipUsersnames: string[];
    historyTtlDays?: number | null;
}
