import { Checkbox } from '@mui/material';
import { OnlyFansSubscriberHostessAutoReplyFreeloaders } from '../../../../types/hostessAutoReplyFreeloaders';

const FormHistoryTtlEnabledCheckbox = ({
    historyTtlEnabled,
    setHistoryTtlEnabled,
    setHistoryTtlDaysString,
    formData,
    setFormData,
}: {
    historyTtlEnabled: boolean;
    setHistoryTtlEnabled: (followingThresholdEnabled: boolean) => void;
    setHistoryTtlDaysString: (followingThresholdAmountString: string) => void;
    formData: OnlyFansSubscriberHostessAutoReplyFreeloaders;
    setFormData: (formData: OnlyFansSubscriberHostessAutoReplyFreeloaders) => void;
}) => {
    return (
        <Checkbox
            checked={historyTtlEnabled}
            size="medium"
            onChange={e => {
                setHistoryTtlEnabled(e.target.checked);

                if (!e.target.checked) {
                    setFormData({
                        ...formData,
                        historyTtlDays: null,
                    });
                    setHistoryTtlDaysString('0');
                } else {
                    setFormData({
                        ...formData,
                        historyTtlDays: 30,
                    });
                    setHistoryTtlDaysString('30');
                }
            }}
            sx={{
                '& .MuiSvgIcon-root': {
                    fontSize: '1.5rem',
                },
            }}
        />
    );
};

export default FormHistoryTtlEnabledCheckbox;
