import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid, Theme, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import {
    defaultSextforceOnlyFansTelegramNotificationOptions,
    SextforceOnlyFansTelegramNotification,
    SextforceOnlyFansTelegramNotificationOptions,
} from '../../../../../hooks/useSextforceOnlyFansTelegramNotifications';
import StyledCard from '../../../../common/StyledCard';
import StyledCardTitleBar from '../../../../common/StyledCardTitleBar';
import SextforceOnlyFansTelegramNotificationOptionsForm from './SextforceOnlyFansTelegramNotificationOptionsForm';

const SextforceOnlyFansTelegramNotificationAddNewForm = ({
    initialOptions,
    isLoading,
    handleSave,
    refetchSextforceOnlyFansTelegramNotifications,
    setOpen,
}: {
    initialOptions?: SextforceOnlyFansTelegramNotificationOptions;
    isLoading: boolean;
    handleSave: (options: SextforceOnlyFansTelegramNotificationOptions) => Promise<SextforceOnlyFansTelegramNotification>;
    refetchSextforceOnlyFansTelegramNotifications: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<SextforceOnlyFansTelegramNotification[], unknown>>;
    setOpen: (show: boolean) => void;
}) => {
    const theme: Theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const [options, setOptions] = useState<SextforceOnlyFansTelegramNotificationOptions>(
        defaultSextforceOnlyFansTelegramNotificationOptions,
    );

    useEffect(() => {
        if (!initialOptions) {
            setOptions(defaultSextforceOnlyFansTelegramNotificationOptions);
        } else {
            setOptions(initialOptions);
        }
    }, [initialOptions]);

    return (
        <>
            <StyledCard noCard noBottomMargin>
                <StyledCardTitleBar title="Add New Telegram Account" theme={theme} />
            </StyledCard>
            <StyledCard>
                <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Notification Options</Typography>
                    </Grid>

                    <SextforceOnlyFansTelegramNotificationOptionsForm options={options} setOptions={setOptions} />

                    <Grid item xs={8}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            loading={isLoading}
                            disabled={options.notifyWhenUnreadCountHigherThan && options.notifyWhenUnreadCountHigherThanAmount < 1}
                            onClick={async () => {
                                const newSextforceOnlyFansTelegramNotification = await handleSave(options);

                                if (newSextforceOnlyFansTelegramNotification) {
                                    enqueueSnackbar('New Telegram account added', {
                                        variant: 'success',
                                    });

                                    await refetchSextforceOnlyFansTelegramNotifications();

                                    setOpen(false);
                                    setOptions(defaultSextforceOnlyFansTelegramNotificationOptions);
                                } else {
                                    enqueueSnackbar('Failed to add new Telegram account', { variant: 'error' });
                                }
                            }}
                        >
                            Add Telegram Account
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="outlined"
                            color="error"
                            fullWidth
                            size="large"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </StyledCard>
        </>
    );
};

export default SextforceOnlyFansTelegramNotificationAddNewForm;
