import AddLinkIcon from '@mui/icons-material/AddLink';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LabelIcon from '@mui/icons-material/Label';
import LinkIcon from '@mui/icons-material/Link';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PushPinIcon from '@mui/icons-material/PushPin';
import RepeatIcon from '@mui/icons-material/Repeat';
import ShieldIcon from '@mui/icons-material/Shield';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Collapse, IconButton, Stack, TableCell, TableRow, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { SextforceAutoRepost, SextforceAutoRepostWithLatestRepostLog } from '../../../../hooks/useSextforceAutoRepost';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError } from '../../../../utils/common';
import Bullet from '../../../common/Bullet';
import NA from '../../../common/NA';
import SextforceAutoRepostCalendarLocalMenu from './SextforceAutoRepostCalendarLocalMenu';
import SextforceAutoRepostChangeNextAutoRepostDateDialog from './SextforceAutoRepostChangeNextAutoRepostDateDialog';
import SextforceAutoRepostEditRepostDialog from './SextforceAutoRepostEditRepostDialog';
import SextforceAutoRepostShareDialog from './SextforceAutoRepostShareDialog';
import SextforceAutoRepostTableRepostsHistoryRowLogs from './SextforceAutoRepostTableRepostsHistoryRowLogs';

export const AutoRepostNameAndBadges = (props: {
    autoRepost: SextforceAutoRepostWithLatestRepostLog;
    isFutureScheduledMessage: boolean;
    setSelectedDate: (day: moment.Moment) => void;
}) => {
    const { autoRepost, isFutureScheduledMessage, setSelectedDate } = props;
    const theme: Theme = useTheme();

    return (
        <Stack direction="column" spacing={0.5}>
            <Stack direction="row" spacing={0.5} alignItems="flex-start" justifyContent="flex-start">
                <LinkIcon
                    fontSize="small"
                    color={
                        autoRepost.actionPreviousPost === 'delete' && autoRepost.type === 'post' && autoRepost.repostCount > 0
                            ? 'disabled'
                            : 'action'
                    }
                />
                {autoRepost.actionPreviousPost === 'delete' && autoRepost.type === 'post' && autoRepost.repostCount > 0 ? (
                    <Typography variant="inherit" color="textPrimary">
                        {autoRepost.name}
                    </Typography>
                ) : (
                    <Link
                        to={autoRepost.originalPostUrl}
                        target="_blank"
                        rel="noreferrer"
                        onClick={e => {
                            e.stopPropagation();
                        }}
                    >
                        <Typography variant="inherit" color="textPrimary">
                            {autoRepost.name}
                        </Typography>
                    </Link>
                )}
            </Stack>
            {autoRepost.description && (
                <Typography variant="inherit" color="textSecondary">
                    {autoRepost.description}
                </Typography>
            )}
            <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography variant="inherit" color="textSecondary" noWrap>
                    {autoRepost.type === 'link' && (
                        <Tooltip title="Post Link to Original">
                            <AddLinkIcon color="action" sx={{ fontSize: '12pt' }} />
                        </Tooltip>
                    )}
                    {autoRepost.type === 'post' && (
                        <Tooltip title="Post a Brand New Post">
                            <PostAddIcon color="action" sx={{ fontSize: '12pt' }} />
                        </Tooltip>
                    )}
                </Typography>

                <Bullet />

                <Typography variant="inherit" color="textSecondary" noWrap>
                    <Tooltip title={autoRepost.pinPost ? 'Pin New Post' : `Don't Pin New Post`}>
                        <PushPinIcon
                            color={autoRepost.pinPost ? 'action' : 'disabled'}
                            sx={{
                                fontSize: '12pt',
                                transform: 'rotate(270deg)',
                            }}
                        />
                    </Tooltip>
                </Typography>

                <Bullet />

                <Typography variant="inherit" color="textSecondary" noWrap>
                    {autoRepost.actionPreviousPost === 'none' && (
                        <Tooltip title="Leave Previous Post">
                            <ArchiveIcon color="disabled" sx={{ fontSize: '12pt' }} />
                        </Tooltip>
                    )}
                    {autoRepost.actionPreviousPost === 'delete' && (
                        <Tooltip title="Delete Previous Post">
                            <DeleteIcon color="action" sx={{ fontSize: '12pt' }} />
                        </Tooltip>
                    )}
                    {autoRepost.actionPreviousPost === 'archive' && (
                        <Tooltip title="Archive Previous Post">
                            <ArchiveIcon
                                color="action"
                                sx={{
                                    fontSize: '12pt',
                                }}
                            />
                        </Tooltip>
                    )}
                    {autoRepost.actionPreviousPost === 'privateArchive' && (
                        <Tooltip title="Private Archive Previous Post">
                            <ShieldIcon color="action" sx={{ fontSize: '12pt' }} />
                        </Tooltip>
                    )}
                </Typography>

                <Bullet />

                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Repost Count">
                        <TextSnippetIcon color="success" sx={{ fontSize: '12pt', top: '-2px', position: 'relative' }} />
                    </Tooltip>
                    <Typography variant="inherit" color="textPrimary" noWrap>
                        {autoRepost.repostCount.toLocaleString()}
                    </Typography>
                </Stack>

                <Bullet />

                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Category Color">
                        <LabelIcon
                            htmlColor={autoRepost.color?.color || theme.palette.text.disabled}
                            sx={{ fontSize: '12pt', top: '-1px', position: 'relative' }}
                        />
                    </Tooltip>
                    <Typography variant="inherit" color={autoRepost.color ? 'textPrimary' : 'textSecondary'} noWrap>
                        {autoRepost.color?.name || 'No Color'}
                    </Typography>
                </Stack>
            </Stack>

            <Stack direction="row" spacing={0.5} alignItems="center">
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Repeat Every">
                        <RepeatIcon color="info" sx={{ fontSize: '12pt' }} />
                    </Tooltip>
                    <Typography variant="inherit" color="textPrimary" noWrap>
                        {autoRepost.repostEveryAmount.toLocaleString()} {momentPeriods[autoRepost.repostEveryUnit]}
                    </Typography>
                </Stack>

                <Bullet />

                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Repost Until">
                        <SportsScoreIcon color={autoRepost.endDate ? 'info' : 'disabled'} sx={{ fontSize: '12pt' }} />
                    </Tooltip>

                    <Typography variant="inherit" color={autoRepost.endDate ? 'textPrimary' : 'textSecondary'} noWrap>
                        {autoRepost.endDate ? moment(autoRepost.endDate).format('L') : 'Indefinitely'}
                    </Typography>
                </Stack>
            </Stack>
            <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography variant="inherit" color="textSecondary">
                    Last Repost {autoRepost.latestRepostDate ? moment(autoRepost.latestRepostDate).format('L hh:mma') : <NA />}
                </Typography>
            </Stack>
            {!isFutureScheduledMessage && (
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Typography variant="inherit" color="textSecondary">
                        Following Repost{' '}
                        {!autoRepost.endDate ||
                        (autoRepost.endDate &&
                            moment(autoRepost.nextRepostDate)
                                .add(autoRepost.repostEveryAmount, autoRepost.repostEveryUnit)
                                .isBefore(autoRepost.endDate)) ? (
                            <Typography
                                variant="inherit"
                                color="primary"
                                onClick={() => {
                                    setSelectedDate(
                                        moment(autoRepost.nextRepostDate).add(autoRepost.repostEveryAmount, autoRepost.repostEveryUnit),
                                    );
                                    localStorage.setItem(
                                        'sextforceAutoRepostCalendarViewSelectedDate',
                                        moment(autoRepost.nextRepostDate)
                                            .add(autoRepost.repostEveryAmount, autoRepost.repostEveryUnit)
                                            .format(),
                                    );
                                }}
                                sx={{
                                    cursor: 'pointer',
                                    display: 'inline',
                                }}
                            >
                                {moment(autoRepost.nextRepostDate)
                                    .add(autoRepost.repostEveryAmount, autoRepost.repostEveryUnit)
                                    .format('L hh:mma')}
                            </Typography>
                        ) : (
                            <NA />
                        )}
                    </Typography>
                </Stack>
            )}
        </Stack>
    );
};

const momentPeriods: { [key: string]: string } = {
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
};

type Props = {
    subscriber: any;
    autoRepost: SextforceAutoRepostWithLatestRepostLog;
    theme: Theme;
    refetchAutoReposts: () => void;
    setSelectedDate: (day: moment.Moment) => void;
};

const SextforceAutoRepostTableRepostsHistoryRow = (props: Props) => {
    const { subscriber, autoRepost, theme, refetchAutoReposts, setSelectedDate } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();
    const { enqueueSnackbar } = useSnackbar();
    const axios = useAxios();
    const [open, setOpen] = useState(false);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [changeNextRepostDateDialogOpen, setChangeNextRepostDateDialogOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isReactivatingAutoRepost, setIsReactivatingAutoRepost] = useState(false);

    const deleteAutoRepost = async (autoRepostId: string) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsDeleting(true);

        const data = await axios
            .delete(`${settingsContext.routes.autoRepost.base}${params.userId}/${autoRepostId}`)
            .then(async response => {
                return response.data as SextforceAutoRepost;
            })
            .catch(error => {
                setIsDeleting(false);
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const handleDeleteAutoRepost = (autoRepostId: string) => {
        deleteAutoRepost(autoRepostId).then(data => {
            if (!data) {
                return;
            }

            if (autoRepost.active) {
                enqueueSnackbar({
                    variant: 'success',
                    message:
                        'Auto Repost has been scheduled for deactivation. In a minute or two it will be deactivated and will send the final notification and summary on Telegram.',
                });
            } else {
                enqueueSnackbar({
                    variant: 'success',
                    message: 'Auto Repost has been deleted',
                });
            }

            setIsDeleting(false);

            refetchAutoReposts();
        });
    };

    const changeAutoRepostActiveState = async (autoRepostId: string, active: boolean) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsReactivatingAutoRepost(true);

        const data = await axios
            .put(`${settingsContext.routes.autoRepost.base}${params.userId}/${autoRepostId}/active`, {
                active,
            })
            .then(async response => {
                return response.data as SextforceAutoRepost;
            })
            .catch(error => {
                setIsReactivatingAutoRepost(false);
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const handleActivateAutoRepost = async (autoRepostId: string) => {
        return changeAutoRepostActiveState(autoRepostId, true).then(() => {
            enqueueSnackbar({
                variant: 'success',
                message: 'Auto Repost has been reactivated',
            });

            refetchAutoReposts();
        });
    };

    const handleDeactivateAutoRepost = async (autoRepostId: string) => {
        return changeAutoRepostActiveState(autoRepostId, false).then(() => {
            enqueueSnackbar({
                variant: 'success',
                message: 'Auto Repost has been deactivated',
            });

            refetchAutoReposts();
        });
    };

    return (
        <>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    bgcolor: open ? 'grey.50' : 'transparent',
                }}
            >
                <TableCell
                    sx={{
                        borderRight: '6px solid',
                        borderRightColor: autoRepost.color ? autoRepost.color.color : 'divider',
                        borderBottom: 'none',
                        verticalAlign: 'top',
                    }}
                >
                    <Stack direction="column" spacing={0} alignItems="center" justifyContent="flex-start">
                        <Typography variant="inherit" noWrap>
                            {moment(autoRepost.nextRepostDate).format('hh:mma')}
                        </Typography>
                        <Tooltip title={open ? 'Hide Reposts History' : 'Show Reposts History'}>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </TableCell>
                <TableCell>
                    <AutoRepostNameAndBadges autoRepost={autoRepost} isFutureScheduledMessage={false} setSelectedDate={setSelectedDate} />
                </TableCell>
                <TableCell
                    align="center"
                    sx={{
                        borderBottom: 'none',
                    }}
                >
                    <SextforceAutoRepostCalendarLocalMenu
                        autoRepost={autoRepost}
                        subscriber={subscriber}
                        setChangeNextRepostDateDialogOpen={setChangeNextRepostDateDialogOpen}
                        setEditDialogOpen={setEditDialogOpen}
                        setShareDialogOpen={setShareDialogOpen}
                        handleActivateAutoRepost={handleActivateAutoRepost}
                        handleDeactivateAutoRepost={handleDeactivateAutoRepost}
                        handleDeleteAutoRepost={handleDeleteAutoRepost}
                        isDeleting={isDeleting}
                        isReactivatingAutoRepost={isReactivatingAutoRepost}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse
                        in={open}
                        timeout={{
                            enter: theme.transitions.duration.enteringScreen,
                            exit: theme.transitions.duration.leavingScreen,
                        }}
                        unmountOnExit
                    >
                        <SextforceAutoRepostTableRepostsHistoryRowLogs subscriber={subscriber} autoRepost={autoRepost} />
                    </Collapse>
                </TableCell>
            </TableRow>
            <SextforceAutoRepostShareDialog
                subscriber={subscriber}
                autoRepost={autoRepost}
                open={shareDialogOpen}
                onClose={() => setShareDialogOpen(false)}
                theme={theme}
            />
            <SextforceAutoRepostEditRepostDialog
                subscriber={subscriber}
                autoRepost={autoRepost}
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                refetchAutoReposts={refetchAutoReposts}
            />
            <SextforceAutoRepostChangeNextAutoRepostDateDialog
                subscriber={subscriber}
                autoRepost={autoRepost}
                open={changeNextRepostDateDialogOpen}
                onClose={() => setChangeNextRepostDateDialogOpen(false)}
                refetchAutoReposts={refetchAutoReposts}
            />
        </>
    );
};

export default SextforceAutoRepostTableRepostsHistoryRow;
