import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker, MobileDateTimePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import useMessageUsersScheduledSessions, { ScheduledTask } from '../../../../hooks/useMessageUsersScheduledSessions';
import SextforceColorSelector from '../../../common/SextforceColorSelector';
import StyledCardTitleBar from '../../../common/StyledCardTitleBar';
import SelectTimezone from '../../../forms/helpers/SelectTimezone';

const momentPeriods: { [key: string]: string } = {
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
};

type Props = {
    scheduledTask: ScheduledTask;
    onClose: () => void;
    open: boolean;
};

const MessageUsersScheduledTaskChangeSettingsDialog = (props: Props) => {
    const { scheduledTask, onClose, open } = props;

    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const { updateScheduledMessage, isUpdatingScheduledMessage } = useMessageUsersScheduledSessions();

    const [timezone, setTimezone] = useState<string>(moment.tz.guess());
    const [repeatMessage, setRepeatMessage] = useState<boolean>(!!scheduledTask.repeatEveryAmount && !!scheduledTask.repeatEveryUnit);

    const [scheduleDateTime, setScheduleDateTime] = useState<Date>(scheduledTask.scheduleDateTime);
    const [repeatEveryAmount, setRepeatEveryAmount] = useState<number | undefined>(scheduledTask.repeatEveryAmount);
    const [repeatEveryUnit, setRepeatEveryUnit] = useState<string | undefined>(scheduledTask.repeatEveryUnit);
    const [repeatEndDate, setRepeatEndDate] = useState<Date | undefined>(scheduledTask.endDate);
    const [colorId, setColorId] = useState<string | undefined>(scheduledTask.colorId);

    const [date, setDate] = useState<moment.Moment>(moment(scheduledTask.scheduleDateTime));
    const [time, setTime] = useState<moment.Moment>(moment(scheduledTask.scheduleDateTime));

    useEffect(() => {
        const handleDateTimeChanged = (newDate: moment.Moment, newTime: moment.Moment) => {
            const localDateTime = moment.tz(`${newDate.format('YYYY-MM-DD')} ${newTime.format('HH:mm')}`, timezone).local();

            setScheduleDateTime(localDateTime.toDate());
        };

        handleDateTimeChanged(date, time);
    }, [date, setScheduleDateTime, time, timezone]);

    // Reset values when dialog is closed
    useEffect(() => {
        if (!open) {
            setTimezone(moment.tz.guess());
            setRepeatMessage(!!scheduledTask.repeatEveryAmount && !!scheduledTask.repeatEveryUnit);
            setScheduleDateTime(scheduledTask.scheduleDateTime);
            setRepeatEveryAmount(scheduledTask.repeatEveryAmount);
            setRepeatEveryUnit(scheduledTask.repeatEveryUnit);
            setRepeatEndDate(scheduledTask.endDate);
            setColorId(scheduledTask.colorId);
            setDate(moment(scheduledTask.scheduleDateTime));
            setTime(moment(scheduledTask.scheduleDateTime));
        }
    }, [open, scheduledTask]);

    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    const handleSave = () => {
        updateScheduledMessage(scheduledTask._id || '', scheduleDateTime, repeatEveryAmount, repeatEveryUnit, repeatEndDate, colorId).then(
            result => {
                if (result) {
                    enqueueSnackbar('Scheduled message settings updated.', { variant: 'success' });

                    handleClose({}, 'escapeKeyDown');
                } else {
                    enqueueSnackbar('Failed to update scheduled message settings.', { variant: 'error' });
                }
            },
        );
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
                <StyledCardTitleBar title="Change Scheduled Message Settings" theme={theme} />
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="inherit" gutterBottom>
                                    When should the next message session go out?
                                </Typography>
                                <Typography variant="body2" color={'text.secondary'}>
                                    Please note that if a session is already in progress, the next session will be scheduled after the
                                    current session is completed.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <SelectTimezone timezone={timezone} setTimezone={setTimezone} fullWidth={true} />
                            </Grid>
                            <Grid item xs={6}>
                                <MobileDatePicker
                                    renderInput={props => <TextField fullWidth {...props} />}
                                    label="Date"
                                    value={moment(date)}
                                    onChange={newValue => {
                                        setDate(newValue as moment.Moment);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <MobileTimePicker
                                    label="Time"
                                    ampm={true}
                                    value={moment(time)}
                                    onChange={newValue => {
                                        setTime(newValue as moment.Moment);
                                    }}
                                    renderInput={params => <TextField fullWidth {...params} />}
                                    DialogProps={{
                                        sx: {
                                            '.MuiPickersToolbar-content .Mui-selected': {
                                                color: theme.palette.secondary.main,
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            {moment(scheduleDateTime).isBefore(moment()) && (
                                <Grid item xs={12}>
                                    <Typography variant="body2" color={'error'} gutterBottom>
                                        Scheduled date must be in the future.
                                    </Typography>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Typography variant="inherit" gutterBottom>
                                    Category Color
                                </Typography>
                                <Typography variant="body2" color={'text.secondary'}>
                                    Choose an identifying color for this message. This will be used to help identify the category or topic
                                    of the message.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <SextforceColorSelector colorId={colorId} setColorId={setColorId} />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={repeatMessage}
                                            onChange={(e, checked) => {
                                                setRepeatMessage(checked);
                                            }}
                                        />
                                    }
                                    label="Repeat this message..."
                                />
                            </Grid>
                            {repeatMessage && (
                                <Grid item xs={12} sx={{ marginLeft: 4 }}>
                                    <Grid container spacing={1} flexGrow={1} alignItems="center">
                                        <Grid item xs={6} sm="auto">
                                            <TextField
                                                label="Every"
                                                type="number"
                                                value={repeatEveryAmount}
                                                onChange={e => {
                                                    setRepeatEveryAmount(
                                                        Number.isNaN(Number.parseInt(e.target.value, 10))
                                                            ? 0
                                                            : Number.parseInt(e.target.value, 10),
                                                    );
                                                }}
                                                InputProps={{ inputProps: { min: 1 } }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm="auto">
                                            <FormControl fullWidth>
                                                <InputLabel>Period</InputLabel>
                                                <Select
                                                    label="Period"
                                                    value={repeatEveryUnit}
                                                    onChange={e => {
                                                        setRepeatEveryUnit(e.target.value as string);
                                                    }}
                                                    fullWidth
                                                    size="small"
                                                >
                                                    {Object.keys(momentPeriods).map(item => (
                                                        <MenuItem value={item} key={item}>
                                                            {momentPeriods[item]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            Stop repeating on:
                                        </Grid>
                                        <Grid item xs={12} sm="auto">
                                            <MobileDateTimePicker
                                                renderInput={props => (
                                                    <TextField
                                                        {...props}
                                                        error={repeatEndDate && repeatEndDate < new Date() ? true : false}
                                                        helperText={
                                                            repeatEndDate && repeatEndDate < new Date()
                                                                ? 'End date must be in the future'
                                                                : ''
                                                        }
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: repeatEndDate ? (
                                                                <ClearIcon
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();

                                                                        setRepeatEndDate(undefined);
                                                                    }}
                                                                />
                                                            ) : (
                                                                <CalendarMonthIcon />
                                                            ),
                                                        }}
                                                    />
                                                )}
                                                label="Repeat Until"
                                                ampm={true}
                                                value={repeatEndDate ? moment(repeatEndDate) : null}
                                                minDate={moment()}
                                                onChange={newValue => {
                                                    setRepeatEndDate(newValue?.toDate() || undefined);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions
                    sx={{
                        borderTop: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    <Button
                        color="error"
                        size="medium"
                        disabled={isUpdatingScheduledMessage}
                        onClick={() => {
                            handleClose({}, 'escapeKeyDown');
                        }}
                    >
                        Close
                    </Button>
                    <LoadingButton
                        color="secondary"
                        variant="contained"
                        size="medium"
                        loading={isUpdatingScheduledMessage}
                        disabled={!scheduleDateTime || !moment(scheduleDateTime).isValid() || !moment(scheduleDateTime).isAfter(moment())}
                        onClick={() => {
                            handleSave();
                        }}
                    >
                        Save Settings
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MessageUsersScheduledTaskChangeSettingsDialog;
