import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Joi from 'joi';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import {
    emptyOnlyFansMassDMMessageRequest,
    OnlyFansMassDMMessageRequest,
    OnlyFansMassDMMessageRequestSchema,
} from '../../../../hooks/useMessageUsersMassDm';
import useSextforceAutoRepostColors from '../../../../hooks/useSextforceAutoRepostColors';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError } from '../../../../utils/common';
import StyledCard from '../../../common/StyledCard';
import StyledCardTitleBar from '../../../common/StyledCardTitleBar';

const TitleGridItem = (props: { title: string | React.ReactNode; discription?: string | React.ReactNode }) => {
    const { title, discription } = props;

    return (
        <Grid item xs={12}>
            {title}
            <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                {discription}
            </Typography>
        </Grid>
    );
};

const momentPeriods: { [key: string]: string } = {
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
};

type Props = {
    subscriber: any;
    refetchMassDms: () => void;
    handleCancel: () => void;
    setShowAddNewForm: (show: boolean) => void;
};

const MessageUsersMassDmAutoMassDmMessageForm = (props: Props) => {
    const { subscriber, refetchMassDms, handleCancel, setShowAddNewForm } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const axios = useAxios();
    const { enqueueSnackbar } = useSnackbar();
    const dialog = useDialog();
    const theme = useTheme();
    const [formData, setFormData] = useState<OnlyFansMassDMMessageRequest>(emptyOnlyFansMassDMMessageRequest);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { data: sextforceAutoRepostColors, isLoading: isSextforceAutoRepostColorsLoading } = useSextforceAutoRepostColors();

    // Validate the form data
    const errors: Joi.ValidationErrorItem[] | null = OnlyFansMassDMMessageRequestSchema.validate(formData).error?.details || null;

    const massDmAdd = async (newMassDm: OnlyFansMassDMMessageRequest) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsSubmitting(true);

        const data = await axios
            .post(`${settingsContext.routes.massDm.base}${params.userId}`, newMassDm)
            .then(async response => {
                setIsSubmitting(false);
                return response.data as OnlyFansMassDMMessageRequest;
            })
            .catch(error => {
                setIsSubmitting(false);
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const handleSubmit = () => {
        massDmAdd(formData).then(data => {
            if (!data) {
                return;
            }

            enqueueSnackbar('Mass DM created successfully', { variant: 'success' });

            refetchMassDms();

            // Reset the form
            setFormData(emptyOnlyFansMassDMMessageRequest);

            setShowAddNewForm(false);
        });
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <StyledCard noCard noBottomMargin>
                    <StyledCardTitleBar title="Add New Mass DM" theme={theme} />
                </StyledCard>
                <StyledCard>
                    <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
                        <TitleGridItem title="Name" discription="The name of the Mass DM" />

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={8}>
                            <LoadingButton
                                variant="contained"
                                color="secondary"
                                fullWidth
                                size="large"
                                loading={isSubmitting}
                                disabled={!!errors}
                                onClick={handleSubmit}
                            >
                                Add New Repost
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="outlined" color="error" fullWidth size="large" onClick={handleCancel}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </StyledCard>
            </LocalizationProvider>
        </>
    );
};

export default MessageUsersMassDmAutoMassDmMessageForm;
