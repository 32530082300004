import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';

export interface SextforceOnlyFansTelegramNotificationOptions {
    notifyIncomingMessage: boolean;
    includeSpentTotal: boolean;
    includeIncomingMessageFromCreators: boolean;
    includeIncomingMessageFromMassDMs: boolean;
    notifyIncomingMessageFreeloaders: boolean;
    notifyIncomingMessageSpenders: boolean;
    notifyWhenUnreadCountHigherThan: boolean;
    notifyWhenUnreadCountHigherThanAmount: number;
    notifyIncomingTip: boolean;
    notifyIncomingMessagePurchase: boolean;
    notifyIncomingPostPurchase: boolean;
    notifyIncomingStreamTip: boolean;
    notifyIncomingSubscription: boolean;
    notifyIncomingRebill: boolean;
    notifyIncomingResubscription: boolean;
    notifyMassDMStarted: boolean;
    notifyMassDMFinished: boolean;
    notifyBlockedMe: boolean;
    notifyBlockedUser: boolean;
}

export interface SextforceOnlyFansTelegramNotification {
    _id: string;
    inviteCode: string;
    subscriberId: string;
    telegramChatId?: number;
    telegramUsername?: string;
    options?: SextforceOnlyFansTelegramNotificationOptions;
    active: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    registerDate?: Date;
    deletedDate?: Date;
}

export const defaultSextforceOnlyFansTelegramNotificationOptions: SextforceOnlyFansTelegramNotificationOptions = {
    notifyIncomingMessage: true,
    includeSpentTotal: true,
    includeIncomingMessageFromCreators: false,
    includeIncomingMessageFromMassDMs: false,
    notifyIncomingMessageFreeloaders: true,
    notifyIncomingMessageSpenders: true,
    notifyWhenUnreadCountHigherThan: false,
    notifyWhenUnreadCountHigherThanAmount: 20,
    notifyIncomingTip: false,
    notifyIncomingMessagePurchase: false,
    notifyIncomingPostPurchase: false,
    notifyIncomingStreamTip: false,
    notifyIncomingSubscription: false,
    notifyIncomingRebill: false,
    notifyIncomingResubscription: false,
    notifyMassDMStarted: false,
    notifyMassDMFinished: false,
    notifyBlockedMe: false,
    notifyBlockedUser: false,
};

const useSextforceOnlyFansTelegramNotifications = (subscriberId: string | undefined) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const axios = useAxios();

    const [keepRefreshing, setKeepRefreshing] = useState<boolean>(false);
    const [isCreatingSextforceOnlyFansTelegramNotification, setIsCreatingSextforceOnlyFansTelegramNotification] = useState<boolean>(false);
    const [isUpdatingSextforceOnlyFansTelegramNotification, setIsUpdatingSextforceOnlyFansTelegramNotification] = useState<boolean>(false);
    const [isSetSextforceOnlyFansTelegramNotificationActive, setIsSetSextforceOnlyFansTelegramNotificationActive] =
        useState<boolean>(false);
    const [isDeletingSextforceOnlyFansTelegramNotification, setIsDeletingSextforceOnlyFansTelegramNotification] = useState<boolean>(false);

    // Fetch Fans Counters
    const fetchData = async (): Promise<SextforceOnlyFansTelegramNotification[]> => {
        const query: string = `${settingsContext.routes.sextforce.base}${subscriberId}/telegram/onlyfans`;

        const data = await axios
            .get(query)
            .then(async response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return [];
            });

        const unclaimedInvitationLinks = data.filter((notification: SextforceOnlyFansTelegramNotification) => !notification.telegramChatId);

        if (unclaimedInvitationLinks.length > 0) {
            setKeepRefreshing(true);
        } else {
            setKeepRefreshing(false);
        }

        return data;
    };

    const createSextforceOnlyFansTelegramNotification = async (
        options?: SextforceOnlyFansTelegramNotificationOptions,
    ): Promise<SextforceOnlyFansTelegramNotification> => {
        const query: string = `${settingsContext.routes.sextforce.base}${subscriberId}/telegram/onlyfans`;

        setIsCreatingSextforceOnlyFansTelegramNotification(true);

        const data = await axios
            .post(query, options)
            .then(async response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return null;
            });

        setIsCreatingSextforceOnlyFansTelegramNotification(false);

        return data;
    };

    const updateSextforceOnlyFansTelegramNotification = async (
        notificationId: string,
        options: SextforceOnlyFansTelegramNotificationOptions,
    ): Promise<SextforceOnlyFansTelegramNotification> => {
        const query: string = `${settingsContext.routes.sextforce.base}${subscriberId}/telegram/onlyfans/${notificationId}`;

        setIsUpdatingSextforceOnlyFansTelegramNotification(true);

        const data = await axios
            .put(query, options)
            .then(async response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return null;
            });

        setIsUpdatingSextforceOnlyFansTelegramNotification(false);

        return data;
    };

    const setSextforceOnlyFansTelegramNotificationActive = async (
        notificationId: string,
        active: boolean,
    ): Promise<SextforceOnlyFansTelegramNotification> => {
        const query: string = `${settingsContext.routes.sextforce.base}${subscriberId}/telegram/onlyfans/${notificationId}/active`;

        setIsSetSextforceOnlyFansTelegramNotificationActive(true);

        const data = await axios
            .put(query, { active })
            .then(async response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return null;
            });

        setIsSetSextforceOnlyFansTelegramNotificationActive(false);

        return data;
    };

    const deleteSextforceOnlyFansTelegramNotification = async (notificationId: string): Promise<{ success: boolean }> => {
        const query: string = `${settingsContext.routes.sextforce.base}${subscriberId}/telegram/onlyfans/${notificationId}`;

        setIsDeletingSextforceOnlyFansTelegramNotification(true);

        const data = await axios
            .delete(query)
            .then(async response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return null;
            });

        setIsDeletingSextforceOnlyFansTelegramNotification(false);

        return data;
    };

    const query = useQuery(['sextforceOnlyFansTelegramNotifications', subscriberId], () => fetchData(), {
        refetchOnWindowFocus: false,
        // 5 minutes
        staleTime: 60 * 1000 * 5,
        refetchInterval: keepRefreshing ? 5 * 1000 : false,
        enabled: userContext.jwtToken && settingsContext.apiKey && subscriberId ? true : false,
    });

    return {
        ...query,
        createSextforceOnlyFansTelegramNotification,
        isCreatingSextforceOnlyFansTelegramNotification,
        updateSextforceOnlyFansTelegramNotification,
        isUpdatingSextforceOnlyFansTelegramNotification,
        setSextforceOnlyFansTelegramNotificationActive,
        isSetSextforceOnlyFansTelegramNotificationActive,
        deleteSextforceOnlyFansTelegramNotification,
        isDeletingSextforceOnlyFansTelegramNotification,
    };
};

export default useSextforceOnlyFansTelegramNotifications;
