import CircleIcon from '@mui/icons-material/Circle';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import useSextforceAutoRepostColors, { SextforceAutoRepostColorsBasicList } from '../../../../hooks/useSextforceAutoRepostColors';
import useSubscriber from '../../../../hooks/useSubscriber';

type Props = {
    open: boolean;
    onClose: () => void;
};

const SextforceAutoRepostColorsDialog = (props: Props) => {
    const { open, onClose } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [formData, setFormData] = useState<{ _id?: string; name: string; color: string; order: number }[]>([]);

    const { data: subscriber, isLoading: isSubscribersLoading } = useSubscriber();
    const { data: autoRepostColors, isLoading: autoRepostColorsLoading, updateSextforceAutoRepostColors } = useSextforceAutoRepostColors();

    useEffect(() => {
        if (autoRepostColors && subscriber && subscriber._id) {
            const formColors: { _id?: string; name: string; color: string; order: number }[] = [];

            for (let i = 0; i < SextforceAutoRepostColorsBasicList.length; i += 1) {
                const c = SextforceAutoRepostColorsBasicList[i];

                const autoRepostColor = autoRepostColors.find(ac => ac.color === c.hex);

                formColors.push({
                    _id: autoRepostColor ? autoRepostColor._id : undefined,
                    name: autoRepostColor?.name || c.name,
                    color: c.hex,
                    order: i,
                });
            }

            setFormData(formColors);
        }
    }, [autoRepostColors, subscriber]);

    const isSaving: boolean = false;

    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    const handleSave = () => {
        if (subscriber && subscriber._id) {
            updateSextforceAutoRepostColors(formData).then(() => {
                enqueueSnackbar('Auto Repost Colors updated successfully.', { variant: 'success' });

                onClose();
            });
        }
    };

    return (
        <Dialog open={open} onClose={onClose} disableEscapeKeyDown maxWidth="sm">
            <DialogTitle>Auto Repost Colors</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Give meaningful names to the colors you want to use for auto reposting. You can give each Auto Repost a color/name to
                    easily identify them based on topic or any other criteria.
                </DialogContentText>

                {isSubscribersLoading || autoRepostColorsLoading ? (
                    <Skeleton variant="rectangular" height={400} />
                ) : (
                    <Table
                        sx={{
                            tableLayout: 'fixed',
                            '& th': {
                                fontWeight: 'bold',
                            },
                            '& th, & td': {
                                padding: 0,
                                paddingTop: 1,
                                paddingBottom: 1,
                            },
                            '& td': {
                                padding: 0,
                            },
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell width={60} align="center">
                                    Color
                                </TableCell>
                                <TableCell>Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                overflowY: 'auto',
                                maxHeight: '400px',
                            }}
                        >
                            {formData.map((c, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            verticalAlign: 'middle',
                                            borderBottom: 'none',
                                        }}
                                    >
                                        <Stack direction="row" spacing={1} alignItems={'center'} justifyContent={'center'}>
                                            <CircleIcon
                                                sx={{
                                                    diaplay: 'inline-block',
                                                    color: c.color,
                                                    fontSize: 36,
                                                }}
                                            />
                                        </Stack>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            verticalAlign: 'middle',
                                            borderBottom: 'none',
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            value={c.name}
                                            onChange={e => {
                                                const newFormData = [...formData];

                                                newFormData.find(fc => fc.color === c.color)!.name = e.target.value;

                                                setFormData(newFormData);
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    size="medium"
                    // disabled={isSubscribersLoading}
                    onClick={() => {
                        handleClose({}, 'escapeKeyDown');
                    }}
                >
                    Close
                </Button>
                <LoadingButton
                    color="primary"
                    size="medium"
                    loading={isSaving}
                    // disabled={!groupName || groupName.trim().length === 0}
                    onClick={handleSave}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default SextforceAutoRepostColorsDialog;
