import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
    alpha,
    AppBar,
    Box,
    Card,
    CardContent,
    FormControlLabel,
    Grid,
    IconButton,
    Stack,
    Switch,
    Tab,
    Tabs,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import useMessageUsersScheduledSessions, { ScheduledTask } from '../../../../hooks/useMessageUsersScheduledSessions';
import useSextforceAutoRepostColors from '../../../../hooks/useSextforceAutoRepostColors';
import MessageUsersPriorityMessageCalendarDayView from './MessageUsersPriorityMessageCalendarDayView';
import MessageUsersPriorityMessageCalendarListView from './MessageUsersPriorityMessageCalendarListView';

export interface TaskOccurrence {
    task: ScheduledTask;
    dateTime: moment.Moment;
    isRepeat: boolean;
}

const MessageUsersPriorityMessageCalendar = () => {
    const theme: Theme = useTheme();

    const { data: scheduledMessages, isLoading: scheduledMessagesLoading } = useMessageUsersScheduledSessions();

    const [currentDate, setCurrentDate] = useState<moment.Moment>(
        (localStorage.getItem('messageUsersPriorityMessageCalendarViewCurrentDate') &&
            moment(localStorage.getItem('messageUsersPriorityMessageCalendarViewCurrentDate'))) ||
            moment(),
    );
    const [selectedDate, setSelectedDate] = useState<moment.Moment>(
        (localStorage.getItem('messageUsersPriorityMessageCalendarViewSelectedDate') &&
            moment(localStorage.getItem('messageUsersPriorityMessageCalendarViewSelectedDate'))) ||
            moment(),
    );
    const [view, setView] = useState<'month' | 'day' | 'list'>(
        (localStorage.getItem('messageUsersPriorityMessageCalendarView') as 'month' | 'day' | 'list') || 'month',
    );

    const [showFutureMessages, setShowFutureMessages] = useState<boolean>(
        (localStorage.getItem('messageUsersPriorityMessageCalendarShowFutureReposts') &&
            localStorage.getItem('messageUsersPriorityMessageCalendarShowFutureReposts') === 'true') ||
            false,
    );

    const { data: sextforceAutoRepostColors, isLoading: sextforceAutoRepostColorsLoading } = useSextforceAutoRepostColors();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handlePrevMonth = () => {
        setCurrentDate(moment(currentDate).subtract(1, 'month'));
        localStorage.setItem('messageUsersPriorityMessageCalendarViewCurrentDate', moment(currentDate).subtract(1, 'month').format());
    };

    const handleNextMonth = () => {
        setCurrentDate(moment(currentDate).add(1, 'month'));
        localStorage.setItem('messageUsersPriorityMessageCalendarViewCurrentDate', moment(currentDate).add(1, 'month').format());
    };

    const handlePrevDay = () => {
        setSelectedDate(moment(selectedDate).subtract(1, 'day'));
        localStorage.setItem('messageUsersPriorityMessageCalendarViewSelectedDate', moment(selectedDate).subtract(1, 'day').format());

        // If the previous day is in the previous month, change the current month
        if (moment(selectedDate).subtract(1, 'day').isBefore(moment(currentDate).startOf('month'))) {
            setCurrentDate(moment(currentDate).subtract(1, 'month'));
            localStorage.setItem('messageUsersPriorityMessageCalendarViewCurrentDate', moment(currentDate).subtract(1, 'month').format());
        }
    };

    const handleNextDay = () => {
        setSelectedDate(moment(selectedDate).add(1, 'day'));
        localStorage.setItem('messageUsersPriorityMessageCalendarViewSelectedDate', moment(selectedDate).add(1, 'day').format());

        // If the next day is in the next month, change the current month
        if (moment(selectedDate).add(1, 'day').isAfter(moment(currentDate).endOf('month'))) {
            setCurrentDate(moment(currentDate).add(1, 'month'));
            localStorage.setItem('messageUsersPriorityMessageCalendarViewCurrentDate', moment(currentDate).add(1, 'month').format());
        }
    };

    const handleDateSelect = (date: moment.Moment) => {
        setSelectedDate(date);
        localStorage.setItem('messageUsersPriorityMessageCalendarViewSelectedDate', date.format());
        setView('day');
        localStorage.setItem('messageUsersPriorityMessageCalendarView', 'day');
    };

    const handleViewChange = (event: React.SyntheticEvent, newValue: any) => {
        setView(newValue);

        localStorage.setItem('messageUsersPriorityMessageCalendarView', newValue);
    };

    // Get tasks originally scheduled for a specific date
    const getOriginalRepostsForDate = (date: moment.Moment): ScheduledTask[] => {
        return scheduledMessages?.filter(task => moment(task.scheduleDateTime).isSame(date, 'day')) || [];
    };

    const getAllTaskOccurrencesForDate = (date: moment.Moment): TaskOccurrence[] => {
        const targetDate = moment(date).startOf('day');
        const targetEndDate = moment(date).endOf('day');
        const occurrences: TaskOccurrence[] = [];

        // Add original tasks
        const originalTasks = getOriginalRepostsForDate(date);
        originalTasks.forEach(task => {
            occurrences.push({
                task,
                dateTime: moment(task.scheduleDateTime),
                isRepeat: false,
            });
        });

        if (!showFutureMessages) {
            return occurrences;
        }

        // Add repeating occurrences
        scheduledMessages?.forEach(task => {
            if (!task.repeatEveryAmount || !task.repeatEveryUnit || task.complete) return;
            if (task.endDate && moment(date).isAfter(task.endDate)) return;

            let currentTime = moment(task.scheduleDateTime);

            // Move to first occurrence that's not the original
            currentTime.add(task.repeatEveryAmount, task.repeatEveryUnit);

            while (currentTime.isSameOrBefore(targetEndDate)) {
                if (currentTime.isSameOrAfter(targetDate)) {
                    occurrences.push({
                        task,
                        dateTime: currentTime.clone(),
                        isRepeat: true,
                    });
                }
                currentTime.add(task.repeatEveryAmount, task.repeatEveryUnit);
            }
        });

        // Sort by datetime
        return occurrences.sort((a, b) => a.dateTime.valueOf() - b.dateTime.valueOf());
    };

    const getRepeatingRepostsForDate = (date: moment.Moment): { task: ScheduledTask; count: number }[] => {
        const targetDate = moment(date).startOf('day');
        const targetEndDate = moment(date).endOf('day');

        return (
            scheduledMessages?.filter(task => {
                if (!task.repeatEveryAmount || !task.repeatEveryUnit || task.complete) {
                    return false;
                }

                if (task.endDate && moment(date).isAfter(task.endDate)) {
                    return false;
                }

                // Allow tasks that either start on this day (for same-day repetitions)
                // or match the repeat interval pattern
                const startDate = moment(task.scheduleDateTime);
                if (!targetDate.isSame(startDate, 'day')) {
                    const startOfDay = moment(startDate).startOf('day');
                    const diffInBaseUnit = targetDate.diff(startOfDay, task.repeatEveryUnit);
                    return diffInBaseUnit > 0 && diffInBaseUnit % task.repeatEveryAmount === 0;
                }

                return true;
            }) || []
        )
            .map(task => {
                let count = 0;
                let currentTime = moment(task.scheduleDateTime);

                // Count occurrences within the day, including original occurrence
                while (currentTime.isSameOrBefore(targetEndDate)) {
                    if (currentTime.isSame(targetDate, 'day')) {
                        count++;
                    }
                    currentTime.add(task.repeatEveryAmount!, task.repeatEveryUnit!);
                }

                // Subtract 1 if this is the original date (it will be shown separately)
                if (moment(task.scheduleDateTime).isSame(targetDate, 'day')) {
                    count = Math.max(0, count - 1);
                }

                return { task, count };
            })
            .filter(({ count }) => count > 0);
    };

    const renderTaskCount = (tasks: ScheduledTask[], colorId: string, isRepeating: boolean = false) => {
        const backgroundColor =
            colorId === 'none' || sextforceAutoRepostColorsLoading || !sextforceAutoRepostColors
                ? alpha(theme.palette.primary.light, 0.5)
                : sextforceAutoRepostColors.find(c => c._id === colorId)?.color || alpha(theme.palette.primary.light, 0.5);

        return (
            <Tooltip
                key={`${colorId}-${isRepeating}`}
                title={`${sextforceAutoRepostColors?.find(c => c._id === colorId)?.name || 'No category'}${
                    isRepeating ? ' (Repeating)' : ''
                }`}
            >
                <Box
                    sx={{
                        pl: 0.5,
                        pr: 0.5,
                        backgroundColor: isRepeating ? theme.palette.grey[300] : backgroundColor,
                        borderRadius: theme.shape.borderRadius,
                        width: '100%',
                        borderLeft: isRepeating ? `3px solid ${backgroundColor}` : 'none',
                    }}
                >
                    <Typography variant="caption" color={isRepeating ? backgroundColor : theme.palette.getContrastText(backgroundColor)}>
                        {tasks.length} {!isMobile && `message${tasks.length > 1 ? 's' : ''}`}
                    </Typography>
                </Box>
            </Tooltip>
        );
    };

    const renderDayContent = (day: moment.Moment) => {
        const originalTasks = getOriginalRepostsForDate(day);
        const repeatingTasks = getRepeatingRepostsForDate(day);

        const originalGrouped = groupRepostsByColorId(originalTasks);
        const repeatingGrouped = repeatingTasks.reduce((acc, { task, count }) => {
            const colorId = task.colorId || 'none';
            if (!acc[colorId]) {
                acc[colorId] = { tasks: [], totalCount: 0 };
            }
            acc[colorId].tasks.push(task);
            acc[colorId].totalCount += count;
            return acc;
        }, {} as { [key: string]: { tasks: ScheduledTask[]; totalCount: number } });

        return (
            <Stack direction="column" spacing={0.5} alignItems="center">
                {Object.entries(originalGrouped).map(([colorId, tasks]) => renderTaskCount(tasks, colorId))}
                {showFutureMessages &&
                    Object.entries(repeatingGrouped).map(([colorId, { tasks, totalCount }]) => (
                        <Tooltip
                            key={`${colorId}-repeating`}
                            title={`${sextforceAutoRepostColors?.find(c => c._id === colorId)?.name || 'No category'} (${
                                tasks.length
                            } tasks repeating ${totalCount} ${totalCount === 1 ? 'time' : 'times'})`}
                        >
                            <Box
                                sx={{
                                    pl: 0.5,
                                    pr: 0.5,
                                    backgroundColor: theme.palette.grey[300],
                                    borderRadius: theme.shape.borderRadius,
                                    width: '100%',
                                    borderLeft: `3px solid ${
                                        colorId === 'none' || !sextforceAutoRepostColors
                                            ? alpha(theme.palette.primary.light, 0.5)
                                            : sextforceAutoRepostColors.find(c => c._id === colorId)?.color ||
                                              alpha(theme.palette.primary.light, 0.5)
                                    }`,
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    color={
                                        colorId === 'none' || !sextforceAutoRepostColors
                                            ? theme.palette.primary.light
                                            : sextforceAutoRepostColors.find(c => c._id === colorId)?.color
                                    }
                                >
                                    {totalCount} {!isMobile && `repeat${totalCount > 1 ? 's' : ''}`}
                                </Typography>
                            </Box>
                        </Tooltip>
                    ))}
            </Stack>
        );
    };

    const groupRepostsByColorId = (tsaks: ScheduledTask[]) => {
        const groupedTasks: { [key: string]: ScheduledTask[] } = {};

        for (const task of tsaks) {
            if (!task.colorId) {
                if (!groupedTasks['none']) {
                    groupedTasks['none'] = [];
                }

                groupedTasks['none'].push(task);
                continue;
            }

            if (!groupedTasks[task.colorId]) {
                groupedTasks[task.colorId] = [];
            }

            groupedTasks[task.colorId].push(task);
        }

        return groupedTasks;
    };

    const renderMonthView = () => {
        const monthStart = moment(currentDate).startOf('month');
        const monthEnd = moment(currentDate).endOf('month');

        // Get the first day of the month (0-6, where 0 is Sunday)
        const firstDayOfMonth = monthStart.day();

        // Create array for all days including empty spots for previous month
        const days: (moment.Moment | null)[] = Array(firstDayOfMonth).fill(null);

        // Add all days of the month
        let currentDay = monthStart.clone();
        while (currentDay.isSameOrBefore(monthEnd)) {
            days.push(currentDay.clone());
            currentDay.add(1, 'day');
        }

        // Calculate remaining spots to fill out the last week
        const remainingDays = (7 - (days.length % 7)) % 7;
        days.push(...Array(remainingDays).fill(null));

        const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <IconButton onClick={handlePrevMonth} disabled={moment(currentDate).isSame(moment(), 'month')}>
                        <ChevronLeft />
                    </IconButton>
                    <Typography variant="h6" sx={{ flex: 1, textAlign: 'center' }}>
                        {currentDate.format('MMMM YYYY')}
                    </Typography>
                    <IconButton onClick={handleNextMonth}>
                        <ChevronRight />
                    </IconButton>
                </Box>

                <Grid container spacing={0}>
                    {weekDays.map(day => (
                        <Grid item key={day} xs={12 / 7}>
                            <Typography align="center" variant="subtitle2">
                                {isMobile ? day.substring(0, 1) : day}
                            </Typography>
                        </Grid>
                    ))}

                    {days.map((day, index) => (
                        <Grid
                            item
                            xs={12 / 7}
                            key={index}
                            sx={{
                                borderTop: index < 7 ? `1px solid ${theme.palette.divider}` : 'none',
                                borderLeft: index % 7 !== 0 ? `1px solid ${theme.palette.divider}` : 'none',
                                borderBottom: `1px solid ${theme.palette.divider}`,
                            }}
                        >
                            {day ? (
                                <Box
                                    onClick={() => handleDateSelect(day)}
                                    sx={{
                                        p: 0.5,
                                        minHeight: 80,
                                        height: '100%',
                                        cursor: 'pointer',
                                        backgroundColor: 'background.paper',
                                        '&:hover': {
                                            backgroundColor: 'action.hover',
                                        },
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        align="center"
                                        sx={{
                                            fontWeight: moment().isSame(day, 'day') ? 'bold' : 'normal',
                                        }}
                                    >
                                        {day.format('D')}
                                    </Typography>
                                    {renderDayContent(day)}
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        p: 1,
                                        minHeight: 80,
                                        height: '100%',
                                        backgroundColor: 'action.disabledBackground',
                                    }}
                                />
                            )}
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
    };

    return (
        <Box>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" sx={{ marginTop: 2, marginBottom: 2 }}>
                <Typography variant="h5">
                    Scheduled Messages ({scheduledMessages ? scheduledMessages.length.toLocaleString() : 0})
                </Typography>
            </Stack>
            <Card variant="elevation">
                <CardContent
                    sx={{
                        p: 0,
                        '&:last-child': {
                            paddingBottom: 0,
                        },
                    }}
                >
                    <AppBar position="static" sx={{ mb: 2 }}>
                        <Tabs
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            value={view}
                            onChange={handleViewChange}
                            centered
                        >
                            <Tab label="Month" value="month" />
                            <Tab label="Day" value="day" />
                            <Tab label="List" value="list" />
                        </Tabs>
                    </AppBar>

                    {view === 'month' && renderMonthView()}
                    {view === 'day' && (
                        <MessageUsersPriorityMessageCalendarDayView
                            taskOccurrences={getAllTaskOccurrencesForDate(selectedDate)}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            handlePrevDay={handlePrevDay}
                            handleNextDay={handleNextDay}
                        />
                    )}
                    {view === 'list' && (
                        <MessageUsersPriorityMessageCalendarListView
                            scheduledMessages={scheduledMessages || []}
                            setSelectedDate={setSelectedDate}
                            isScheduledMessagesLoading={scheduledMessagesLoading}
                        />
                    )}

                    <Stack direction="column" spacing={1} sx={{ mt: 2, mb: 2 }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={view === 'list'}
                                    checked={showFutureMessages}
                                    onChange={() => {
                                        setShowFutureMessages(!showFutureMessages);
                                        localStorage.setItem(
                                            'messageUsersPriorityMessageCalendarShowFutureReposts',
                                            (!showFutureMessages).toString(),
                                        );
                                    }}
                                />
                            }
                            label="Show future messages"
                            sx={{ ml: 1 }}
                        />
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    );
};

export default MessageUsersPriorityMessageCalendar;
