import LoadingButton from '@mui/lab/LoadingButton';
import { Card, CardContent, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError, handleHttpErrorResponse, stripHtml, targetUsersName } from '../../../../utils/common';

const refreshBackgroundStyle = { animation: 'backgroundRefresh .5s ease forwards' };

type Props = {
    currentSession: any;
    isSessionRunning: boolean;
    isJobRunning: boolean;
};

const MessageUsersUnsendCurrentSession = (props: Props) => {
    const { currentSession, isSessionRunning, isJobRunning } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();
    const timezone: string = moment.tz.guess();
    const [stoppingSession, setStoppingSession] = useState<boolean>(false);

    // Fetch Follow-Back Overview
    const handleStopCurrentSession = () => {
        const stopCurrentSession = async (): Promise<any> => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && currentSession) {
                const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/session/${currentSession._id}`;

                setStoppingSession(true);

                await fetch(query, {
                    method: 'delete',
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleHttpErrorResponse(response, dialog);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        handleHttpError(error, dialog);
                    });

                setStoppingSession(false);
            }
        };

        stopCurrentSession();
    };

    return (
        <Card
            variant="elevation"
            sx={{
                marginBottom: 2,
            }}
        >
            <CardContent>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <CircularProgress size={14} />
                            <div>{isSessionRunning ? 'Unsending' : 'Unsending Waiting to Start'}</div>
                        </Stack>
                    </Stack>
                </Typography>
                {isJobRunning && !isSessionRunning && (
                    <Grid container spacing={1}>
                        <Grid item xs>
                            Please wait for this session to finish before unsending another message
                        </Grid>
                    </Grid>
                )}
                {isSessionRunning && currentSession.relatedSession && (
                    <Grid container alignItems={'flex-start'} spacing={1}>
                        <Grid item xs={4}>
                            Message
                        </Grid>
                        <Grid item xs={8}>
                            {currentSession.relatedSession.payload.message
                                ? stripHtml(currentSession.relatedSession.payload.message)
                                      .split('\n')
                                      .map((i: string, key: number) => {
                                          return <div key={key}>{i}</div>;
                                      })
                                : ''}
                        </Grid>
                        <Grid item xs={4}>
                            Latest Update
                        </Grid>
                        <Grid item xs={8}>
                            <span key={currentSession.lastStatusUpdate} style={refreshBackgroundStyle}>
                                {moment(currentSession.lastStatusUpdate).tz(timezone).format('L LT')}
                            </span>
                        </Grid>
                        <Grid item xs={4}>
                            Users Group
                        </Grid>
                        <Grid item xs={8}>
                            <span key={currentSession.lastStatusUpdate} style={refreshBackgroundStyle}>
                                {targetUsersName(currentSession.relatedSession.targetUsers, {})}
                            </span>
                        </Grid>
                        {currentSession.relatedSession.targetUsers === 'lists' && (
                            <>
                                <Grid item xs={4}>
                                    List Name
                                </Grid>
                                <Grid item xs={8}>
                                    <span key={currentSession.lastStatusUpdate} style={refreshBackgroundStyle}>
                                        {currentSession.relatedSession.payload.listName}
                                    </span>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={4}>
                            Latest Status
                        </Grid>
                        <Grid item xs={8}>
                            <span key={currentSession.lastStatusUpdate} style={refreshBackgroundStyle}>
                                {currentSession.status}
                            </span>
                        </Grid>
                        <Grid item xs={4}>
                            Messages Unsent So Far
                        </Grid>
                        <Grid item xs={8}>
                            <span key={currentSession.lastStatusUpdate} style={refreshBackgroundStyle}>
                                {currentSession.followBackSessionSummary ? currentSession.followBackSessionSummary.totalMessagesUnsent : 0}
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                color="error"
                                loading={stoppingSession}
                                onClick={() => {
                                    dialog
                                        .confirm({
                                            message: 'Are you sure you want to stop this session?',
                                            title: 'Stop Unsend Message',
                                            ok: { text: 'STOP', variant: 'contained', color: 'error' },
                                            cancel: { text: 'Cancel' },
                                        })
                                        .then(() => {
                                            handleStopCurrentSession();
                                        })
                                        .catch(() => {});
                                }}
                            >
                                Stop Session!
                            </LoadingButton>
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
};

export default MessageUsersUnsendCurrentSession;
