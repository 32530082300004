import {
    Alert,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    OutlinedInput,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { useEffect, useState } from 'react';

type Props = {
    autoUnsendTimer: boolean;
    setAutoUnsendTimer: (autoUnsendMessage: boolean) => void;
    autoUnsendTimerPeriodAmount: number;
    setAutoUnsendTimerPeriodAmount: (autoUnsendTimerPeriodAmount: number) => void;
    lockMessage: boolean;
    lockMessagePrice: number;
    session?: any;
    theme: Theme;
};

const MessageUsersAutoUnsendTimer = (props: Props) => {
    const {
        autoUnsendTimer,
        setAutoUnsendTimer,
        autoUnsendTimerPeriodAmount,
        setAutoUnsendTimerPeriodAmount,
        lockMessage,
        lockMessagePrice,
        session,
        theme,
    } = props;
    const [autoUnsendTimerPeriodAmountString, setAutoUnsendTimerPeriodAmountString] = useState<string>(
        autoUnsendTimerPeriodAmount.toString(),
    );

    useEffect(() => {
        if (session && session.payload && session.payload.autoUnsendTimerPeriod && session.payload.autoUnsendTimerPeriodAmount) {
            setAutoUnsendTimer(true);
            setAutoUnsendTimerPeriodAmount(session.payload.autoUnsendTimerPeriodAmount);
        }
    }, [session, setAutoUnsendTimer, setAutoUnsendTimerPeriodAmount]);

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
            <Grid item xs={12}>
                <Card variant="elevation">
                    <CardContent>
                        <Grid container spacing={1} flexGrow={0} alignItems="center">
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={autoUnsendTimer}
                                            onChange={(e, checked) => {
                                                setAutoUnsendTimer(checked);
                                            }}
                                        />
                                    }
                                    label={<Typography variant="h6">Auto Unsend Messages?</Typography>}
                                />
                            </Grid>
                            {autoUnsendTimer && (
                                <Grid item xs={12}>
                                    <Grid container spacing={1} flexGrow={0} alignItems="center">
                                        <Grid item xs={12}>
                                            This feature will automatically unsend all this session's messages after the specified period of
                                            time.
                                        </Grid>
                                        <Grid item xs="auto">
                                            <FormControl>
                                                <InputLabel htmlFor="periodAmount">Length</InputLabel>
                                                <OutlinedInput
                                                    id="periodAmount"
                                                    size="medium"
                                                    inputProps={{ inputMode: 'text', pattern: /^\d*$$/ }}
                                                    value={autoUnsendTimerPeriodAmountString}
                                                    onChange={e => {
                                                        e.preventDefault();

                                                        if (/^\d*$/.test(e.target.value)) {
                                                            const parsed = parseFloat(e.target.value.replaceAll(',', '.'));

                                                            if (isNaN(parsed) || parsed === 0) {
                                                                setAutoUnsendTimerPeriodAmount(0);
                                                                setAutoUnsendTimerPeriodAmountString('0');
                                                            } else {
                                                                setAutoUnsendTimerPeriodAmount(parsed);
                                                                setAutoUnsendTimerPeriodAmountString(e.target.value.replace(/^0+/, ''));
                                                            }
                                                        }
                                                    }}
                                                    label="Length"
                                                    error={
                                                        (!lockMessage || (lockMessage && !lockMessagePrice)) &&
                                                        (autoUnsendTimerPeriodAmount === 0 || autoUnsendTimerPeriodAmount > 24)
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <strong>hours</strong> after the last message was sent
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Alert severity="warning" icon={false} style={{ marginTop: theme.spacing(1) }}>
                                            Please bear in mind the time it would take the whole session to send and for the unsending
                                            session to run. A message WITHOUT a locked PPV will not be unsent if it has been longer than 24
                                            hours! Messages with a locked PPV can be unsent even after 24 hours.
                                        </Alert>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MessageUsersAutoUnsendTimer;
