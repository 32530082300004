import { Card, CardContent, Grid, Skeleton, Theme, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import StatsChart from './StatsChart';
import ValueAndTrend from './ValueAndTrend';

const StatsCardTopPerformer = (props: { dailyStats: any[] | undefined; dailyStatsLoading: boolean }) => {
    const { dailyStats, dailyStatsLoading } = props;
    const theme: Theme = useTheme();

    return (
        <Card variant="elevation">
            <CardContent
                sx={{
                    height: 150,
                    padding: {
                        xs: theme.spacing(1.5),
                        sm: theme.spacing(2),
                    },
                    '&:last-child': {
                        paddingBottom: theme.spacing(1.5),
                    },
                }}
            >
                {dailyStatsLoading ? (
                    <Skeleton />
                ) : (
                    <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ height: '100%' }}>
                        <Grid item xs={12}>
                            <Grid container spacing={1} flexGrow={0} alignItems="center">
                                <Grid item xs>
                                    <Typography variant="h6" textAlign={'left'}>
                                        Top Performer
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <ValueAndTrend
                                        logic="less"
                                        value={
                                            dailyStats && dailyStats[0] && dailyStats[0].performerTop
                                                ? dailyStats[0].performerTop
                                                : undefined
                                        }
                                        previousValue={
                                            dailyStats && dailyStats.length > 1 && dailyStats[1].performerTop
                                                ? dailyStats[1].performerTop
                                                : undefined
                                        }
                                        type={'number'}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <StatsChart
                                data={
                                    dailyStats
                                        ? dailyStats.map(d => {
                                              return {
                                                  time: moment(d._id).valueOf(),
                                                  count: d.performerTop || undefined,
                                              };
                                          })
                                        : []
                                }
                                color={theme.palette.primary.main}
                            />
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
};

export default StatsCardTopPerformer;
