import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import {
    Alert,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { LocalizationProvider, MobileDatePicker, MobileDateTimePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import SextforceColorSelector from '../../../common/SextforceColorSelector';
import SelectTimezone from '../../../forms/helpers/SelectTimezone';

const momentPeriods: { [key: string]: string } = {
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
};

// function getDateFormatString(locale: string) {
//     const options: Intl.DateTimeFormatOptions = {
//         hour: 'numeric',
//         minute: 'numeric',
//         second: 'numeric',
//         day: 'numeric',
//         month: 'numeric',
//         year: 'numeric',
//     };

//     const formatObj = new Intl.DateTimeFormat(locale, options).formatToParts(Date.now());

//     return formatObj
//         .map(obj => {
//             switch (obj.type) {
//                 case 'hour':
//                     return '';
//                 case 'minute':
//                     return '';
//                 case 'second':
//                     return '';
//                 case 'day':
//                     return 'DD';
//                 case 'month':
//                     return 'MM';
//                 case 'year':
//                     return 'YYYY';
//                 case 'literal':
//                     if (obj.value === '/') {
//                         return '/';
//                     } else {
//                         return '';
//                     }
//                 default:
//                     return '';
//             }
//         })
//         .join('');
// }

type Props = {
    isSessionOrJobRunning: boolean;
    scheduleMessage: boolean;
    setScheduleMessage: (scheduleMessage: boolean) => void;
    dateTime: Date | null;
    setDateTime: (date: Date | null) => void;
    repeatMessage: boolean;
    setRepeatMessage: (repeatMessage: boolean) => void;
    repeatEveryAmount: number;
    setRepeatEveryAmount: (amount: number) => void;
    repeatEveryUnit: string;
    setRepeatEveryUnit: (unit: string) => void;
    repeatEndDate: Date | null;
    setRepeatEndDate: (date: Date | null) => void;
    colorId?: string;
    setColorId: (colorId: string | undefined) => void;
    session?: any;
    theme: Theme;
};

const MessageUsersSchedule = (props: Props) => {
    const {
        isSessionOrJobRunning,
        scheduleMessage,
        setScheduleMessage,
        dateTime,
        setDateTime,
        repeatMessage,
        setRepeatMessage,
        repeatEveryAmount,
        setRepeatEveryAmount,
        repeatEveryUnit,
        setRepeatEveryUnit,
        repeatEndDate,
        setRepeatEndDate,
        colorId,
        setColorId,
        theme,
        session,
    } = props;
    const [timezone, setTimezone] = useState<string>(moment.tz.guess());
    const [date, setDate] = useState<moment.Moment>(dateTime ? moment(dateTime) : moment());
    const [time, setTime] = useState<moment.Moment>(dateTime ? moment(dateTime) : moment());

    useEffect(() => {
        if (session && session.scheduleMessageDate) {
            const sessionDate: moment.Moment = moment(session.scheduleMessageDate);
            const sessionDateTimezone: string | undefined = sessionDate.tz();

            setScheduleMessage(true);
            setDateTime(sessionDate.toDate());
            sessionDateTimezone && setTimezone(sessionDateTimezone);
        }
    }, [session, setDateTime, setScheduleMessage]);

    useEffect(() => {
        if (isSessionOrJobRunning) {
            setScheduleMessage(true);
        }
    }, [isSessionOrJobRunning, setScheduleMessage]);

    useEffect(() => {
        const handleDateTimeChanged = (newDate: moment.Moment, newTime: moment.Moment) => {
            const localDateTime = moment.tz(`${newDate.format('YYYY-MM-DD')} ${newTime.format('HH:mm')}`, timezone).local();

            setDateTime(localDateTime.toDate());
        };

        handleDateTimeChanged(date, time);
    }, [date, setDateTime, time, timezone]);

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
            <Grid item xs={12}>
                <Card variant="elevation">
                    <CardContent>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Grid container spacing={1} alignItems="center" sx={{ marginBottom: 2 }}>
                                <Grid item xs="auto">
                                    <ScheduleSendIcon color="secondary" />
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="h6">When to Send?</Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <RadioGroup
                                        value={scheduleMessage ? 'later' : 'now'}
                                        onChange={e => {
                                            setScheduleMessage(e.currentTarget.value === 'later');
                                        }}
                                        sx={{ paddingBottom: 1 }}
                                    >
                                        <FormControlLabel
                                            value="now"
                                            control={<Radio size="medium" disabled={isSessionOrJobRunning} />}
                                            label={
                                                <Stack direction="column" spacing={0}>
                                                    <Typography variant="inherit" color="inherit">
                                                        Send now!
                                                    </Typography>
                                                    <Typography variant="caption" color="textSecondary">
                                                        The message will start sending immediately, or as soon as the previous session had
                                                        finished.
                                                    </Typography>
                                                </Stack>
                                            }
                                        />
                                        <FormControlLabel
                                            value="later"
                                            control={<Radio size="medium" />}
                                            label={
                                                <Stack direction="column" spacing={0}>
                                                    <Typography variant="inherit" color="inherit">
                                                        Schedule for later...
                                                    </Typography>
                                                    <Typography variant="caption" color="textSecondary">
                                                        Choose a date and time for the message to start sending.
                                                    </Typography>
                                                </Stack>
                                            }
                                        />
                                    </RadioGroup>
                                </Grid>
                            </Grid>

                            {scheduleMessage && (
                                <Grid container alignItems="center" spacing={1} sx={{ paddingLeft: 4 }}>
                                    <Grid item xs={12} sm>
                                        <SelectTimezone timezone={timezone} setTimezone={setTimezone} fullWidth={true} />
                                    </Grid>
                                    <Grid item xs={6} sm="auto">
                                        <MobileDatePicker
                                            renderInput={props => (
                                                <TextField
                                                    {...props}
                                                    // helperText={getDateFormatString(Intl.Locale.name)}
                                                />
                                            )}
                                            label="Date"
                                            value={moment(date)}
                                            onChange={newValue => {
                                                setDate(newValue as moment.Moment);
                                                // handleDateTimeChanged(newValue as moment.Moment, time);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm="auto">
                                        <MobileTimePicker
                                            label="Time"
                                            ampm={true}
                                            value={moment(time)}
                                            onChange={newValue => {
                                                setTime(newValue as moment.Moment);
                                                // handleDateTimeChanged(date, newValue as moment.Moment);
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    // helperText="HH:MM AM/PM"
                                                />
                                            )}
                                            DialogProps={{
                                                sx: {
                                                    '.MuiPickersToolbar-content .Mui-selected': {
                                                        color: theme.palette.secondary.main,
                                                    },
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Alert variant="standard" severity="info">
                                            A scheduled message won't start sending until the previous session had finished
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="inherit" gutterBottom>
                                            Category Color
                                        </Typography>
                                        <Typography variant="body2" color={'text.secondary'}>
                                            Choose an identifying color for this message. This will be used to help identify the category or
                                            topic of the message.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SextforceColorSelector colorId={colorId} setColorId={setColorId} />
                                    </Grid>
                                </Grid>
                            )}

                            <Grid container spacing={1} flexGrow={0} alignItems="center" sx={{ mt: 1 }}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="medium"
                                                checked={repeatMessage}
                                                onChange={(e, checked) => {
                                                    setRepeatMessage(checked);
                                                }}
                                            />
                                        }
                                        label="Repeat this message..."
                                    />
                                </Grid>
                                {repeatMessage && (
                                    <Grid item xs={12} sx={{ marginLeft: 4 }}>
                                        <Grid container spacing={1} flexGrow={1} alignItems="center">
                                            <Grid item xs={6} sm="auto">
                                                <TextField
                                                    label="Every"
                                                    type="number"
                                                    value={repeatEveryAmount}
                                                    onChange={e => {
                                                        setRepeatEveryAmount(
                                                            Number.isNaN(Number.parseInt(e.target.value, 10))
                                                                ? 0
                                                                : Number.parseInt(e.target.value, 10),
                                                        );
                                                    }}
                                                    InputProps={{ inputProps: { min: 1 } }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm="auto">
                                                <FormControl fullWidth>
                                                    <InputLabel>Period</InputLabel>
                                                    <Select
                                                        label="Period"
                                                        value={repeatEveryUnit}
                                                        onChange={e => {
                                                            setRepeatEveryUnit(e.target.value as string);
                                                        }}
                                                        fullWidth
                                                        size="small"
                                                    >
                                                        {Object.keys(momentPeriods).map(item => (
                                                            <MenuItem value={item} key={item}>
                                                                {momentPeriods[item]}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                Stop repeating on:
                                            </Grid>
                                            <Grid item xs={12} sm="auto">
                                                <MobileDateTimePicker
                                                    renderInput={props => (
                                                        <TextField
                                                            {...props}
                                                            error={repeatEndDate && repeatEndDate < new Date() ? true : false}
                                                            helperText={
                                                                repeatEndDate && repeatEndDate < new Date()
                                                                    ? 'End date must be in the future'
                                                                    : ''
                                                            }
                                                            fullWidth
                                                            InputProps={{
                                                                endAdornment: repeatEndDate ? (
                                                                    <ClearIcon
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();

                                                                            setRepeatEndDate(null);
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <CalendarMonthIcon />
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                    label="Repeat Until"
                                                    ampm={true}
                                                    value={repeatEndDate ? moment(repeatEndDate) : null}
                                                    minDate={moment()}
                                                    onChange={newValue => {
                                                        setRepeatEndDate(newValue?.toDate() || null);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </LocalizationProvider>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MessageUsersSchedule;
