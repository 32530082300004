import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import {
    Avatar,
    Chip,
    IconButton,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useSubscriber from '../../hooks/useSubscriber';
import { OnlyFansSubscriberCategory } from '../../hooks/useSubscriberCategories';
import DashboardMyAccountsSetCategoryDialog from '../dashboard/DashboardMyAccountsSetCategoryDialog';
import OnlyFansReloginDialog from '../login/OnlyFansReloginDialog';
import UsernameWithAvatarLocalMenu from './UsernameWithAvatarLocalMenu';

const TableCellStyles = styled(TableCell)(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
}));

const UsernameWithAvatar = ({
    subscriber,
    category,
    refetchSubscribers,
}: {
    subscriber: any;
    category: OnlyFansSubscriberCategory | void;
    refetchSubscribers: () => void;
}) => {
    const theme: Theme = useTheme();
    const dialog = useDialog();
    const { enqueueSnackbar } = useSnackbar();
    const { subscriberLogout, subscriberRemove, refetch: refetchSubscriber } = useSubscriber();

    const [openSetCategoryDialog, setOpenSetCategoryDialog] = useState(false);
    const [openReloginDialog, setOpenReloginDialog] = useState(false);

    const handleSetCategoryDialogClosed = () => {
        setOpenSetCategoryDialog(false);
    };

    const handleReloginDialogClosed = () => {
        setOpenReloginDialog(false);
    };

    const onClickRelogin = () => {
        setOpenReloginDialog(true);
    };

    const onClickLogout = () => {
        dialog
            .confirm({
                title: 'Logout',
                message: 'Are you sure you want to logout? All active sessions and services will be stopped.',
                cancel: {
                    text: 'Cancel',
                    color: 'primary',
                    variant: 'outlined',
                },
                ok: {
                    text: 'Logout',
                    color: 'error',
                    variant: 'contained',
                },
            })
            .then(async () => {
                // Logout
                await subscriberLogout(subscriber._id)
                    .then(() => {
                        enqueueSnackbar('Successfully logged out', { variant: 'success' });

                        // Refetch the subscribers
                        refetchSubscriber();
                        refetchSubscribers();
                    })
                    .catch(() => {});
            });
    };

    const onClickRemove = () => {
        dialog
            .confirm({
                title: 'Delete Account',
                message: 'Are you sure you want to delete this OnlyFans page from your dashboard account? All services will be stopped.',
                cancel: {
                    text: 'Cancel',
                    color: 'primary',
                    variant: 'outlined',
                },
                ok: {
                    text: 'Delete',
                    color: 'error',
                    variant: 'contained',
                },
            })
            .then(async () => {
                // Remove
                await subscriberRemove(subscriber._id)
                    .then(() => {
                        enqueueSnackbar(`Successfully deleted ${(subscriber.username as string).toLowerCase()}`, { variant: 'success' });

                        // Refetch the subscribers
                        refetchSubscriber();
                        refetchSubscribers();
                    })
                    .catch(() => {});
            });
    };

    return (
        <>
            <Table width={'100%'}>
                <TableBody>
                    <TableRow>
                        <TableCellStyles rowSpan={3} width={48} sx={{ borderBottom: 'none', p: 0 }}>
                            <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
                                <Avatar src={subscriber.cognito?.avatar?.url || undefined} sx={{ width: '48px', height: '48px', mr: 1 }}>
                                    {subscriber.username.charAt(0).toUpperCase()}
                                </Avatar>
                                {!subscriber.isLoggedIn && (
                                    <Chip
                                        label={'OFFLINE'}
                                        size="small"
                                        color={'error'}
                                        onClick={() => {
                                            onClickRelogin();
                                        }}
                                        sx={{
                                            cursor: 'pointer',
                                            // full width
                                            width: '100%',
                                            '&:hover': {
                                                backgroundColor: theme.palette.error.light,
                                            },
                                        }}
                                    />
                                )}
                                {subscriber.isLoggedIn && (
                                    <Chip
                                        color="primary"
                                        variant={'outlined'}
                                        size="small"
                                        label={subscriber.ofSubscriptionPrice ? 'PAID' : 'FREE'}
                                        sx={{
                                            // full width
                                            width: '100%',
                                        }}
                                    />
                                )}
                            </Stack>
                        </TableCellStyles>
                        <TableCellStyles align="left" sx={{ borderBottom: 'none', p: 0, pt: 0.5, pl: 1 }}>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent={'space-between'}>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent={'flex-start'}>
                                    <Typography variant="h6" sx={{ display: 'inline', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <Link
                                            to={`/subscribers/${subscriber._id}`}
                                            style={{ textDecoration: 'none', color: theme.palette.primary.main }}
                                        >
                                            {subscriber.username.toUpperCase()}
                                        </Link>
                                    </Typography>
                                </Stack>
                                <UsernameWithAvatarLocalMenu
                                    subscriber={subscriber}
                                    onClickRelogin={onClickRelogin}
                                    onClickLogout={onClickLogout}
                                    onClickRemove={onClickRemove}
                                />
                            </Stack>
                        </TableCellStyles>
                    </TableRow>
                    <TableRow>
                        <TableCellStyles align="left" colSpan={2} sx={{ borderBottom: 'none', p: 0.5, pl: 1 }}>
                            <Typography variant="body2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {subscriber.email}
                            </Typography>
                        </TableCellStyles>
                    </TableRow>
                    <TableRow>
                        <TableCellStyles align="left" colSpan={2} sx={{ borderBottom: 'none', p: 0, pb: 0.5, pl: 1 }}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="body2" color="textSecondary" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {category ? category.name : 'No Category'}
                                </Typography>
                                <Tooltip title="Move to another category">
                                    <IconButton
                                        size="small"
                                        sx={{ width: 24, height: 24 }}
                                        onClick={() => {
                                            setOpenSetCategoryDialog(true);
                                        }}
                                    >
                                        <DriveFileMoveIcon fontSize="medium" color="primary" />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </TableCellStyles>
                    </TableRow>
                </TableBody>
            </Table>

            {openSetCategoryDialog && (
                <DashboardMyAccountsSetCategoryDialog
                    subscriber={subscriber}
                    open={openSetCategoryDialog}
                    onClose={handleSetCategoryDialogClosed}
                    refetchSubscribers={refetchSubscribers}
                />
            )}

            {openReloginDialog && (
                <OnlyFansReloginDialog
                    subscriber={subscriber}
                    refetchSubscriber={refetchSubscriber}
                    open={openReloginDialog}
                    onClose={handleReloginDialogClosed}
                />
            )}
        </>
    );
};

export default UsernameWithAvatar;
