import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useInfiniteQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useSubscriber from './useSubscriber';

export namespace OnlyFansVaultAlbums {
    export interface VaultAlbums {
        list: List[];
        all: All;
        hasMore: boolean;
    }

    export interface All {
        videosCount: number;
        photosCount: number;
        gifsCount: number;
        audiosCount: number;
        medias: Media[];
    }

    export interface Media {
        type: MediaType;
        url: string;
    }

    export enum MediaType {
        GIF = 'gif',
        Photo = 'photo',
        Video = 'video',
        Audio = 'audio',
    }

    export interface List {
        id: number;
        type: ListType;
        name: string;
        hasMedia: boolean;
        canUpdate: boolean;
        canDelete: boolean;
        videosCount: number;
        photosCount: number;
        gifsCount: number;
        audiosCount: number;
        medias: Media[];
    }

    export enum ListType {
        Custom = 'custom',
    }
}

export namespace OnlyFansDeleteStory {
    export interface Response {
        success: boolean;
    }
}

export namespace OnlyFansVaultMedias {
    export interface VaultMedias {
        list: List[];
        hasMore: boolean;
    }

    export interface List {
        id: number;
        type: ListType;
        convertedToVideo: boolean;
        canView: boolean;
        hasError: boolean;
        createdAt: Date;
        isReady: boolean;
        counters: Counters;
        files: Files;
        duration: number;
        hasPosts: boolean;
        listStates: ListState[];
        mentionedUsers: MentionedUser[];
        releaseForms: ReleaseForm[];
        hasCustomPreview: boolean;
        videoSources: { [key: string]: null | string };
    }

    export interface Counters {
        likesCount: number;
        tipsSumm: number;
    }

    export interface Files {
        full: Full;
        thumb?: Full;
        preview?: Full;
        squarePreview?: Full;
    }

    export interface Full {
        url: string;
        width: number;
        height: number;
        size: number;
        sources?: Source[];
        options?: Option[];
    }

    export interface Option {
        id: number;
        url: string;
        selected: boolean;
        custom: boolean;
    }

    export interface Source {
        source: string;
        width: number;
        height: number;
        size: number;
        duration: number;
    }

    export interface ListState {
        id: number;
        type: ListStateType;
        name: string;
        hasMedia: boolean;
        canAddMedia: boolean;
    }

    export enum ListStateType {
        Custom = 'custom',
        Messages = 'messages',
        Posts = 'posts',
        Stories = 'stories',
        Streams = 'streams',
    }

    export enum ListType {
        Photo = 'photo',
        Video = 'video',
        Audio = 'audio',
        GIF = 'gif',
    }

    export enum SortOrder {
        Asc = 'asc',
        Desc = 'desc',
    }

    export enum SortType {
        Recent = 'recent',
        MostLiked = 'most-liked',
        HighestTips = 'highest-tips',
    }

    export interface MentionedUser {
        view: string;
        avatar: string;
        avatarThumbs: AvatarThumbs;
        header: string;
        headerSize: HeaderSize;
        headerThumbs: HeaderThumbs;
        id: number;
        name: string;
        username: string;
        hasNotViewedStory: boolean;
        isVerified: boolean;
        hasStream: boolean;
        hasStories: boolean;
        isRestricted: boolean;
        hasPromotion: boolean;
        isFree: boolean;
    }

    export interface ReleaseForm {
        id: number;
        name: string;
        partnerSource: PartnerSource;
        type: ReleaseFormType;
        status: Status;
        code: null;
        signature: null;
        date: Date;
        approve_date: Date;
        user: User;
    }

    export enum PartnerSource {
        Document = 'document',
        Tag = 'tag',
    }

    export enum ReleaseFormType {
        Document = 'document',
        User = 'user',
    }

    export interface User {
        view: View;
        id: number;
        name: string;
        username: string;
        isVerified: boolean;
        avatar: null;
        avatarThumbs: null;
        ivStatus: null;
        isFromGuest: boolean;
    }

    export enum View {
        N = 'n',
        M = 'm',
        Cl = 'cl',
        X = 'x',
        S = 's',
    }

    export enum Status {
        Approved = 'approved',
    }

    export interface AvatarThumbs {
        c50: string;
        c144: string;
    }

    export interface HeaderSize {
        width: number;
        height: number;
    }

    export interface HeaderThumbs {
        w480: string;
        w760: string;
    }

    export interface DeleteResponse {
        success: boolean;
    }

    export interface RestructuredResponseItem {
        id: number;
        canView: boolean;
        hasError: boolean;
        createdAt: Date;
        thumb: string | null;
        cachedThumb: string | null;
        src?: string;
        duration?: number;
        type: string;
        counters: Counters;
        mentionedUsers?: MentionedUser[];
    }
}

const useSubscriberVaultAlbums = (enabled: boolean = true) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { data: subscriber } = useSubscriber();
    const dialog = useDialog();

    // Fetch Subscriber's Lists
    const fetchVaultAlbums = async ({ offset }: { offset: number }): Promise<OnlyFansVaultAlbums.VaultAlbums> => {
        const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/vaultAlbums`;

        return axios
            .get(query, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
                params: {
                    offset,
                },
            })
            .then(response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);
            });
    };

    return useInfiniteQuery<OnlyFansVaultAlbums.VaultAlbums, Error>(
        ['vaultAlbums', subscriber?._id],
        ({ pageParam = 0 }) => fetchVaultAlbums({ offset: pageParam }),
        {
            getNextPageParam: (lastPage, allPages) => {
                return lastPage.hasMore ? allPages.length * 10 : undefined; // Increment offset by 10 for each page
            },
            select: data => {
                // Combine all pages into a single data structure
                return {
                    pages: data.pages.map(page => ({
                        ...page,
                        list: page.list,
                    })),
                    pageParams: data.pageParams,
                };
            },
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            enabled: userContext.jwtToken && settingsContext.apiKey && subscriber?._id && enabled ? true : false,
        },
    );
};

export default useSubscriberVaultAlbums;
