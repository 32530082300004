import InfoIcon from '@mui/icons-material/Info';
import { Alert, Backdrop, Card, CardContent, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import { SettingsContext } from '../../../store/SettingsContext';
import { UserContext } from '../../../store/UserContext';
import { handleHttpError, isSextforceActive } from '../../../utils/common';
// import MessageUsersHistoryGraph from '../../../components/services/messageUsers/history/MessageUsersHistoryGraph';
import { useSnackbar } from 'notistack';
import PageContainer from '../../../components/common/PageContainter';
import MessageUsersHistorySearchBar from '../../../components/services/messageUsers/history/MessageUsersHistorySearchBar';
import MessageUsersHistorySessionsList from '../../../components/services/messageUsers/history/MessageUsersHistorySessionsList';
import MessageUsersUnsendCurrentSession from '../../../components/services/messageUsers/unsendMessage/MessageUsersUnsendCurrentSession';
import useAxios from '../../../hooks/useAxios';
import useMessageUsersCurrentSessions from '../../../hooks/useMessageUsersCurrentSessions';
import useSubscriber from '../../../hooks/useSubscriber';

const MessageUsersHistory = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const dialog = useDialog();
    const params = useParams();
    const axios = useAxios();
    const isMounted = useRef(true);
    const { enqueueSnackbar } = useSnackbar();
    const timezone: string = moment.tz.guess();

    const { data: subscriber, isLoading: subscriberLoading } = useSubscriber();
    const {
        data: currentSessions,
        isLoading: currentSessionLoading,
        refetch: refetchCurrentSession,
        isMessageUsersUnendSessionRunning,
        isMessageUsersUnsendJobInQueue,
    } = useMessageUsersCurrentSessions();
    const [isUnsendingMessage, setIsUnsendingMessage] = useState<boolean>(false);

    // Search Bar
    const [searchTimezone, setSearchTimezone] = useState<string>(moment.tz.guess());
    const [searchMainDateFrom, setSearchMainDateFrom] = useState<Date>(moment().toDate());

    // Report
    const [isFetchingReport, setIsFetchingReport] = useState<boolean>(false);
    const [reportTimezone, setReportTimezone] = useState<string>(moment.tz.guess());
    const [reportMainDateFrom, setReportMainDateFrom] = useState<Date | null>(null);

    // Result
    const [mainData, setMainData] = useState<any[]>([]);
    const [sextforceActive, setSextforceActive] = useState<boolean>(false);
    const [hoursSinceMessage, setHoursSinceMessage] = useState<number>(6);
    const [showSalesMode, setShowSalesMode] = useState<string>('net');
    const [sessionEarningsData, setSessionEarningsData] = useState<any[]>([]);
    const [sessionEarningsDataLoading, setSessionEarningsDataLoading] = useState<boolean>(false);

    // Fetch Report
    // Retrieve the report from the server
    const requestReport = useCallback(async () => {
        const fetchReport = async (date: Date, timezone: string): Promise<any[]> => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && !subscriberLoading && subscriber) {
                const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/history?${new URLSearchParams({
                    startDate: moment(date).tz(timezone, true).startOf('day').toISOString(),
                    endDate: moment(date).tz(timezone, true).endOf('day').toISOString(),
                })}`;

                setIsFetchingReport(true);

                const result = await axios
                    .get(query)
                    .then(async response => {
                        if (response.status === 200) {
                            return response.data;
                        }

                        throw new Error(response.statusText);
                    })
                    .catch(error => {
                        handleHttpError(error, dialog);
                    });

                setIsFetchingReport(false);

                return result;
            }

            return [];
        };

        const fetchAllReports = async () => {
            // Update report date range
            setReportTimezone(searchTimezone);
            setReportMainDateFrom(searchMainDateFrom);

            // Fetch Follow-BackReport
            const result = await fetchReport(searchMainDateFrom, searchTimezone);

            setSessionEarningsData([]);
            setMainData(result);
        };

        if (isFetchingReport) {
            return;
        }

        fetchAllReports();

        // only update if we are still mounted
        if (isMounted.current) {
        }
    }, [isFetchingReport, searchMainDateFrom, searchTimezone, subscriber]);

    // Auto load current week's report
    useEffect(() => {
        requestReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (subscriber) {
            setSextforceActive(isSextforceActive(subscriber));

            if (!mainData) {
                requestReport();
            }
        } else {
            setSextforceActive(false);
        }
    }, [subscriber]);

    useEffect(() => {
        if (subscriber) {
            requestReport();
        }
    }, [searchMainDateFrom]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleUnsendMessage = (sessionId: string) => {
        const unsendMessage = async (sessionId: string) => {
            if (userContext.jwtToken && settingsContext.apiKey && params && 'userId' in params && params.userId) {
                const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/unsendMessage`;

                setIsUnsendingMessage(true);

                const unsendSession = await axios
                    .post(query, { sessionId })
                    .then(async response => {
                        if (response.status === 200 && response.data && response.data.success) {
                            enqueueSnackbar('Unsend Message Job has been added to the queue', { variant: 'success' });

                            return response.data;
                        }

                        throw new Error(response.statusText);
                    })
                    .catch(async error => {
                        console.error(error);
                        handleHttpError(error, dialog);
                    });

                if (unsendSession) {
                    refetchCurrentSession();
                }

                setIsUnsendingMessage(false);
            }
        };

        unsendMessage(sessionId);
    };

    useEffect(() => {
        const fetchSessionSalesData = async (id: string): Promise<any> => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/sales/${id}?${new URLSearchParams({
                    hoursSinceMessage: hoursSinceMessage.toString(),
                })}`;

                return axios
                    .get(query)
                    .then(async response => {
                        if (response.status === 200) {
                            return response.data;
                        }

                        throw new Error(response.statusText);
                    })
                    .catch(error => {
                        console.error(error);
                        handleHttpError(error, dialog);
                    });
            }
        };

        if (subscriber && mainData && sextforceActive) {
            // Fetch session sales data using fetchSessionSalesData for each mainData record but only fetch one record at a time
            const fetchSessionSalesDataOneByOne = async () => {
                setSessionEarningsDataLoading(true);

                for (const sessionData of mainData) {
                    const sessionSalesData = await fetchSessionSalesData(sessionData._id);

                    if (sessionSalesData) {
                        setSessionEarningsData(prevState => [...prevState, sessionSalesData]);
                    }
                }

                setSessionEarningsDataLoading(false);
            };

            fetchSessionSalesDataOneByOne();
        }
    }, [hoursSinceMessage, mainData, sextforceActive, subscriber]);

    // const sessionsSalesDataLoading = sessionsSalesData.some(sessionQuery => sessionQuery.isLoading || sessionQuery.isFetching);
    // const isUnsendMessageSessionRunning: boolean = currentSessions && currentSessions.unsendMessage.sessions.length > 0 ? true : false;
    // const isUnsendMessageJobInQueue: boolean = currentSessions && currentSessions.unsendMessage.queue ? true : false;

    return (
        <PageContainer>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}/${settingsContext.services.messageUsers.homeUrl}`} />{' '}
                        Message Users History for {subscriber.username}
                    </Typography>

                    {subscriber ? (
                        <>
                            <MessageUsersHistorySearchBar
                                timezone={searchTimezone}
                                setTimezone={setSearchTimezone}
                                date={searchMainDateFrom}
                                setDate={setSearchMainDateFrom}
                                isFetchingReport={isFetchingReport}
                                requestReport={requestReport}
                            />

                            <Typography variant="subtitle1" sx={{ paddingBottom: theme.spacing(2), textAlign: 'center' }}>
                                {reportMainDateFrom ? `Results for ${reportMainDateFrom.toLocaleDateString()}` : 'No Dates Selected'}
                                {reportMainDateFrom && ` (${reportTimezone})`}
                            </Typography>

                            {isMessageUsersUnendSessionRunning || isMessageUsersUnsendJobInQueue ? (
                                <MessageUsersUnsendCurrentSession
                                    currentSession={currentSessions?.unsendMessage.sessions[0]}
                                    isSessionRunning={isMessageUsersUnendSessionRunning}
                                    isJobRunning={isMessageUsersUnsendJobInQueue}
                                />
                            ) : (
                                <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                                    <CardContent>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6">
                                                    Unsend Message Last Run{' '}
                                                    <Tooltip title={`Time Zone: ${timezone}`}>
                                                        <InfoIcon fontSize="small" />
                                                    </Tooltip>
                                                </Typography>

                                                {subscriber.lastUnsendMessageDate ? (
                                                    <>{moment(subscriber.lastUnsendMessageDate).tz(timezone).format('L hh:mm a')} </>
                                                ) : (
                                                    'Unsend Message has not run yet on this account'
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            )}

                            <MessageUsersHistorySessionsList
                                subscriber={subscriber}
                                data={mainData}
                                currentUnsendMessageSessions={currentSessions ? currentSessions.unsendMessage : null}
                                isLoadingCurrentUnsendMessageSessions={subscriberLoading || currentSessionLoading}
                                isFetching={isFetchingReport}
                                timezone={reportTimezone}
                                handleUnsendMessage={handleUnsendMessage}
                                sextforceActive={sextforceActive}
                                showSalesMode={showSalesMode}
                                setShowSalesMode={setShowSalesMode}
                                hoursSinceMessage={hoursSinceMessage}
                                setHoursSinceMessage={setHoursSinceMessage}
                                // sessionsSalesData={sessionsSalesData}
                                // sessionsSalesDataLoading={sessionsSalesDataLoading}
                                sessionsSalesData={sessionEarningsData}
                                sessionsSalesDataLoading={subscriberLoading || sessionEarningsDataLoading}
                            />
                        </>
                    ) : (
                        <>
                            <Alert severity="warning" sx={{ marginBottom: theme.spacing(2) }}>
                                You do not have Follow-Back setup on this account. Please contact Admin for more information.
                            </Alert>
                        </>
                    )}
                </>
            )}

            <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={isUnsendingMessage}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </PageContainer>
    );
};

export default MessageUsersHistory;
