import DeleteIcon from '@mui/icons-material/Delete';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import {
    Box,
    Button,
    Collapse,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import useMessageUsersScheduledSessions, { ScheduledTask } from '../../../../hooks/useMessageUsersScheduledSessions';
import MessageUsersScheduledListRowDetails from '../scheduled/MessageUsersScheduledListRowDetails';
import MessageUsersScheduledTaskChangeSettingsDialog from '../scheduled/MessageUsersScheduledTaskChangeSettingsDialog';
import MessageUsersPriorityMessageNameAndBadges from './MessageUsersPriorityMessageNameAndBadges';

const MessageUsersPriorityMessageCalendarItemCard = (props: {
    scheduledMessage: ScheduledTask;
    isFutureScheduledMessage: boolean;
    futureScheduledDate?: moment.Moment;
    setSelectedDate: (day: moment.Moment) => void;
}) => {
    const { scheduledMessage, isFutureScheduledMessage, futureScheduledDate, setSelectedDate } = props;

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const dialog = useDialog();
    const { enqueueSnackbar } = useSnackbar();
    const timezone = moment.tz.guess();

    const { deleteScheduledMessage, isDeletingScheduledMessage } = useMessageUsersScheduledSessions();

    const [open, setOpen] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);

    const handleDeleteScheduledMessage = (id: string) => {
        dialog
            .confirm({
                message: 'Are you sure you want to delete this scheduled message?',
                ok: { text: 'Delete', variant: 'contained', color: 'error' },
                cancel: { text: 'Cancel' },
                title: 'Delete Scheduled Message',
            })
            .then(async () => {
                const result = await deleteScheduledMessage(id);

                if (result && result.success) {
                    enqueueSnackbar('Scheduled message deleted.', { variant: 'success' });
                } else {
                    enqueueSnackbar('Failed to delete scheduled message.', { variant: 'error' });
                }
            })
            .catch(() => {});
    };

    return (
        <Box
            pl={1}
            pr={1}
            pb={0}
            sx={{
                borderBottom: '1px solid',
                borderBottomColor: 'divider',
                backgroundColor: isFutureScheduledMessage ? 'grey.50' : 'transparent',
                // opacity: isFutureScheduledMessage ? 0.5 : 1,
            }}
        >
            <Table
                width={'100%'}
                size="small"
                sx={{
                    '& td': {
                        '&:first-of-type': {
                            paddingLeft: '0px',
                            paddingRight: '0px',
                        },
                        '&:last-child': {
                            paddingRight: '0px',
                        },
                        paddingLeft: 1,
                        paddingRight: 1,
                        borderBottom: 'none',
                    },
                    '& th': {
                        '&:first-of-type': {
                            paddingLeft: '0px',
                            paddingRight: '0px',
                        },
                        '&:last-child': {
                            paddingRight: '0px',
                        },
                        // Make header cells bold
                        fontWeight: 'bold',
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                    bgcolor: open ? 'grey.50' : 'transparent',
                    tableLayout: 'fixed',
                }}
            >
                <TableBody>
                    <TableRow>
                        <TableCell
                            width={'85px'}
                            valign="top"
                            sx={{
                                borderRight: '6px solid',
                                borderRightColor: scheduledMessage.color ? scheduledMessage.color.color : 'divider',
                                paddingLeft: 0,
                                minWidth: 75,
                            }}
                        >
                            <Stack direction="column" spacing={1} alignItems="center">
                                {isFutureScheduledMessage && futureScheduledDate && (
                                    <Button
                                        variant="text"
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            setSelectedDate(moment(scheduledMessage.scheduleDateTime));
                                        }}
                                        startIcon={<EventRepeatIcon />}
                                    >
                                        {moment(scheduledMessage.scheduleDateTime).format('DD MMM')}
                                    </Button>
                                )}
                                <Box>
                                    {moment(
                                        isFutureScheduledMessage && futureScheduledDate
                                            ? futureScheduledDate
                                            : scheduledMessage.scheduleDateTime,
                                    ).format('hh:mm a')}
                                </Box>
                                <Tooltip title={open ? 'Hide Message Details' : 'Show Message Details'}>
                                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </TableCell>
                        <TableCell sx={{ textWrap: 'wrap', overflow: 'scroll' }}>
                            <Stack direction="column" spacing={0.5} sx={{ pt: 1 }}>
                                <MessageUsersPriorityMessageNameAndBadges
                                    scheduledMessage={scheduledMessage}
                                    isFutureScheduledMessage={isFutureScheduledMessage}
                                    timezone={timezone}
                                />

                                <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="flex-start">
                                    <Tooltip title="Edit Repeat Settings">
                                        <div>
                                            <IconButton
                                                color={
                                                    scheduledMessage.repeatEveryAmount && scheduledMessage.repeatEveryUnit
                                                        ? 'success'
                                                        : 'default'
                                                }
                                                size="small"
                                                disabled={
                                                    scheduledMessage.complete || isDeletingScheduledMessage || isFutureScheduledMessage
                                                }
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setOpenEditDialog(true);
                                                }}
                                            >
                                                <MoreTimeIcon />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title="Delete Scheduled Message">
                                        <div>
                                            <IconButton
                                                color="error"
                                                size="small"
                                                disabled={
                                                    scheduledMessage.complete || isDeletingScheduledMessage || isFutureScheduledMessage
                                                }
                                                onClick={e => {
                                                    e.stopPropagation();

                                                    handleDeleteScheduledMessage(scheduledMessage._id!);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </Stack>
                            </Stack>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                            }}
                            colSpan={2}
                        >
                            <Collapse
                                in={open}
                                timeout={{
                                    enter: theme.transitions.duration.enteringScreen,
                                    exit: theme.transitions.duration.leavingScreen,
                                }}
                                unmountOnExit
                                sx={{
                                    overflow: 'hidden',
                                }}
                            >
                                <Box
                                    sx={{
                                        overflowX: 'auto',
                                        border: '6px solid',
                                        borderColor: scheduledMessage.color ? scheduledMessage.color.color : theme.palette.divider,
                                    }}
                                >
                                    <MessageUsersScheduledListRowDetails
                                        row={scheduledMessage}
                                        timezone={timezone}
                                        smallScreen={smallScreen}
                                    />
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            {openEditDialog && (
                <MessageUsersScheduledTaskChangeSettingsDialog
                    open={openEditDialog}
                    onClose={() => setOpenEditDialog(false)}
                    scheduledTask={scheduledMessage}
                />
            )}
        </Box>
    );
};

export default MessageUsersPriorityMessageCalendarItemCard;
