import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';
import { SextforceAutoRepostColor } from './useSextforceAutoRepost';

export const SextforceAutoRepostColorsBasicList = [
    {
        id: 1,
        name: 'Silver',
        hex: '#C0C0C0',
    },
    {
        id: 2,
        name: 'Gray',
        hex: '#808080',
    },
    {
        id: 3,
        name: 'Black',
        hex: '#000000',
    },
    {
        id: 4,
        name: 'Red',
        hex: '#FF0000',
    },
    {
        id: 5,
        name: 'Maroon',
        hex: '#800000',
    },
    {
        id: 6,
        name: 'Yellow',
        hex: '#FFFF00',
    },
    {
        id: 7,
        name: 'Olive',
        hex: '#808000',
    },
    {
        id: 8,
        name: 'Lime',
        hex: '#00FF00',
    },
    {
        id: 9,
        name: 'Green',
        hex: '#008000',
    },
    {
        id: 10,
        name: 'Aqua',
        hex: '#00FFFF',
    },
    {
        id: 11,
        name: 'Teal',
        hex: '#008080',
    },
    {
        id: 12,
        name: 'Blue',
        hex: '#0000FF',
    },
    {
        id: 13,
        name: 'Navy',
        hex: '#000080',
    },
    {
        id: 14,
        name: 'Fuchsia',
        hex: '#FF00FF',
    },
    {
        id: 15,
        name: 'Purple',
        hex: '#800080',
    },
];

const useSextforceAutoRepostColors = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const axios = useAxios();
    const dialog = useDialog();
    const params = useParams();
    const queryClient = useQueryClient();

    // Fetch online users count vs. total sales report
    const fetchData = async (): Promise<SextforceAutoRepostColor[]> => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return [];
        }

        const query: string = `${settingsContext.routes.autoRepostColors.base}${params.userId}`;

        const data = await axios
            .get(query)
            .then(async response => response.data as SextforceAutoRepostColor[])
            .catch(error => {
                handleHttpError(error, dialog);

                return [];
            });

        return data;
    };

    const updateSextforceAutoRepostColors = async (data: { _id?: string; name: string; color: string }[]): Promise<void> => {
        if (!userContext.jwtToken || !settingsContext.apiKey || !params.userId) {
            return;
        }

        const query: string = `${settingsContext.routes.autoRepostColors.base}${params.userId}/`;

        await axios
            .put(query, data)
            .then(response => {
                if (response.status === 200) {
                    queryClient.invalidateQueries('autoRepostColors');

                    return response.data;
                } else {
                    return Promise.reject(response.data);
                }
            })
            .catch(error => {
                handleHttpError(error, dialog);

                return [];
            });
    };

    const autoRepostsColosQuery = useQuery(
        ['autoRepostColors', params.userId, userContext.jwtToken],
        () => {
            return fetchData();
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            enabled: userContext.jwtToken && params.userId && settingsContext.apiKey ? true : false,
        },
    );

    return { ...autoRepostsColosQuery, updateSextforceAutoRepostColors };
};

export default useSextforceAutoRepostColors;
