import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { OnlyFansSubscriberHostessAutoReplyFreeloaders } from '../../../../types/hostessAutoReplyFreeloaders';

const FormHistoryTtlDays = ({
    historyTtlEnabled,
    historyTtlDaysString,
    setHistoryTtlDaysString,
    autoReplyFreeloaders,
    formData,
    setFormData,
}: {
    historyTtlEnabled: boolean;
    historyTtlDaysString: string;
    setHistoryTtlDaysString: (followingThresholdAmountString: string) => void;
    autoReplyFreeloaders: OnlyFansSubscriberHostessAutoReplyFreeloaders | null;
    formData: OnlyFansSubscriberHostessAutoReplyFreeloaders;
    setFormData: (formData: OnlyFansSubscriberHostessAutoReplyFreeloaders) => void;
}) => {
    return (
        <FormControl>
            <InputLabel htmlFor="historyTtlDays">Days</InputLabel>
            <OutlinedInput
                id="historyTtlDays"
                size="medium"
                inputProps={{ inputMode: 'text', pattern: /^\d*$$/ }}
                disabled={!historyTtlEnabled}
                value={historyTtlDaysString}
                onChange={e => {
                    e.preventDefault();

                    if (/^\d*$/.test(e.target.value)) {
                        const parsed = parseFloat(e.target.value.replaceAll(',', '.'));

                        if (isNaN(parsed) || parsed === 0) {
                            setFormData({ ...formData, historyTtlDays: null });
                            setHistoryTtlDaysString('0');
                        } else {
                            setFormData({ ...formData, historyTtlDays: parsed });
                            setHistoryTtlDaysString(e.target.value.replace(/^0+/, ''));
                        }
                    }
                }}
                label="Length"
                error={formData && historyTtlEnabled && !formData.historyTtlDays ? true : false}
                sx={{
                    width: '80px',
                }}
            />
        </FormControl>
    );
};

export default FormHistoryTtlDays;
