import { QueryStats } from '@mui/icons-material';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { SextforceAutoRepostWithLatestRepostLog } from '../../../../hooks/useSextforceAutoRepost';
import { objectIdToDate } from '../../../../utils/common';
import SextforceAutoRepostTableRepostsHistoryRow from './SextforceAutoRepostTableRepostsHistoryRow';

const DateTitle = ({ date }: { date: Date }) => {
    return (
        <TableRow>
            <TableCell colSpan={5}>
                <Typography variant="h6" pl={1}>
                    {moment(date).format('MMMM D, YYYY')}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

type Props = {
    subscriber: any;
    autoReposts: SextforceAutoRepostWithLatestRepostLog[];
    autoRepostsLoading: boolean;
    refetchAutoReposts: () => void;
    setSelectedDate: (day: moment.Moment) => void;
    sortBy: string;
};

const SextforceAutoRepostCalendarDayView = (props: Props) => {
    const { subscriber, autoReposts, autoRepostsLoading, refetchAutoReposts, setSelectedDate, sortBy } = props;

    const theme: Theme = useTheme();

    return (
        <Box
            sx={{
                display: 'block',
                overflowX: 'auto',
            }}
        >
            {!autoRepostsLoading && autoReposts && autoReposts.length === 0 && (
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: 'center',
                        color: theme.palette.text.secondary,
                        mt: 1,
                    }}
                >
                    No auto reposts yet
                    <br />
                    Click on the button above to add a new auto repost
                </Typography>
            )}
            {!autoRepostsLoading && autoReposts && autoReposts.length > 0 && (
                <>
                    <Table
                        size="small"
                        sx={{
                            // No padding on the left and right of the table but only on the first and last cell
                            '& td': {
                                '&:first-of-type': {
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                },
                                '&:last-child': {
                                    paddingRight: 1,
                                },
                                paddingLeft: 0.5,
                                paddingRight: 0.5,
                            },
                            '& th': {
                                '&:first-of-type': {
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                },
                                '&:last-child': {
                                    paddingRight: 1,
                                },
                                // Make header cells bold
                                fontWeight: 'bold',
                                paddingLeft: 0.5,
                                paddingRight: 0.5,
                            },
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width={75}
                                    align="center"
                                    sx={{
                                        minWidth: 75,
                                    }}
                                >
                                    <QueryStats fontSize="small" />
                                </TableCell>
                                <TableCell>Post</TableCell>
                                <TableCell width={60} align="center">
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {autoReposts.map((autoRepost, index) => (
                                <React.Fragment key={index}>
                                    {
                                        // If sorting by date added , show the day if the day changed
                                        sortBy === '_id' && index === 0 && (
                                            <DateTitle key={`date-${autoRepost._id}`} date={objectIdToDate(autoRepost._id!)} />
                                        )
                                    }
                                    {sortBy === '_id' &&
                                        index > 0 &&
                                        objectIdToDate(autoReposts[index - 1]._id!).getDate() !==
                                            objectIdToDate(autoRepost._id!).getDate() && (
                                            <DateTitle key={`date-${autoRepost._id}`} date={objectIdToDate(autoRepost._id!)} />
                                        )}
                                    {sortBy === 'nextRepostDate' && index === 0 && (
                                        <DateTitle key={`date-${autoRepost._id}`} date={autoRepost.nextRepostDate} />
                                    )}
                                    {sortBy === 'nextRepostDate' &&
                                        index > 0 &&
                                        moment(autoReposts[index - 1].nextRepostDate).isSame(autoRepost.nextRepostDate, 'day') ===
                                            false && <DateTitle key={`date-${autoRepost._id}`} date={autoRepost.nextRepostDate} />}
                                    <SextforceAutoRepostTableRepostsHistoryRow
                                        subscriber={subscriber}
                                        autoRepost={autoRepost}
                                        refetchAutoReposts={refetchAutoReposts}
                                        setSelectedDate={setSelectedDate}
                                        theme={theme}
                                        key={autoRepost._id}
                                    />
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </Box>
    );
};

export default SextforceAutoRepostCalendarDayView;
